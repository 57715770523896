/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ApiLoginSendPinCodePutRequest {
    cpr?: string | null;
}

export interface ApiLoginValidatePinCodePutRequest {
    cpr?: string | null;
    pin?: string | null;
}

/**
 * 
 */
export class LoginApi extends runtime.BaseAPI {

    /**
     */
    async apiLoginSendPinCodePutRaw(requestParameters: ApiLoginSendPinCodePutRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.cpr !== undefined) {
            queryParameters['cpr'] = requestParameters.cpr;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Login/SendPinCode`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiLoginSendPinCodePut(requestParameters: ApiLoginSendPinCodePutRequest): Promise<string> {
        const response = await this.apiLoginSendPinCodePutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiLoginValidatePinCodePutRaw(requestParameters: ApiLoginValidatePinCodePutRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.cpr !== undefined) {
            queryParameters['cpr'] = requestParameters.cpr;
        }

        if (requestParameters.pin !== undefined) {
            queryParameters['pin'] = requestParameters.pin;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Login/ValidatePinCode`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiLoginValidatePinCodePut(requestParameters: ApiLoginValidatePinCodePutRequest): Promise<string> {
        const response = await this.apiLoginValidatePinCodePutRaw(requestParameters);
        return await response.value();
    }

}
