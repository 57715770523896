import { useFormikContext } from 'formik';
import React from "react";
import styled from "styled-components";
import { Error } from "../../src/ApplicationForm";
import AgeSuffix from "./AgeSuffix";
import { HiddenTitle } from "../StepFour";
import { InputGroup, SmallLabel } from "../StepThree";

const StepContainer = styled("div")({
    display: "inline-flex",

})

const Step = styled("div")({
    width: "12px",
    height: "12px",
    borderRadius: "50px",
    border: "1px solid #3F9BE1",
    margin: "5px"
})

const FilledStep = styled(Step)({
    background: "#3F9BE1"
})

const CarsContainer = styled.div`
width: 100%;
padding: 0;
`


export const getObjectStyles = (value, touched) => {
    if (value == null && touched) {
        return {
            border: '2px solid #E76F51'
        }
    }
}

export const getSecondObjectStyles = (age, touched) => {
    if (age == -1 && touched) {
        return {
            border: '2px solid #E76F51'
        }
    }
}



const ChildrenCounter: React.FunctionComponent = () => {
    const { values, setFieldValue, errors, touched, handleChange, handleBlur } = useFormikContext<any>();
    function changeAge(e, i) {
        values.children[i].age = parseInt(e.replace(" år", ""))
    }
    const countChildren = () => {
        let numOfChildren = [];
        let childrenNum = values.children.length;
        for (let i = 0; i < childrenNum; i++) {
            numOfChildren.push(
                <div key={"child-" + i} className="row">
                    <div className="col-12">
                        <SmallLabel>Barn nummer {i + 1} </SmallLabel>
                    </div>
                            <InputGroup className="col-12">
                                {values.children[i].age > -1 &&
                                    <div>
                                        <HiddenTitle>Barnets alder</HiddenTitle>
                                    </div>
                        }
                        <span className="has-float-label">
                            <AgeSuffix maskOptions placeholder=" " name={"children[" + i + "].age"} onChange={(event) => changeAge(event.target.value, i)} className={values.children[i].age > 0 ? "validated text-input form-input" : "form-input text-input"} style={errors.children && errors.children[i] && errors.children[i].age && touched.children && touched.children[i] && touched.children[i].age ? { border: "2px solid #E76F51" } : {}} value={values.children[i].age > -1 ? values.children[i].age : ""} />
                            <label>Barnets alder</label>
                        </span>
                        {errors.children && errors.children[i] && errors.children[i].age && touched.children && touched.children[i] && touched.children[i].age &&
                                <div className="col-12">
                                    <Error>
                                <div>{errors.children[i].age}</div>
                                    </Error>
                                </div>
                            }
                        </InputGroup>
                    
                </div>
                    )

        };
        return numOfChildren
    }


    return (
        <CarsContainer>
            {countChildren()}
            </CarsContainer>
    )
}


export default ChildrenCounter;