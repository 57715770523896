import { Field, useFormikContext } from 'formik';
import React from "react";
import styled from "styled-components";
import { Error } from "../src/ApplicationForm";
import { LoanReason } from "../src/Enums";

const InputFieldsContainer = styled.div`
    width: 100%;
    margin: auto;
    margin-bottom: 25px;
`

export const FormGroup = styled.div`
    position: relative;
    margin: auto;
    margin-top: 12px;
    width: 90%;

    input {
    font-size: 15px;
    font-weight: 300;
    }

    @media only screen and (max-width: 500px) {
    width: 100%;
    }

    @media only screen and (max-width: 1300px) {
    width: 100%;
    margin-top: 15px;
    }
`


const Select = styled.select`
        width: 100%;
        height: 50px;
        border: 2px solid #E3E3E3;
        border-radius: 6px;
        display: block;
        background-color: white !important;
        padding-left: 15px;
        font-size: 14px;
        flex:1;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url(../images/drop-grey.svg) 96% / 6% no-repeat white;
        &:hover{
        cursor: pointer;
        border: 2px solid #c9c9c9;
        box-shadow: 0px 1px 3px #00000029;
        transition: 0.2s;
        }   
        &:focus {
        border: 2px solid #c9c9c9;
        box-shadow: 0px 3px 6px #00000029;
        outline: none !important;

        }
        @media only screen and (max-width: 1200px) {
        font-size: 13px;
    }
`

const InputIcon = styled.div`
        width: 60px;
        background: #F5F5F5;
        border: 1px solid #E3E3E3;
        text-align: center;

        img {
            margin-top: 40%;
        }`


export const Input = styled(styled(Field)({
    width: "100%",
    display: "block",
    backgroundColor: "white !important",
    height: "50px",
    paddingLeft: "15px",
    flex: "1",
    fontSize: "14px",
    borderRadius: "6px",
}))`

     &:hover{
        border: 2px solid #c9c9c9;
        box-shadow: 0px 1px 3px #00000029;
        transition: 0.2s;
        }
        &:focus {
        border: 2px solid #c9c9c9;
        box-shadow: 0px 3px 6px #00000029;
        outline: none !important;

        }
        @media only screen and (max-width: 500px) {
        font-size: 13px;
        }

`

export const InputRent = styled(Input)`
    :hover {
        + #tooltip-rent {
        display:block !important;
        }

    :focus{
        outline: none!important;

}

    }

`

export const PrefixInput = styled(Input)`
    padding-left: 48px;
@media (max-width: 500px) {
    padding-left: 10px;
}

`

export const InputPop = styled(Input)`
    :focus {
        outline: none!important;
        border-color: #315665 !important;
        + .form-group-textbox {
        display:block !important;
        }

    }
`
const SelectLabel = styled.label`
position: absolute;
font-size: 14px;
opacity: 0.7;
top: 0;
bottom: 0;
margin-top: auto;
margin-bottom: auto;
margin-left: 14px;
display: flex;
align-items: center;
pointer-events: none;
height: 20px;
color: #315665;
`

export const BruttoInput = styled(Input)`
:hover {
        border-color: #315665 !important;
        + .brutto-tooltip {
        display:block !important;
        }

    }

:focus{
            outline: none!important;

}
`



export const MailLabel = styled.label`
font-size: 17px;
font-weight: 300;
font-style: normal;
color: #315665;
text-align: left;
width: 98%;
margin: auto;
margin-bottom: 10px;
margin-left: 2%;
`

const HiddenTitle = styled.label`
    font-size: 12px;
    position: absolute;
    left: 30px;
    top: 6px;
        @media only screen and (max-width: 500px) {
    font-size: 11px;
    }
`

const InputGroup = styled.div`
position: relative;
width: 100%;
margin-bottom: 25px;
`

export const getStyles = (errors, touched) => {
    if (errors && touched) {
        return {
            border: '2px solid #E76F51'
        }
    }
}
export const getYesNoStyles = (errors, touched) => {
    if (errors && touched) {
        return {
            border: '2px solid #E76F51',
            opacity: "0.8", 
            color: "#E76F51",
        }
    }
}


const InputFields: React.FunctionComponent = () => {
    const { values, setFieldValue, handleChange, errors, touched, isSubmitting, handleBlur } = useFormikContext<any>();

    function changeReason(e) {
        setFieldValue("loanReason", e.target.value)
        values.loanReason == e.target.value;
        if (e.target.value != LoanReason.Other) {
            values.step == 2;
            setFieldValue("step", 2);
            if (e.target.value == LoanReason.Samlelaan) {
                setFieldValue("isSamleLaan", true);
                setFieldValue("isCarLoan", false);
            }
            if (e.target.value == LoanReason.Car) {
                setFieldValue("isCarLoan", true)
                setFieldValue("isSamleLaan", false)
            }
        }
    };
    return (
        <div> 
            <InputFieldsContainer>
                <FormGroup style={{marginBottom: "15px"}}>
                    <InputGroup className="col-12" style={{marginBottom: 0}}>
                        <Select className={values.loanReason != "" && !values.lowCarPrice ? "validated select" : "select"} style={!values.lowCarPrice ? getStyles(errors.loanReason, touched.loanReason) : { borderColor: "#F4A261"}} name="loanReason" id="loanReason" onChange={(e) => changeReason(e)} onBlur={handleBlur} value={values.loanReason != "" && values.loanReason}>
                            <option hidden value={""}></option>
                            <option disabled value={""}>Vælg</option>
                            <option value={LoanReason.Samlelaan}>Samlelån</option>
                            <option value={LoanReason.Car}>Køb af bil</option>
                            <option value={LoanReason.Vehicle}>Køb af båd eller MC</option>
                            <option value={LoanReason.Renovation}>Renovering af bolig</option>
                            <option value={LoanReason.Travel}>Ferie / rejser / oplevelser</option> 
                            <option value={LoanReason.Special}>Særlige begivenheder</option>
                            <option value={LoanReason.HouseDeposit}>Depositum til bolig</option>  
                            <option value={LoanReason.Dentist}>Tandlæge / operation</option>
                            <option value={LoanReason.Debt}>Til dækning af gæld</option>
                            <option value={LoanReason.Electronics}>Køb af elektronik</option>
                            <option value={LoanReason.Other}>Andet</option>
                        </Select>
                        <SelectLabel className={values.loanReason != "" ? "select-chosen" : ""}>Låneformål</SelectLabel>

                    </InputGroup>
                    {errors.loanReason && touched.loanReason &&
                        <div className="col-12">
                            <Error>
                                <div>{errors.loanReason}</div>
                            </Error>
                        </div>
                    }
                </FormGroup>
                {values.loanReason == LoanReason.Other &&
                    <FormGroup className="col-12">
                    <span className="has-float-label">
                    <input name="otherReason" id="otherReason" className={values.otherReason.length > 0 ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.otherReason, touched.otherReason)} onChange={handleChange} placeholder=" " />
                    <label>Andet låneformål</label>
                    </span>

                        {errors.otherReason && touched.otherReason &&
                            <div className="col-12">
                                <Error>
                                    <div>{errors.otherReason}</div>
                                </Error>
                            </div>
                        }
                    </FormGroup>
                }

                </InputFieldsContainer>
            </div>
    )
}

export default InputFields