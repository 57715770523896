export function GetAddressMonthText(num) {
    var text;
    switch (num) {
        case 1:
        text = "Januar";
            break;
        case 2:
        text = "Februar";
            break;
        case 3:
        text = "Marts";
            break;
        case 4:
        text = "April";
            break;
         case 5:
        text = "Maj";
            break;
         case 6:
        text = "Juni";
            break;
         case 7:
        text = "Juli";
            break;
         case 8:
        text = "August";
            break;
         case 9:
        text = "September";
            break;
         case 10:
        text = "Oktober";
            break;
         case 11:
        text = "November";
            break;
         case 12:
        text = "December";
            break;
    }
    return text;
}