import { useFormikContext } from "formik";
import Slider, { createSliderWithTooltip } from "rc-slider";
import React from "react";
import { Continue } from "./FormContainer";
import NormalLoan from "./NormalLoan";
import SamleLoan from "./SamleLoan";


const SliderWithTooltip = createSliderWithTooltip(Slider);
const interest = 0.07 / 12;
const CarLoan = React.lazy(() => import('./CarLoan'));


const ChangeValue: React.FunctionComponent = () => {
    const { values, setFieldValue, handleSubmit, isSubmitting, submitForm, handleChange } = useFormikContext<any>();
    function changeTime(add: boolean) {
        if (add == true) {
            setFieldValue("durationYears", values.durationYears + 1);
        }
        else {
            setFieldValue("durationYears", values.durationYears - 1);

        }

    }
    function changeAmount(amount: number) {
        setFieldValue("loanAmount", amount)
        setFieldValue("inputAmount", amount)
    }

    function changeBlur() {
        setFieldValue("loanAmount", values.inputAmount)
    }
    return (
        <div>
            {!values.isSamleLaan && !values.isCarLoan &&
                <NormalLoan />
            }
            {values.isSamleLaan &&
                <SamleLoan />
            }
            {values.isCarLoan && <CarLoan/> }
            <Continue type="button" onClick={() => setFieldValue("editLoan", false)}>Videre med ansøgning<img src="../images/arrow-right-short-white.svg"/></Continue>
        </div>
        )
}

export default ChangeValue;