import { useFormikContext } from 'formik';
import React from "react";
import styled from "styled-components";
import { Error } from "../src/ApplicationForm";
import { CivilStatus, EmploymentType, HouseType } from "../src/Enums";
import PercentageSuffix from './Components/PercentageSuffix';
import { InnerWrapper } from "./FormContainer";
import { getStyles, getYesNoStyles, Input } from "./InputFields";
import { Info, YesNoLayout } from "./StepEight";
import { Card, ContentBox, DateContainer, DateDrop, InfoText, InputGroup, SmallLabel, SmallLabelFullWidth, SuffixLabel, TooltipWrapper, InfoTextToolTip, SmallLabelFull } from "./StepThree";
import { InputExtraInfo, Select, TriangleDown } from "./StepTwo";
import { SelectLabel } from "./TopPart";

export const HiddenTitle = styled.label`
    font-size: 12px;
    position: absolute;
    left: 30px;
    top: 6px;
    color: black;
        @media only screen and (max-width: 500px) {
    font-size: 11px;
    }
`

export const DateTitle = styled(HiddenTitle)`
    left: 17px;

`

export const CVRTitle = styled(HiddenTitle)`
    left: 17px;
`

export const CarBtn = styled.div`
padding: 8px 0;
border: 2px solid #4C4C4C;
border-radius: 6px;
text-align: center;
width: 100%;
font-size: 15px;
font-style: normal;
font-weight: 600;
opacity: 0.6;
    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */

&:hover{
cursor: pointer;
opacity: 1;
border-color: #2A9D8F;
color: #2A9D8F;
}
`

export const CarBtnCon = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
margin: auto;
        @media only screen and (max-width: 500px) {
        flex-wrap: wrap;
        justify-content: center;
        
        }

`

export const MediumSpace = styled.div`
margin: 20px 0;
width: 100%;
`

export const YesNoContainer = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
padding-bottom: 20px;
align-items: center;

 @media only screen and (min-width: 600px) {
        justify-content: flex-end;
        padding-bottom: 0px;
     }
`

export const YesNoBtn = styled.button`
border: 2px solid #4C4C4C;
border-radius: 6px;
opacity: 0.3;
width: 40%;
padding: 11px 10px;
text-align: center;
background-color: transparent;

 @media only screen and (min-width: 600px) {
        width: 75px;
        height: 50px;
        margin-left: 10px;
     }

&:hover{
opacity: 0.8;
color: #2A9D8F !important;
border-color: #2A9D8F !important;
}

:focus{
outline: 0 !important;
}
`

export const ThreeOptions = styled(YesNoBtn)`
        width: 27%;
        @media only screen and (max-width: 500px) {
        width: 30%;
        font-size: 13px;
    }

 @media only screen and (min-width: 600px) {
        margin-left: 0px;
     }
`

export const CloseButton = styled.button`
        display: none;
        border: 0;

        @media only screen and (max-width: 600px) {
        display: block;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 10px;
        height: 10px;
        background-color: transparent;
        background-image: url(../images/close-icon.svg);
        background-size: 100% 100%
     }
    
`

export const DropDownBtn = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white !important;
    height: 50px;
    font-size: 14px;
    padding-left: 15px;
    flex: 1;
    border-radius: 6px;
    border: 2px solid #E3E3E3;
 -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    
img{
width: 25px;
height: auto;
}
     &:hover{
        border: 2px solid #c9c9c9;
        box-shadow: 0px 1px 3px #00000029;
        transition: 0.2s;
        }
        &:focus {
        border: 2px solid #c9c9c9;
        box-shadow: 0px 3px 6px #00000029;
        outline: none !important;

        }
`

export const BigDropDownBtn = styled(DropDownBtn)`
span{
text-align: left;
}
`

export const DropDown = styled.div`
position: absolute;
width: 100%;
max-height: 150px;
background-color: white !important;
overflow-y: scroll;
border: 2px solid #E2E2E2;
border-radius: 5px;
margin-top: 2px;
z-index: 10;
font-size: 14px;
`

export const BigDropDown = styled(DropDown)`
width: 100%;
`

export const DropDownValue = styled.div`
width: 100%;
padding: 10px 0;
text-align: center;
 -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
&:hover{
background-color: #eff2f7;
cursor: pointer;
}
`

export const InputWrapper = styled.div`
    margin-top: 10px;
    width: 100%;
`

const StepFour: React.FunctionComponent = () => {
    const { values, setFieldValue, handleChange, isSubmitting, errors, validateOnChange, touched, handleBlur } = useFormikContext<any>();
    const [showYearDropdown, setshowYearDropdown] = React.useState(false);
    const [showMonthDropdown, setshowMonthDropdown] = React.useState(false);
    const [showCoYearDropdown, setshowCoYearDropdown] = React.useState(false);
    const [showCoMonthDropdown, setshowCoMonthDropdown] = React.useState(false);
    const [showTooltip, setTooltip] = React.useState(false);

    function showDropdown(value) {
        setshowYearDropdown(false)
        setshowMonthDropdown(false)
        setshowCoYearDropdown(false)
        setshowCoMonthDropdown(false)
        if (value == "year") {
            setshowYearDropdown(true)
            if (showYearDropdown == true) {
                setshowYearDropdown(false)
            }
        }
        else if (value == "month") {
            setshowMonthDropdown(true)
            if (showMonthDropdown == true) {
                setshowMonthDropdown(false)
            }
        }
        if (value == "coyear") {
            setshowCoYearDropdown(true)
            if (showCoYearDropdown == true) {
                setshowCoYearDropdown(false)
            }
        }
        else if (value == "comonth") {
            setshowCoMonthDropdown(true)
            if (showCoMonthDropdown == true) {
                setshowCoMonthDropdown(false)
            }
        }
    }

    var date = new Date();
    let currentYear: number;
    currentYear = date.getFullYear();

    const drawYears = () => {
        let YearsHTML = []
        for (let i = currentYear; i > 1950; i--) {
            YearsHTML.push(
                <DropDownValue key={i + 1} className="dropvalue" onClick={() => setFieldValue("employmentSinceYear", i)}>{i}</DropDownValue>
            );
        };
        return YearsHTML;


    }
    const drawCoYears = () => {
        let YearsHTML = []
        for (let i = currentYear; i > 1950; i--) {
            YearsHTML.push(
                <DropDownValue className="dropvalue" onClick={() => setFieldValue("coEmploymentSinceYear", i)}>{i}</DropDownValue>
            );
        };
        return YearsHTML;


    }

    window.onclick = function (event) {
        if (!event.target.matches('.dropbtn')) {
            if (showYearDropdown == true) {
                setshowYearDropdown(false);
            }
            if (showMonthDropdown == true) {
                setshowMonthDropdown(false);
            }
            if (showCoYearDropdown == true) {
                setshowCoYearDropdown(false);
            }
            if (showCoMonthDropdown == true) {
                setshowCoMonthDropdown(false);
            }
        }
    }

    function setMonth(text, value) {
        setFieldValue("employmentSinceMonthText", text)
        setFieldValue("employmentSinceMonth", value)
    }
    function setCoMonth(text, value) {
        setFieldValue("coEmploymentSinceMonthText", text)
        setFieldValue("coEmploymentSinceMonth", value)
    }

    function toogleTooltip(value) {
        setTooltip(value);
    }

    function changeNumber(e, value) {
        var amount = parseInt(e.replaceAll(".", ""))
        console.log(amount + " amount")
        if (e == "") {
            setFieldValue(value, -1);
        }
        else {
            setFieldValue(value, amount);
        }
    }

    return (
        <div className="row">
            <ContentBox className="col-12">
                <InnerWrapper>
                        <Card>
                        <SmallLabel>Hvor stor en del af husstandens samlede udgifter betaler du?</SmallLabel>
                        <InputGroup>
                            <span className="has-float-label">
                                <PercentageSuffix maskOptions placeholder=" " name="householdShare" id="householdShare" className={values.householdShare > -1 && values.householdShare < 101 ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.householdShare, touched.householdShare)} onBlur={(event) => changeNumber(event.target.value, "householdShare")} value={values.householdShare > 0 ? values.householdShare : ""} />
                                <label>Udgifter i procent</label>
                            </span>
                            <InputExtraInfo className="brutto-tooltip">
                                <span>Hvis du bor alene, skriv 100%</span>
                                <TriangleDown />
                            </InputExtraInfo>
                            {errors.householdShare && touched.householdShare &&
                                <div className="col-12">
                                    <Error>
                                        <div>{errors.householdShare}</div>
                                    </Error>
                                </div>
                            }
                        </InputGroup>
                        </Card>
                    {values.dwelling != HouseType.Ejer && values.dwelling != HouseType.Hus &&

                        <Card>
                        <YesNoLayout>
                            <SmallLabelFullWidth>Får du boligstøtte?</SmallLabelFullWidth>
                            <YesNoContainer>
                                <YesNoBtn type="button" style={getYesNoStyles(errors.hasHouseSupport, touched.hasHouseSupport)} className={values.hasHouseSupport == 1 ? "clicked" : ""} onClick={() => setFieldValue("hasHouseSupport", 1)}>Ja<span /></YesNoBtn>
                                <YesNoBtn type="button" style={getYesNoStyles(errors.hasHouseSupport, touched.hasHouseSupport)} className={values.hasHouseSupport == 0 ? "clicked" : ""} onClick={() => setFieldValue("hasHouseSupport", 0)}>Nej<span /></YesNoBtn>
                            </YesNoContainer>
                        </YesNoLayout>
                        {values.hasHouseSupport == 1 &&
                            <InputWrapper>
                                    <InputGroup>
                                        {!errors.houseSupport && values.houseSupport > 0 &&
                                            <div>
                                                <CVRTitle>Boligstøtte om måneden</CVRTitle>
                                            </div>
                                        }
                                        <Input type="number" placeholder="Boligstøtte om måneden" name="houseSupport" id="houseSupport" className={!errors.houseSupport && values.houseSupport > 0 ? "validated text-input hidden-title" : "text-input"} style={getStyles(errors.houseSupport, touched.houseSupport)} onChange={handleChange} value={values.houseSupport > 0 ? values.houseSupport : ""} />
                                        <SuffixLabel className={!errors.householdDigitalMediaExpense && touched.householdDigitalMediaExpense ? "full-opacity" : ""}>kr./mdr.</SuffixLabel>
                                        {errors.houseSupport && touched.houseSupport &&
                                            <div>
                                                <Error>
                                                    <div>{errors.houseSupport}</div>
                                                </Error>
                                            </div>
                                        }
                            </InputGroup>
                            </InputWrapper>
                                }
                        </Card>
                    }
                    <Card>
                        <YesNoLayout>
                            <SmallLabelFullWidth>Ejer du et sommerhus?</SmallLabelFullWidth>
                            <YesNoContainer>
                                <YesNoBtn type="button" style={getYesNoStyles(errors.ownVacationHouse, touched.ownVacationHouse)} className={values.ownVacationHouse == "ja" ? "clicked" : ""} onClick={() => setFieldValue("ownVacationHouse", "ja")}>Ja<span /></YesNoBtn>
                                <YesNoBtn type="button" style={getYesNoStyles(errors.ownVacationHouse, touched.ownVacationHouse)} className={values.ownVacationHouse == "nej" ? "clicked" : ""} onClick={() => setFieldValue("ownVacationHouse", "nej")}>Nej<span /></YesNoBtn>
                            </YesNoContainer>
                        </YesNoLayout>
                    </Card>
                    <Card>
                        <div style={{marginBottom: "15px", width: "100%"}}>
                            <TooltipWrapper>
                                <SmallLabel>
                                    <p>Beskæftigelsestype</p>
                                </SmallLabel>
                                <Info className="info-circle"><img onClick={() => toogleTooltip(false)} src="../images/information-tooltip.svg" alt="info" />
                                    {!showTooltip &&
                                        <InfoTextToolTip className="info-text">Indtast den beskæftigelsesform der passer bedst på dig<div className="info-triangle" />
                                            <CloseButton onClick={() => toogleTooltip(true)} type="button"></CloseButton>
                                        </InfoTextToolTip>
                                    }
                                </Info>
                            </TooltipWrapper>
                            <InputGroup style={{marginBottom: 0}}>
                            <Select className={values.employmentType > -1 ? "validated select" : "select"} style={getStyles(errors.employmentType, touched.employmentType)} name="employmentType" id="employmentType" onChange={handleChange} onBlur={handleBlur} value={values.employmentType > -1 && values.employmentType}>
                                <option hidden value={-1}></option>
                                <option value={EmploymentType.Unemployed}>Arbejdsløs</option>
                                <option value={EmploymentType.SalaryEmployed}>Lønmodtager</option>
                                <option value={EmploymentType.Hourly}>Timelønnet</option>
                                <option value={EmploymentType.Management}>Leder</option>
                                <option value={EmploymentType.Homemaker}>Hjemmegående</option>
                                <option value={EmploymentType.Retired}>Pensioneret</option>
                                <option value={EmploymentType.SelfEmployed}>Selvstændig</option>
                                <option value={EmploymentType.Student}>Studerende</option>
                                <option value={EmploymentType.Trainee}>Praktikant/Elev</option>
                                <option value={EmploymentType.EarlyRetirement}>Førtidspensioneret</option>
                                <option value={EmploymentType.Other}>Andet</option>
                                <option value={EmploymentType.Temporary}>Midlertidigt arbejde</option>
                            </Select>
                            <SelectLabel className={values.employmentType > -1 ? "select-chosen" : ""}>Beskæftigelsestype</SelectLabel>
                        </InputGroup>
                        {errors.employmentType && touched.employmentType &&
                            <div>
                                <Error>
                                    <div>{errors.employmentType}</div>
                                </Error>
                            </div>
                        }
                        </div>
                        {values.employmentType == EmploymentType.SelfEmployed &&
                            <InputGroup>
                                {values.employerIdentificationNumber > 0 &&
                                    <div>
                                    <CVRTitle>CVR-nummer</CVRTitle>
                                    </div>
                                }
                                <Input type="number" placeholder="Indtast CVR-nummer" name="employerIdentificationNumber" id="employerIdentificationNumber" className={values.employerIdentificationNumber >= 10000000 && values.employerIdentificationNumber < 100000000 ? "validated text-input hidden-title" : "text-input"} style={getStyles(errors.employerIdentificationNumber, touched.employerIdentificationNumber)} onChange={handleChange} value={values.employerIdentificationNumber > 0 && values.employerIdentificationNumber} />
                                {errors.employerIdentificationNumber && touched.employerIdentificationNumber &&
                                    <div>
                                        <Error>
                                        <div>{errors.employerIdentificationNumber}</div>
                                        </Error>
                                    </div>
                                }
                            </InputGroup>
                        }
                        {values.coApplicant == true &&
                            <div className="row"> 
                            <TooltipWrapper>
                                <SmallLabel>
                                    <p>Beskæftigelsestype - Medansøger</p>
                                </SmallLabel>
                                <Info className="info-circle"><img onClick={() => toogleTooltip(false)} src="../images/information-tooltip.svg" alt="info" />
                                    {!showTooltip &&
                                        <InfoTextToolTip className="info-text">Indtast den beskæftigelsesform der passer bedst på medansøger<div className="info-triangle" />
                                            <CloseButton onClick={() => toogleTooltip(true)} type="button"></CloseButton>
                                        </InfoTextToolTip>
                                    }
                                </Info>
                            </TooltipWrapper>
                                <InputGroup style={{marginBottom: 0}}>
                                    <Select className={values.coEmploymentType > -1 ? "validated select" : "select"} style={getStyles(errors.coEmploymentType, touched.coEmploymentType)} name="coEmploymentType" id="coEmploymentType" onChange={handleChange} onBlur={handleBlur} value={values.coEmploymentType > -1 && values.coEmploymentType}>
                                        <option hidden value={-1}>Vælg beskæftigelsestype</option>
                                    <option value={EmploymentType.Unemployed}>Arbejdsløs</option>
                                    <option value={EmploymentType.SalaryEmployed}>Lønmodtager</option>
                                    <option value={EmploymentType.Hourly}>Timelønnet</option>
                                    <option value={EmploymentType.Management}>Leder</option>
                                    <option value={EmploymentType.Homemaker}>Hjemmegående</option>
                                    <option value={EmploymentType.Retired}>Pensioneret</option>
                                    <option value={EmploymentType.SelfEmployed}>Selvstændig</option>
                                    <option value={EmploymentType.Student}>Studerende</option>
                                    <option value={EmploymentType.Trainee}>Praktikant/Elev</option>
                                    <option value={EmploymentType.EarlyRetirement}>Førtidspensioneret</option>
                                    <option value={EmploymentType.Other}>Andet</option>
                                    <option value={EmploymentType.Temporary}>Midlertidigt arbejde</option>
                                    </Select>
                                    {errors.coEmploymentType && touched.coEmploymentType &&
                                        <div className="col-12">
                                            <Error>
                                                <div>{errors.coEmploymentType}</div>
                                            </Error>
                                        </div>
                                    }
                                </InputGroup>
                            </div>
                        }
                    </Card>
                            <Card>
                        <TooltipWrapper>
                            <SmallLabelFull>
                                <p>Beskæftigelse siden</p>
                            </SmallLabelFull>
                            <Info className="info-circle"><img onClick={() => toogleTooltip(false)} src="../images/information-tooltip.svg" alt="info" />
                                {!showTooltip &&
                                    <InfoTextToolTip className="info-text">Vælg måned og år for hvornår du startede i dit nuværende job<div className="info-triangle" />
                                        <CloseButton onClick={() => toogleTooltip(true)} type="button"></CloseButton>
                                    </InfoTextToolTip>
                                }
                            </Info>
                        </TooltipWrapper>
                        <DateContainer>
                            <DateDrop>
                                <DropDownBtn className={values.employmentSinceMonthText != "" ? "validated dropbtn" : "dropbtn"} style={getStyles(errors.employmentSinceMonth, touched.employmentSinceMonth)} onClick={() => showDropdown("month")}>
                                    {values.employmentSinceMonthText != "" && <DateTitle className="dropbtn">Måned</DateTitle>}
                                                <span style={{ width: "80%" }} className={values.employmentSinceMonthText != "" ? "hidden-title dropbtn" : "dropbtn"}>{values.employmentSinceMonthText != "" ? values.employmentSinceMonthText : "Vælg måned"}</span>
                                                <img className={showMonthDropdown ? "rotate dropbtn" : "dropbtn"} src="../images/drop-grey.svg" />
                                            </DropDownBtn>
                                            {showMonthDropdown &&
                                                <div>
                                                    <DropDown className="scroll">
                                                        <DropDownValue className="dropvalue" onClick={() => setMonth("Januar", 1)}>Januar</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setMonth("Februar", 2)}>Februar</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setMonth("Marts", 3)}>Marts</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setMonth("April", 4)}>April</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setMonth("Maj", 5)}>Maj</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setMonth("Juni", 6)}>Juni</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setMonth("Juli", 7)}>Juli</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setMonth("August", 8)}>August</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setMonth("September", 9)}>September</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setMonth("Oktober", 10)}>Oktober</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setMonth("November", 11)}>November</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setMonth("December", 12)}>December</DropDownValue>
                                                    </DropDown>
                                                </div>
                                    }
                                    {errors.employmentSinceMonth && touched.employmentSinceMonth &&
                                        <div className="col-12">
                                            <Error>
                                            <div>{errors.employmentSinceMonth}</div>
                                            </Error>
                                        </div>
                                    }
                            </DateDrop>
                            <DateDrop>
                                    <DropDownBtn className={values.employmentSinceYear > 0 ? "validated dropbtn" : "dropbtn"} style={getStyles(errors.employmentSinceYear, touched.employmentSinceYear)} onClick={() => showDropdown("year")}>
                                    {values.employmentSinceYear > 0 && <DateTitle className="dropbtn">År</DateTitle>}
                                                <span style={{ width: "80%" }} className={values.employmentSinceYear > 0 ? "hidden-title dropbtn" : "dropbtn"}>{values.employmentSinceYear > 0 ? values.employmentSinceYear : "Vælg år"}</span>
                                                <img className={showYearDropdown ? "rotate dropbtn" : "dropbtn"} src="../images/drop-grey.svg" />
                                            </DropDownBtn>
                                            {showYearDropdown &&
                                                <div>
                                                    <DropDown id="year-dropdown" className="scroll">
                                                        {drawYears()}
                                                    </DropDown>
                                                </div>
                                    }
                                    {errors.employmentSinceYear && touched.employmentSinceYear &&
                                        <div className="col-12">
                                            <Error>
                                            <div>{errors.employmentSinceYear}</div>
                                            </Error>
                                        </div>
                                    }
                            </DateDrop>
                                    </DateContainer>
                        {values.coApplicant == true &&
                            <div className="row" style={{ margin: "17px 0" }}>
                            <TooltipWrapper>
                                <SmallLabel>
                                    <p>Beskæftigelse siden - Medansøger</p>
                                </SmallLabel>
                                <Info className="info-circle"><img onClick={() => toogleTooltip(false)} src="../images/information-tooltip.svg" alt="info" />
                                    {!showTooltip &&
                                        <InfoTextToolTip className="info-text">Vælg måned og år for hvornår medansøger startede i dit nuværende job<div className="info-triangle" />
                                            <CloseButton onClick={() => toogleTooltip(true)} type="button"></CloseButton>
                                        </InfoTextToolTip>
                                    }
                                </Info>
                            </TooltipWrapper>
                                    <DateContainer>
                                    <DateDrop>
                                        <DropDownBtn className={values.coEmploymentSinceMonthText != "" ? "dropbtn validated" : "dropbtn"} onClick={() => showDropdown("comonth")} style={getStyles(errors.coEmploymentSinceMonth, touched.coEmploymentSinceMonth)}>
                                        {values.coEmploymentSinceMonthText != "" && <DateTitle className="dropbtn">Måned</DateTitle>}
                                                <span style={{ width: "80%" }} className={values.coEmploymentSinceMonthText != "" ? "hidden-title dropbtn" : "dropbtn"}>{values.coEmploymentSinceMonthText != "" ? values.coEmploymentSinceMonthText : "Vælg måned"}</span>
                                                <img className={showCoMonthDropdown ? "rotate dropbtn" : "dropbtn"} src="../images/drop-grey.svg" />
                                            </DropDownBtn>
                                            {showCoMonthDropdown &&
                                                <div>
                                                    <DropDown className="scroll">
                                                        <DropDownValue className="dropvalue" onClick={() => setCoMonth("Januar", 1)}>Januar</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setCoMonth("Februar", 2)}>Februar</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setCoMonth("Marts", 3)}>Marts</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setCoMonth("April", 4)}>April</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setCoMonth("Maj", 5)}>Maj</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setCoMonth("Juni", 6)}>Juni</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setCoMonth("Juli", 7)}>Juli</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setCoMonth("August", 8)}>August</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setCoMonth("September", 9)}>September</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setCoMonth("Oktober", 10)}>Oktober</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setCoMonth("November", 11)}>November</DropDownValue>
                                                        <DropDownValue className="dropvalue" onClick={() => setCoMonth("December", 12)}>December</DropDownValue>
                                                    </DropDown>
                                                </div> 
                                        }
                                        {errors.coEmploymentSinceMonth && touched.coEmploymentSinceMonth &&
                                            <div className="col-12">
                                                <Error>
                                                <div>{errors.coEmploymentSinceMonth}</div>
                                                </Error>
                                            </div>
                                        }
                                    </DateDrop>
                                    <DateDrop>
                                        <DropDownBtn className={values.coEmploymentSinceYear > 0 ? "validated dropbtn" : "dropbtn"} onClick={() => showDropdown("coyear")} style={getStyles(errors.coEmploymentSinceYear, touched.coEmploymentSinceYear)}>
                                        {values.coEmploymentSinceYear > 0 && <DateTitle className="dropbtn">År</DateTitle>}
                                                <span style={{ width: "80%" }} className={values.coEmploymentSinceYear > 0 ? "hidden-title dropbtn" : "dropbtn"}>{values.coEmploymentSinceYear > 0 ? values.coEmploymentSinceYear : "Vælg år"}</span>
                                                <img className={showCoYearDropdown ? "rotate dropbtn" : "dropbtn"} src="../images/drop-grey.svg" />
                                            </DropDownBtn>
                                            {showCoYearDropdown &&
                                                <div>
                                                    <DropDown id="year-dropdown" className="scroll">
                                                {drawCoYears()}
                                                    </DropDown>
                                                </div>
                                        }
                                        {errors.coEmploymentSinceYear && touched.coEmploymentSinceYear &&
                                            <div className="col-12">
                                                <Error>
                                                <div>{errors.coEmploymentSinceYear}</div>
                                                </Error>
                                            </div>
                                        }
                                    </DateDrop>
                                    </DateContainer>
                            </div>
                        }
                    </Card>
                </InnerWrapper>
            </ContentBox>

        </div>
    )
}

export default StepFour;