import React from "react";

const Loader: React.FunctionComponent = () => {    
    return (
        <div className="broker-placeholder">
            <img src="~/images/gif-logo.gif" alt="loading" />
        </div>
    )
}

export default Loader