/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CitizenshipCategory {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

export function CitizenshipCategoryFromJSON(json: any): CitizenshipCategory {
    return CitizenshipCategoryFromJSONTyped(json, false);
}

export function CitizenshipCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): CitizenshipCategory {
    return json as CitizenshipCategory;
}

export function CitizenshipCategoryToJSON(value?: CitizenshipCategory | null): any {
    return value as any;
}

