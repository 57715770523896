/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoanDto
 */
export interface LoanDto {
    /**
     * 
     * @type {number}
     * @memberof LoanDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    headline?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LoanDto
     */
    minLoanAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanDto
     */
    maxLoanAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanDto
     */
    minInterest?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanDto
     */
    maxInterest?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanDto
     */
    minDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanDto
     */
    maxDuration?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    timeUnit?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    trackingUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LoanDto
     */
    minimumAge?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanDto
     */
    rating?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanDto
     */
    exampleAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanDto
     */
    exampleMonths?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanDto
     */
    exampleStartCosts?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    extraExampleInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    exampleInterestType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    exampleInterest?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    exampleAAop?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    exampleMonthlyPay?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    exampleTotalCosts?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    exampleTotalPayback?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    maxAAop?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LoanDto
     */
    establishment?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanDto
     */
    punchLines?: string | null;
}

export function LoanDtoFromJSON(json: any): LoanDto {
    return LoanDtoFromJSONTyped(json, false);
}

export function LoanDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoanDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'headline': !exists(json, 'headline') ? undefined : json['headline'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'minLoanAmount': !exists(json, 'minLoanAmount') ? undefined : json['minLoanAmount'],
        'maxLoanAmount': !exists(json, 'maxLoanAmount') ? undefined : json['maxLoanAmount'],
        'minInterest': !exists(json, 'minInterest') ? undefined : json['minInterest'],
        'maxInterest': !exists(json, 'maxInterest') ? undefined : json['maxInterest'],
        'minDuration': !exists(json, 'minDuration') ? undefined : json['minDuration'],
        'maxDuration': !exists(json, 'maxDuration') ? undefined : json['maxDuration'],
        'timeUnit': !exists(json, 'timeUnit') ? undefined : json['timeUnit'],
        'trackingUrl': !exists(json, 'trackingUrl') ? undefined : json['trackingUrl'],
        'minimumAge': !exists(json, 'minimumAge') ? undefined : json['minimumAge'],
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'exampleAmount': !exists(json, 'exampleAmount') ? undefined : json['exampleAmount'],
        'exampleMonths': !exists(json, 'exampleMonths') ? undefined : json['exampleMonths'],
        'exampleStartCosts': !exists(json, 'exampleStartCosts') ? undefined : json['exampleStartCosts'],
        'extraExampleInfo': !exists(json, 'extraExampleInfo') ? undefined : json['extraExampleInfo'],
        'exampleInterestType': !exists(json, 'exampleInterestType') ? undefined : json['exampleInterestType'],
        'exampleInterest': !exists(json, 'exampleInterest') ? undefined : json['exampleInterest'],
        'exampleAAop': !exists(json, 'exampleAAop') ? undefined : json['exampleAAop'],
        'exampleMonthlyPay': !exists(json, 'exampleMonthlyPay') ? undefined : json['exampleMonthlyPay'],
        'exampleTotalCosts': !exists(json, 'exampleTotalCosts') ? undefined : json['exampleTotalCosts'],
        'exampleTotalPayback': !exists(json, 'exampleTotalPayback') ? undefined : json['exampleTotalPayback'],
        'maxAAop': !exists(json, 'maxAAop') ? undefined : json['maxAAop'],
        'establishment': !exists(json, 'establishment') ? undefined : json['establishment'],
        'punchLines': !exists(json, 'punchLines') ? undefined : json['punchLines'],
    };
}

export function LoanDtoToJSON(value?: LoanDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'headline': value.headline,
        'imageUrl': value.imageUrl,
        'minLoanAmount': value.minLoanAmount,
        'maxLoanAmount': value.maxLoanAmount,
        'minInterest': value.minInterest,
        'maxInterest': value.maxInterest,
        'minDuration': value.minDuration,
        'maxDuration': value.maxDuration,
        'timeUnit': value.timeUnit,
        'trackingUrl': value.trackingUrl,
        'minimumAge': value.minimumAge,
        'rating': value.rating,
        'exampleAmount': value.exampleAmount,
        'exampleMonths': value.exampleMonths,
        'exampleStartCosts': value.exampleStartCosts,
        'extraExampleInfo': value.extraExampleInfo,
        'exampleInterestType': value.exampleInterestType,
        'exampleInterest': value.exampleInterest,
        'exampleAAop': value.exampleAAop,
        'exampleMonthlyPay': value.exampleMonthlyPay,
        'exampleTotalCosts': value.exampleTotalCosts,
        'exampleTotalPayback': value.exampleTotalPayback,
        'maxAAop': value.maxAAop,
        'establishment': value.establishment,
        'punchLines': value.punchLines,
    };
}


