/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BinaryBool,
    BinaryBoolFromJSON,
    BinaryBoolFromJSONTyped,
    BinaryBoolToJSON,
    CitizenshipCategory,
    CitizenshipCategoryFromJSON,
    CitizenshipCategoryFromJSONTyped,
    CitizenshipCategoryToJSON,
    EmploymentType,
    EmploymentTypeFromJSON,
    EmploymentTypeFromJSONTyped,
    EmploymentTypeToJSON,
    Permit,
    PermitFromJSON,
    PermitFromJSONTyped,
    PermitToJSON,
} from './';

/**
 * 
 * @export
 * @interface DbApplicant
 */
export interface DbApplicant {
    /**
     * 
     * @type {string}
     * @memberof DbApplicant
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DbApplicant
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof DbApplicant
     */
    phoneNumber: string;
    /**
     * 
     * @type {string}
     * @memberof DbApplicant
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DbApplicant
     */
    cpr: string;
    /**
     * 
     * @type {EmploymentType}
     * @memberof DbApplicant
     */
    employment?: EmploymentType;
    /**
     * 
     * @type {BinaryBool}
     * @memberof DbApplicant
     */
    jobInsurance?: BinaryBool;
    /**
     * 
     * @type {Date}
     * @memberof DbApplicant
     */
    employmentSince?: Date;
    /**
     * 
     * @type {BinaryBool}
     * @memberof DbApplicant
     */
    citizenship?: BinaryBool;
    /**
     * 
     * @type {Permit}
     * @memberof DbApplicant
     */
    citizenshipPermit?: Permit;
    /**
     * 
     * @type {string}
     * @memberof DbApplicant
     */
    foreignNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplicant
     */
    citizenshipTempPermit?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplicant
     */
    citizenshipPermPermit?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DbApplicant
     */
    citizenshipTempExpire?: Date;
    /**
     * 
     * @type {string}
     * @memberof DbApplicant
     */
    citizenshipSerialNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplicant
     */
    grossIncome?: number;
    /**
     * 
     * @type {number}
     * @memberof DbApplicant
     */
    nettoIncome?: number;
    /**
     * 
     * @type {number}
     * @memberof DbApplicant
     */
    otherIncome?: number;
    /**
     * 
     * @type {number}
     * @memberof DbApplicant
     */
    debtSinceLastTaxReport?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DbApplicant
     */
    coApplicant?: boolean;
    /**
     * 
     * @type {CitizenshipCategory}
     * @memberof DbApplicant
     */
    citizenshipCategory?: CitizenshipCategory;
    /**
     * 
     * @type {string}
     * @memberof DbApplicant
     */
    applicationId?: string;
    /**
     * 
     * @type {Date}
     * @memberof DbApplicant
     */
    birthDate?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof DbApplicant
     */
    aseConsent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DbApplicant
     */
    aseSynced?: boolean;
}

export function DbApplicantFromJSON(json: any): DbApplicant {
    return DbApplicantFromJSONTyped(json, false);
}

export function DbApplicantFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbApplicant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': json['email'],
        'phoneNumber': json['phoneNumber'],
        'name': json['name'],
        'cpr': json['cpr'],
        'employment': !exists(json, 'employment') ? undefined : EmploymentTypeFromJSON(json['employment']),
        'jobInsurance': !exists(json, 'jobInsurance') ? undefined : BinaryBoolFromJSON(json['jobInsurance']),
        'employmentSince': !exists(json, 'employmentSince') ? undefined : (new Date(json['employmentSince'])),
        'citizenship': !exists(json, 'citizenship') ? undefined : BinaryBoolFromJSON(json['citizenship']),
        'citizenshipPermit': !exists(json, 'citizenshipPermit') ? undefined : PermitFromJSON(json['citizenshipPermit']),
        'foreignNumber': !exists(json, 'foreignNumber') ? undefined : json['foreignNumber'],
        'citizenshipTempPermit': !exists(json, 'citizenshipTempPermit') ? undefined : json['citizenshipTempPermit'],
        'citizenshipPermPermit': !exists(json, 'citizenshipPermPermit') ? undefined : json['citizenshipPermPermit'],
        'citizenshipTempExpire': !exists(json, 'citizenshipTempExpire') ? undefined : (new Date(json['citizenshipTempExpire'])),
        'citizenshipSerialNumber': !exists(json, 'citizenshipSerialNumber') ? undefined : json['citizenshipSerialNumber'],
        'grossIncome': !exists(json, 'grossIncome') ? undefined : json['grossIncome'],
        'nettoIncome': !exists(json, 'nettoIncome') ? undefined : json['nettoIncome'],
        'otherIncome': !exists(json, 'otherIncome') ? undefined : json['otherIncome'],
        'debtSinceLastTaxReport': !exists(json, 'debtSinceLastTaxReport') ? undefined : json['debtSinceLastTaxReport'],
        'coApplicant': !exists(json, 'coApplicant') ? undefined : json['coApplicant'],
        'citizenshipCategory': !exists(json, 'citizenshipCategory') ? undefined : CitizenshipCategoryFromJSON(json['citizenshipCategory']),
        'applicationId': !exists(json, 'applicationId') ? undefined : json['applicationId'],
        'birthDate': !exists(json, 'birthDate') ? undefined : (json['birthDate'] === null ? null : new Date(json['birthDate'])),
        'aseConsent': !exists(json, 'aseConsent') ? undefined : json['aseConsent'],
        'aseSynced': !exists(json, 'aseSynced') ? undefined : json['aseSynced'],
    };
}

export function DbApplicantToJSON(value?: DbApplicant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'name': value.name,
        'cpr': value.cpr,
        'employment': EmploymentTypeToJSON(value.employment),
        'jobInsurance': BinaryBoolToJSON(value.jobInsurance),
        'employmentSince': value.employmentSince === undefined ? undefined : (value.employmentSince.toISOString()),
        'citizenship': BinaryBoolToJSON(value.citizenship),
        'citizenshipPermit': PermitToJSON(value.citizenshipPermit),
        'foreignNumber': value.foreignNumber,
        'citizenshipTempPermit': value.citizenshipTempPermit,
        'citizenshipPermPermit': value.citizenshipPermPermit,
        'citizenshipTempExpire': value.citizenshipTempExpire === undefined ? undefined : (value.citizenshipTempExpire.toISOString()),
        'citizenshipSerialNumber': value.citizenshipSerialNumber,
        'grossIncome': value.grossIncome,
        'nettoIncome': value.nettoIncome,
        'otherIncome': value.otherIncome,
        'debtSinceLastTaxReport': value.debtSinceLastTaxReport,
        'coApplicant': value.coApplicant,
        'citizenshipCategory': CitizenshipCategoryToJSON(value.citizenshipCategory),
        'applicationId': value.applicationId,
        'birthDate': value.birthDate === undefined ? undefined : (value.birthDate === null ? null : value.birthDate.toISOString()),
        'aseConsent': value.aseConsent,
        'aseSynced': value.aseSynced,
    };
}


