/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BankEnum,
    BankEnumFromJSON,
    BankEnumFromJSONTyped,
    BankEnumToJSON,
} from './';

/**
 * 
 * @export
 * @interface DbBank
 */
export interface DbBank {
    /**
     * 
     * @type {number}
     * @memberof DbBank
     */
    id?: number;
    /**
     * 
     * @type {BankEnum}
     * @memberof DbBank
     */
    bankEnum?: BankEnum;
    /**
     * 
     * @type {string}
     * @memberof DbBank
     */
    statuses?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbBank
     */
    subStatuses?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbBank
     */
    referenceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbBank
     */
    applicationGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof DbBank
     */
    dbApplicationId?: string;
    /**
     * 
     * @type {Date}
     * @memberof DbBank
     */
    lastUpdated?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DbBank
     */
    generalStatus?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DbBank
     */
    yearlyExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof DbBank
     */
    establishment?: number;
    /**
     * 
     * @type {number}
     * @memberof DbBank
     */
    debitRent?: number;
    /**
     * 
     * @type {number}
     * @memberof DbBank
     */
    monthlyFee?: number;
    /**
     * 
     * @type {number}
     * @memberof DbBank
     */
    loanAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof DbBank
     */
    loanTime?: number;
    /**
     * 
     * @type {number}
     * @memberof DbBank
     */
    collectedBackPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof DbBank
     */
    monthlyPayment?: number;
    /**
     * 
     * @type {string}
     * @memberof DbBank
     */
    bankLink?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof DbBank
     */
    offerReceived?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DbBank
     */
    rejectedReason?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DbBank
     */
    isCarBank?: boolean;
}

export function DbBankFromJSON(json: any): DbBank {
    return DbBankFromJSONTyped(json, false);
}

export function DbBankFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbBank {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'bankEnum': !exists(json, 'bankEnum') ? undefined : BankEnumFromJSON(json['bankEnum']),
        'statuses': !exists(json, 'statuses') ? undefined : json['statuses'],
        'subStatuses': !exists(json, 'subStatuses') ? undefined : json['subStatuses'],
        'referenceId': !exists(json, 'referenceId') ? undefined : json['referenceId'],
        'applicationGuid': !exists(json, 'applicationGuid') ? undefined : json['applicationGuid'],
        'dbApplicationId': !exists(json, 'dbApplicationId') ? undefined : json['dbApplicationId'],
        'lastUpdated': !exists(json, 'lastUpdated') ? undefined : (json['lastUpdated'] === null ? null : new Date(json['lastUpdated'])),
        'generalStatus': !exists(json, 'generalStatus') ? undefined : json['generalStatus'],
        'yearlyExpense': !exists(json, 'yearlyExpense') ? undefined : json['yearlyExpense'],
        'establishment': !exists(json, 'establishment') ? undefined : json['establishment'],
        'debitRent': !exists(json, 'debitRent') ? undefined : json['debitRent'],
        'monthlyFee': !exists(json, 'monthlyFee') ? undefined : json['monthlyFee'],
        'loanAmount': !exists(json, 'loanAmount') ? undefined : json['loanAmount'],
        'loanTime': !exists(json, 'loanTime') ? undefined : json['loanTime'],
        'collectedBackPayment': !exists(json, 'collectedBackPayment') ? undefined : json['collectedBackPayment'],
        'monthlyPayment': !exists(json, 'monthlyPayment') ? undefined : json['monthlyPayment'],
        'bankLink': !exists(json, 'bankLink') ? undefined : json['bankLink'],
        'offerReceived': !exists(json, 'offerReceived') ? undefined : (json['offerReceived'] === null ? null : new Date(json['offerReceived'])),
        'rejectedReason': !exists(json, 'rejectedReason') ? undefined : json['rejectedReason'],
        'isCarBank': !exists(json, 'isCarBank') ? undefined : json['isCarBank'],
    };
}

export function DbBankToJSON(value?: DbBank | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'bankEnum': BankEnumToJSON(value.bankEnum),
        'statuses': value.statuses,
        'subStatuses': value.subStatuses,
        'referenceId': value.referenceId,
        'applicationGuid': value.applicationGuid,
        'dbApplicationId': value.dbApplicationId,
        'lastUpdated': value.lastUpdated === undefined ? undefined : (value.lastUpdated === null ? null : value.lastUpdated.toISOString()),
        'generalStatus': value.generalStatus,
        'yearlyExpense': value.yearlyExpense,
        'establishment': value.establishment,
        'debitRent': value.debitRent,
        'monthlyFee': value.monthlyFee,
        'loanAmount': value.loanAmount,
        'loanTime': value.loanTime,
        'collectedBackPayment': value.collectedBackPayment,
        'monthlyPayment': value.monthlyPayment,
        'bankLink': value.bankLink,
        'offerReceived': value.offerReceived === undefined ? undefined : (value.offerReceived === null ? null : value.offerReceived.toISOString()),
        'rejectedReason': value.rejectedReason,
        'isCarBank': value.isCarBank,
    };
}


