/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface ApiCsvGetRequest {
    from?: Date;
    to?: Date;
    id?: boolean;
    name?: boolean;
    email?: boolean;
    phoneNumber?: boolean;
    type?: boolean;
    receivedApplication?: boolean;
    loanAmount?: boolean;
    acceptedApplication?: boolean;
    basisBankStatus?: boolean;
    expressBankStatus?: boolean;
    leasyStatus?: boolean;
    resursStatus?: boolean;
    kreditnuStatus?: boolean;
    nordCreditStatus?: boolean;
    resursMerchantStatus?: boolean;
    ferratumStatus?: boolean;
    rememberStatus?: boolean;
    amountType?: boolean;
    paidOutAmount?: boolean;
    oetAccept?: boolean;
    commision?: boolean;
    commisionByBank?: boolean;
    channel?: boolean;
    uTMS?: boolean;
    uTMM?: boolean;
    uTMC?: boolean;
    referer?: boolean;
    created?: boolean;
    lastKnownStep?: boolean;
    notFinishedDuoTo?: boolean;
    lastUpdated?: boolean;
    startLandingPage?: boolean;
    employment?: boolean;
    employmentSince?: boolean;
    jobInsurance?: boolean;
    jobInsuranceSince?: boolean;
    citizenship?: boolean;
    grossIncome?: boolean;
    nettoIncome?: boolean;
    loanReason?: boolean;
    loanTime?: boolean;
    coApplicant?: boolean;
    debtSinceLastTaxReport?: boolean;
    householdOtherLoan?: boolean;
    householdOtherLoanExpense?: boolean;
    carLoan?: boolean;
    carLoanExpense?: boolean;
    studyLoan?: boolean;
    studyLoanExpense?: boolean;
    consumerLoan?: boolean;
    consumerLoanExpense?: boolean;
    otherDebt?: boolean;
    otherDebtExpense?: boolean;
    publicDebt?: boolean;
    publicDebtExpense?: boolean;
    age?: boolean;
    disposableIncome?: boolean;
    marketingConsent?: boolean;
    aseConsent?: boolean;
    aseSynced?: boolean;
    dwelling?: boolean;
    transactionId?: boolean;
    transactionIdSynced?: boolean;
    resursId?: boolean;
    title?: string | null;
    topStatistic?: boolean;
    sourceStatistic?: boolean;
    acceptedStatistic?: boolean;
    receivedStatistic?: boolean;
    notFinishedStatistic?: boolean;
    bankStatistic?: boolean;
    key?: string | null;
}

/**
 * 
 */
export class CsvApi extends runtime.BaseAPI {

    /**
     */
    async apiCsvGetRaw(requestParameters: ApiCsvGetRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['From'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['To'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.id !== undefined) {
            queryParameters['Id'] = requestParameters.id;
        }

        if (requestParameters.name !== undefined) {
            queryParameters['Name'] = requestParameters.name;
        }

        if (requestParameters.email !== undefined) {
            queryParameters['Email'] = requestParameters.email;
        }

        if (requestParameters.phoneNumber !== undefined) {
            queryParameters['PhoneNumber'] = requestParameters.phoneNumber;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['Type'] = requestParameters.type;
        }

        if (requestParameters.receivedApplication !== undefined) {
            queryParameters['ReceivedApplication'] = requestParameters.receivedApplication;
        }

        if (requestParameters.loanAmount !== undefined) {
            queryParameters['LoanAmount'] = requestParameters.loanAmount;
        }

        if (requestParameters.acceptedApplication !== undefined) {
            queryParameters['AcceptedApplication'] = requestParameters.acceptedApplication;
        }

        if (requestParameters.basisBankStatus !== undefined) {
            queryParameters['BasisBankStatus'] = requestParameters.basisBankStatus;
        }

        if (requestParameters.expressBankStatus !== undefined) {
            queryParameters['ExpressBankStatus'] = requestParameters.expressBankStatus;
        }

        if (requestParameters.leasyStatus !== undefined) {
            queryParameters['LeasyStatus'] = requestParameters.leasyStatus;
        }

        if (requestParameters.resursStatus !== undefined) {
            queryParameters['ResursStatus'] = requestParameters.resursStatus;
        }

        if (requestParameters.kreditnuStatus !== undefined) {
            queryParameters['KreditnuStatus'] = requestParameters.kreditnuStatus;
        }

        if (requestParameters.nordCreditStatus !== undefined) {
            queryParameters['NordCreditStatus'] = requestParameters.nordCreditStatus;
        }

        if (requestParameters.resursMerchantStatus !== undefined) {
            queryParameters['ResursMerchantStatus'] = requestParameters.resursMerchantStatus;
        }

        if (requestParameters.ferratumStatus !== undefined) {
            queryParameters['FerratumStatus'] = requestParameters.ferratumStatus;
        }

        if (requestParameters.rememberStatus !== undefined) {
            queryParameters['RememberStatus'] = requestParameters.rememberStatus;
        }

        if (requestParameters.amountType !== undefined) {
            queryParameters['AmountType'] = requestParameters.amountType;
        }

        if (requestParameters.paidOutAmount !== undefined) {
            queryParameters['PaidOutAmount'] = requestParameters.paidOutAmount;
        }

        if (requestParameters.oetAccept !== undefined) {
            queryParameters['OetAccept'] = requestParameters.oetAccept;
        }

        if (requestParameters.commision !== undefined) {
            queryParameters['Commision'] = requestParameters.commision;
        }

        if (requestParameters.commisionByBank !== undefined) {
            queryParameters['CommisionByBank'] = requestParameters.commisionByBank;
        }

        if (requestParameters.channel !== undefined) {
            queryParameters['Channel'] = requestParameters.channel;
        }

        if (requestParameters.uTMS !== undefined) {
            queryParameters['UTMS'] = requestParameters.uTMS;
        }

        if (requestParameters.uTMM !== undefined) {
            queryParameters['UTMM'] = requestParameters.uTMM;
        }

        if (requestParameters.uTMC !== undefined) {
            queryParameters['UTMC'] = requestParameters.uTMC;
        }

        if (requestParameters.referer !== undefined) {
            queryParameters['Referer'] = requestParameters.referer;
        }

        if (requestParameters.created !== undefined) {
            queryParameters['Created'] = requestParameters.created;
        }

        if (requestParameters.lastKnownStep !== undefined) {
            queryParameters['LastKnownStep'] = requestParameters.lastKnownStep;
        }

        if (requestParameters.notFinishedDuoTo !== undefined) {
            queryParameters['NotFinishedDuoTo'] = requestParameters.notFinishedDuoTo;
        }

        if (requestParameters.lastUpdated !== undefined) {
            queryParameters['LastUpdated'] = requestParameters.lastUpdated;
        }

        if (requestParameters.startLandingPage !== undefined) {
            queryParameters['StartLandingPage'] = requestParameters.startLandingPage;
        }

        if (requestParameters.employment !== undefined) {
            queryParameters['Employment'] = requestParameters.employment;
        }

        if (requestParameters.employmentSince !== undefined) {
            queryParameters['EmploymentSince'] = requestParameters.employmentSince;
        }

        if (requestParameters.jobInsurance !== undefined) {
            queryParameters['JobInsurance'] = requestParameters.jobInsurance;
        }

        if (requestParameters.jobInsuranceSince !== undefined) {
            queryParameters['JobInsuranceSince'] = requestParameters.jobInsuranceSince;
        }

        if (requestParameters.citizenship !== undefined) {
            queryParameters['Citizenship'] = requestParameters.citizenship;
        }

        if (requestParameters.grossIncome !== undefined) {
            queryParameters['GrossIncome'] = requestParameters.grossIncome;
        }

        if (requestParameters.nettoIncome !== undefined) {
            queryParameters['NettoIncome'] = requestParameters.nettoIncome;
        }

        if (requestParameters.loanReason !== undefined) {
            queryParameters['LoanReason'] = requestParameters.loanReason;
        }

        if (requestParameters.loanTime !== undefined) {
            queryParameters['LoanTime'] = requestParameters.loanTime;
        }

        if (requestParameters.coApplicant !== undefined) {
            queryParameters['CoApplicant'] = requestParameters.coApplicant;
        }

        if (requestParameters.debtSinceLastTaxReport !== undefined) {
            queryParameters['DebtSinceLastTaxReport'] = requestParameters.debtSinceLastTaxReport;
        }

        if (requestParameters.householdOtherLoan !== undefined) {
            queryParameters['HouseholdOtherLoan'] = requestParameters.householdOtherLoan;
        }

        if (requestParameters.householdOtherLoanExpense !== undefined) {
            queryParameters['HouseholdOtherLoanExpense'] = requestParameters.householdOtherLoanExpense;
        }

        if (requestParameters.carLoan !== undefined) {
            queryParameters['CarLoan'] = requestParameters.carLoan;
        }

        if (requestParameters.carLoanExpense !== undefined) {
            queryParameters['CarLoanExpense'] = requestParameters.carLoanExpense;
        }

        if (requestParameters.studyLoan !== undefined) {
            queryParameters['StudyLoan'] = requestParameters.studyLoan;
        }

        if (requestParameters.studyLoanExpense !== undefined) {
            queryParameters['StudyLoanExpense'] = requestParameters.studyLoanExpense;
        }

        if (requestParameters.consumerLoan !== undefined) {
            queryParameters['ConsumerLoan'] = requestParameters.consumerLoan;
        }

        if (requestParameters.consumerLoanExpense !== undefined) {
            queryParameters['ConsumerLoanExpense'] = requestParameters.consumerLoanExpense;
        }

        if (requestParameters.otherDebt !== undefined) {
            queryParameters['OtherDebt'] = requestParameters.otherDebt;
        }

        if (requestParameters.otherDebtExpense !== undefined) {
            queryParameters['OtherDebtExpense'] = requestParameters.otherDebtExpense;
        }

        if (requestParameters.publicDebt !== undefined) {
            queryParameters['PublicDebt'] = requestParameters.publicDebt;
        }

        if (requestParameters.publicDebtExpense !== undefined) {
            queryParameters['PublicDebtExpense'] = requestParameters.publicDebtExpense;
        }

        if (requestParameters.age !== undefined) {
            queryParameters['Age'] = requestParameters.age;
        }

        if (requestParameters.disposableIncome !== undefined) {
            queryParameters['DisposableIncome'] = requestParameters.disposableIncome;
        }

        if (requestParameters.marketingConsent !== undefined) {
            queryParameters['MarketingConsent'] = requestParameters.marketingConsent;
        }

        if (requestParameters.aseConsent !== undefined) {
            queryParameters['AseConsent'] = requestParameters.aseConsent;
        }

        if (requestParameters.aseSynced !== undefined) {
            queryParameters['AseSynced'] = requestParameters.aseSynced;
        }

        if (requestParameters.dwelling !== undefined) {
            queryParameters['Dwelling'] = requestParameters.dwelling;
        }

        if (requestParameters.transactionId !== undefined) {
            queryParameters['TransactionId'] = requestParameters.transactionId;
        }

        if (requestParameters.transactionIdSynced !== undefined) {
            queryParameters['TransactionIdSynced'] = requestParameters.transactionIdSynced;
        }

        if (requestParameters.resursId !== undefined) {
            queryParameters['ResursId'] = requestParameters.resursId;
        }

        if (requestParameters.title !== undefined) {
            queryParameters['Title'] = requestParameters.title;
        }

        if (requestParameters.topStatistic !== undefined) {
            queryParameters['TopStatistic'] = requestParameters.topStatistic;
        }

        if (requestParameters.sourceStatistic !== undefined) {
            queryParameters['SourceStatistic'] = requestParameters.sourceStatistic;
        }

        if (requestParameters.acceptedStatistic !== undefined) {
            queryParameters['AcceptedStatistic'] = requestParameters.acceptedStatistic;
        }

        if (requestParameters.receivedStatistic !== undefined) {
            queryParameters['ReceivedStatistic'] = requestParameters.receivedStatistic;
        }

        if (requestParameters.notFinishedStatistic !== undefined) {
            queryParameters['NotFinishedStatistic'] = requestParameters.notFinishedStatistic;
        }

        if (requestParameters.bankStatistic !== undefined) {
            queryParameters['BankStatistic'] = requestParameters.bankStatistic;
        }

        if (requestParameters.key !== undefined) {
            queryParameters['key'] = requestParameters.key;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Csv`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiCsvGet(requestParameters: ApiCsvGetRequest): Promise<void> {
        await this.apiCsvGetRaw(requestParameters);
    }

}
