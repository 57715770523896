/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SignicatAuthMethod {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

export function SignicatAuthMethodFromJSON(json: any): SignicatAuthMethod {
    return SignicatAuthMethodFromJSONTyped(json, false);
}

export function SignicatAuthMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignicatAuthMethod {
    return json as SignicatAuthMethod;
}

export function SignicatAuthMethodToJSON(value?: SignicatAuthMethod | null): any {
    return value as any;
}

