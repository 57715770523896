/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RejectedReasons {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_12 = 12
}

export function RejectedReasonsFromJSON(json: any): RejectedReasons {
    return RejectedReasonsFromJSONTyped(json, false);
}

export function RejectedReasonsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RejectedReasons {
    return json as RejectedReasons;
}

export function RejectedReasonsToJSON(value?: RejectedReasons | null): any {
    return value as any;
}

