/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DbApplication,
    DbApplicationFromJSON,
    DbApplicationFromJSONTyped,
    DbApplicationToJSON,
    DbApplicationCarInfo,
    DbApplicationCarInfoFromJSON,
    DbApplicationCarInfoFromJSONTyped,
    DbApplicationCarInfoToJSON,
    DbApplicationCars,
    DbApplicationCarsFromJSON,
    DbApplicationCarsFromJSONTyped,
    DbApplicationCarsToJSON,
    DbApplicationChildren,
    DbApplicationChildrenFromJSON,
    DbApplicationChildrenFromJSONTyped,
    DbApplicationChildrenToJSON,
    DbApplicationSamleLaan,
    DbApplicationSamleLaanFromJSON,
    DbApplicationSamleLaanFromJSONTyped,
    DbApplicationSamleLaanToJSON,
    DbApplicationSecureInfo,
    DbApplicationSecureInfoFromJSON,
    DbApplicationSecureInfoFromJSONTyped,
    DbApplicationSecureInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface DbApplicationDTO
 */
export interface DbApplicationDTO {
    /**
     * 
     * @type {DbApplication}
     * @memberof DbApplicationDTO
     */
    application?: DbApplication;
    /**
     * 
     * @type {Array<DbApplicationCars>}
     * @memberof DbApplicationDTO
     */
    cars?: Array<DbApplicationCars> | null;
    /**
     * 
     * @type {Array<DbApplicationChildren>}
     * @memberof DbApplicationDTO
     */
    children?: Array<DbApplicationChildren> | null;
    /**
     * 
     * @type {DbApplicationSecureInfo}
     * @memberof DbApplicationDTO
     */
    secureInfo?: DbApplicationSecureInfo;
    /**
     * 
     * @type {Array<DbApplicationSamleLaan>}
     * @memberof DbApplicationDTO
     */
    samleLaan?: Array<DbApplicationSamleLaan> | null;
    /**
     * 
     * @type {DbApplicationCarInfo}
     * @memberof DbApplicationDTO
     */
    carInfo?: DbApplicationCarInfo;
}

export function DbApplicationDTOFromJSON(json: any): DbApplicationDTO {
    return DbApplicationDTOFromJSONTyped(json, false);
}

export function DbApplicationDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbApplicationDTO {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'application': !exists(json, 'application') ? undefined : DbApplicationFromJSON(json['application']),
        'cars': !exists(json, 'cars') ? undefined : (json['cars'] === null ? null : (json['cars'] as Array<any>).map(DbApplicationCarsFromJSON)),
        'children': !exists(json, 'children') ? undefined : (json['children'] === null ? null : (json['children'] as Array<any>).map(DbApplicationChildrenFromJSON)),
        'secureInfo': !exists(json, 'secureInfo') ? undefined : DbApplicationSecureInfoFromJSON(json['secureInfo']),
        'samleLaan': !exists(json, 'samleLaan') ? undefined : (json['samleLaan'] === null ? null : (json['samleLaan'] as Array<any>).map(DbApplicationSamleLaanFromJSON)),
        'carInfo': !exists(json, 'carInfo') ? undefined : DbApplicationCarInfoFromJSON(json['carInfo']),
    };
}

export function DbApplicationDTOToJSON(value?: DbApplicationDTO | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'application': DbApplicationToJSON(value.application),
        'cars': value.cars === undefined ? undefined : (value.cars === null ? null : (value.cars as Array<any>).map(DbApplicationCarsToJSON)),
        'children': value.children === undefined ? undefined : (value.children === null ? null : (value.children as Array<any>).map(DbApplicationChildrenToJSON)),
        'secureInfo': DbApplicationSecureInfoToJSON(value.secureInfo),
        'samleLaan': value.samleLaan === undefined ? undefined : (value.samleLaan === null ? null : (value.samleLaan as Array<any>).map(DbApplicationSamleLaanToJSON)),
        'carInfo': DbApplicationCarInfoToJSON(value.carInfo),
    };
}


