import { useFormikContext } from 'formik';
import React from "react";
import MaskedInput from 'react-text-mask';
import styled from "styled-components";
import { Error } from "../src/ApplicationForm";
import { CivilStatus, HouseType } from "../src/Enums";
import CurrencySuffix from "./Components/CurrencySuffix";
import { InnerWrapper } from "./FormContainer";
import { getStyles, Input } from "./InputFields";
import { ThousandSeperator } from "./NormalLoan";
import PhonePrefix from "./Components/PhonePrefix";
import { ButtonContainer } from "./SamleLoan";
import { Info, YesNoLayout } from "./StepEight";
import { DateTitle, DropDown, DropDownBtn, DropDownValue, YesNoBtn, YesNoContainer, CloseButton } from "./StepFour";
import { InputExtraInfo, InputExtraInfoMasked, Select, TriangleDown } from "./StepTwo";
import { SelectLabel } from "./TopPart";
import PercentageSuffix from './Components/PercentageSuffix';


export const InputContainer = styled("div")({
    marginBottom: "-25px"
})

export const InputRightText = styled.label`
    font-size: 27px;
    color: #B4BBC4;
    position: relative;
    bottom: 75px;
    left: 185px;
    font-weight: normal;

   @media (max-width: 768px) {
        left: 120px;
  }

`

export const InputLabel = styled("div")({
    color: "#007AB9",
    fontSize: "23px",
    backgroundColor: "white",
    position: "relative",
    bottom: "75px",
    left: "45px",
    paddingLeft: "10px",
    paddingRight: "30px",
    width: "fit-content"
})

export const ContentBox = styled("div")({
    textAlign: "initial",


    "h2": {
        textAlign: "center",
    }
})

export const FormLabel = styled.div`
    font-size: 17px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
    font-style: normal;
    text-align: left;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: start;
`

export const MediumLabel = styled(FormLabel)`
    font-size: 18px;
`

export const SmallLabel = styled(FormLabel)`
    font-size: 16px;
    flex-wrap: nowrap;

    p {
        margin-bottom: 0px;
    }

    @media only screen and (max-width: 600px) {
        justify-content: space-between;
        width: 100%;
    }
`

export const SmallLabelFull = styled(SmallLabel)`
        max-width: 100%;
        justify-content: space-between;
`

export const ExtraSmallLabel = styled(FormLabel)`
    font-size: 15px;
`

export const SmallLabelFullWidth = styled(SmallLabel)`
    width: 100%;

    @media only screen and (max-width: 600px) {
        justify-content: space-between;
    }
`

export const CoLabel = styled(FormLabel)`
    margin-top: 10px;
`

export const MovingBox = styled(ContentBox)({
    textAlign: "center",
    position: "fixed",
    right: "14%",
    padding:"15px"
})

export const WideInput = styled(Input)({
    width: "100%",
    fontSize: "16px",
})

export const CoInput = styled(WideInput)({
    marginTop: "25px",
})

export const ButtonContainerMedium = styled(ButtonContainer)({
    width: "33%",
    marginTop: "25px",
})

export const ButtonContainerSmall = styled(ButtonContainer)`
    width: 14%;
            @media only screen and (max-width: 500px) {
            width: 28%;
            margin: 0px 5px 15px;
            }
`

export const Notification = styled.div`
border-left: 5px solid #F4A261;
background-color: rgba( 233, 196, 106, 0.5);
padding: 12px 25px;
margin-top: 20px;
font-size: 12px;
margin-bottom: 15px;
text-align: left;

`

export const InputGroup = styled.div`
position: relative;
width: 100%;
margin-bottom: 15px;
`

export const CoAppContainer = styled.div`
margin-top: 15px;
`

export const SuffixLabel = styled.label`
position: absolute;
    right: 5%;
    top: 15px;
    font-size: 14px;
    opacity: 0.5;
        @media only screen and (max-width: 500px) {
        font-size: 13px;
        display: none;
        }
}
`

export const Card = styled.div`
width: 100%;
margin: 13px auto;
background-color: white;
border-radius: 16px;
padding: 20px 25px;
border: 1px solid #315665;
box-shadow: 0px 3px 6px #9A9A9A29;
display: flex;
flex-wrap: wrap;
    .row{
        margin-left: 0 !important;
    margin-right: 0 !important;
width: 100% !important;
    }

@media only screen and (max-width: 500px) {
    padding: 10px 15px;
    .row{
    margin-left: 0 !important;
    margin-right: 0 !important;
    }
    }

`
export const DateContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;

`

export const DateDrop = styled.div`
width: 45%;
position: relative;
@media only screen and (max-width: 500px) {
width: 48%;
}
`

export const InfoCircle = styled.i`
position: relative;
background-color: #315665;
color: white;
border-radius: 70%;
width: 18px;
height: 18px;
display: flex;
align-items: center;
justify-content: center;
font-size: 13px;
padding-right: 1px;
-webkit-touch-callout: none; /* iOS Safari */
-webkit-user-select: none; /* Safari */
-khtml-user-select: none; /* Konqueror HTML */
-moz-user-select: none; /* Old versions of Firefox */
-ms-user-select: none; /* Internet Explorer/Edge */
user-select: none; /* Non-prefixed version, currently
supported by Chrome, Edge, Opera and Firefox */

@media only screen and (max-width: 500px) {
    
}

&:hover{
cursor: default;
}
img{
width: 64%;
}
`

export const InfoText = styled.span`
    visibility: hidden;
    opacity: 0;
    position: absolute;
    max-width: 220px;
    background-color: #DFEFEE;
    color: #3C5F6D;
    font-weight: 600;
    font-size: 12px;
    padding: 10px 10px 10px 15px;
    z-index: 60;
    border-radius: 6px;
    display: flex;
    align-items: center;
    height: 75px;
    min-width: 180px;
    right: -18px;
    top: -83px;
    font-weight: 300;
    font-style: normal;

   @media only screen and (max-width: 599px) {
        right: 0px;
        min-width: 320px;
        padding: 10px 35px 10px 15px;
    }

   @media only screen and (max-width: 375px) {
        right: 0px;
        min-width: 295px;
        padding: 10px 35px 10px 15px;
    }

   @media only screen and (max-width: 330px) {
        right: 0px;
        min-width: 260px;
        padding: 10px 35px 10px 15px;
    }


   @media only screen and (max-width: 310px) {
        min-width: 218px;
        padding: 10px 35px 10px 15px;
    }

   @media only screen and (max-width: 300px) {
        min-width: 210px;
        padding: 10px 35px 10px 15px;
    }


    .info-triangle {
        width: 0;
        height: 0;
        border-top: 10px solid #DFEFEE;
        border-bottom: 10px solid transparent;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        position: absolute;
        right: 21px;
        bottom: -15px;

        @media only screen and (max-width: 599px) {
            right: 7px;
        }
    }
`

export const InfoTextToolTip = styled(InfoText)`
        max-width: 390px;
        width: 390px;
        height: 50px;
        right: -10px;
        top: -60px;
        padding-right: 20px;

        .info-triangle {
        right: 10px;
        bottom: -15px;
     }

    @media only screen and (max-width: 1820px) {
        max-width: 380px;
    }

    @media only screen and (max-width: 1800px) {
        max-width: 345px;
    }

    @media only screen and (max-width: 1700px) {
        max-width: 365px;
    }

    @media only screen and (max-width: 580px) {
        max-width: 385px;
    }

    @media only screen and (max-width: 420px) {
        max-width: 315px;
    }

    @media only screen and (max-width: 370px) {
        max-width: 260px;
    }
`

export const TooltipWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: baseline;
    justify-content: space-between;

    @media only screen and (min-width: 600px) {
        max-width: 100%;
    }

    @media only screen and(max-width: 600px) {
    max-width: 200px;
    font-size: 17px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
    font-style: normal;
    text-align: left;
    position: relative;
    display: flex;
    align-items: flex-end;
    max-width: 230px;
    }
`


const StepThree: React.FunctionComponent = () => {
    const { values, setFieldValue, handleChange, isSubmitting, errors, validateOnChange, touched, handleBlur } = useFormikContext<any>();
    const [showYearDropdown, setshowYearDropdown] = React.useState(false);
    const [showMonthDropdown, setshowMonthDropdown] = React.useState(false);
    const [showInfoCPR, setShowInfoCPR] = React.useState(false);
    const [showTooltip, setTooltip] = React.useState(false);

    function dwellingChange(e) {
        setFieldValue("dwelling", e.target.value);
    }

    function validate_cpr_number($cpr) {
        var original = $cpr;
        $cpr = $cpr.replace(/[ \D]/ig, "");
        if ($cpr.toString().length == 10) {
            if (original !== $cpr) {
                values.coCprTest = $cpr;
            }
            var $main_int = 0;
            var $factors = [4, 3, 2, 7, 6, 5, 4, 3, 2, 1];
            for (var ciffer = 0; ciffer < $cpr.length; ciffer++) {
                $main_int += parseInt($cpr.substr(ciffer, 1)) * $factors[ciffer];
            }
            return $main_int % 11 == 0;
        }
        else {
            return false
        }
    }

    function checkCPR(event) {
        setFieldValue("coCprTest", event.target.value)
        let num = values.coCprTest;
        if (num.replace(/[^a-zA-Z0-9 ]/g, "").length == 10) {
            (validate_cpr_number(values.coCprTest)) ? values.coCpr = num.replace(/[^a-zA-Z0-9 ]/g, "") : values.coCpr = "standard1";
            if (values.coCprTest == "1603223864") {
                setFieldValue("coCpr", "1603223864")
                values.coCpr = "1603223864";
                console.log(values.coCpr)
            }
            else if (values.coCprTest == "0702840865") {
                setFieldValue("coCpr", "0702840865")
                values.coCpr = "0702840865";
                console.log(values.coCpr)

            }
            setFieldValue("coCpr", values.coCpr)
        } 
    }


    function showDropdown(value) {
        setshowYearDropdown(false)
        setshowMonthDropdown(false)
        if (value == "year") {
            setshowYearDropdown(true)
            if (showYearDropdown == true) {
                setshowYearDropdown(false)
            }
        }
        else if (value == "month") {
            setshowMonthDropdown(true)
            if (showMonthDropdown == true) {
                setshowMonthDropdown(false)
            }
        }
    }


    function setMonth(text, value) {
        setFieldValue("addressSinceMonthText", text)
        setFieldValue("addressSinceMonth", value)
    }
    function setYear(text, value) {
        setFieldValue(text, value);
        if (value == currentYear) {
            setFieldValue("addressSinceMonth", 0)
            setFieldValue("addressSinceMonthText", "")
        }
    }

    window.onclick = function (event) {
        if (!event.target.matches('.dropbtn')) {
            if (showYearDropdown == true) {
                setshowYearDropdown(false);
            }
            if (showMonthDropdown == true) {
                setshowMonthDropdown(false);
            }
        }
    }

    var cprRegex = /^(((0[1-9]|[12][0-9]|30)(0[13-9]|1[012])|31(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])02)[0-9]{2}|2902((([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))(-|\s)?\d{4}/;


    var date = new Date();
    let currentYear: number;
    currentYear = date.getFullYear();
    var currentMonth = date.getMonth() + 1;

    const drawYears = () => {
        let YearsHTML = []
        for (let i = currentYear; i > 1950; i--) {
            YearsHTML.push(
                <DropDownValue key={i + 1} className="dropvalue" onClick={() => setYear("addressSinceYear", i)}>{i}</DropDownValue>
            );
        };
        return YearsHTML;

    }

    function changePhoneNumber(e, value) {
        var number = parseInt(e.replace("+45 ", ""))
        setFieldValue(value, number);
    }

    function changeNumber(e, value) {
        var amount = parseInt(e.replaceAll(".", ""))
        console.log(amount + " amount")
        if (e == "") {
            setFieldValue(value, -1);
        }
        else {
            setFieldValue(value, amount);
        }
        }

    function toogleTooltip(value) {
        setTooltip(value);
    }

    return (
        <div>
            <div className="row">
                <ContentBox className="col-12">
                    <InnerWrapper>
                        {values.maritalStatus == CivilStatus.Samlevende || values.maritalStatus == CivilStatus.Gift ?
                        <div>
                                <Card>

                         <Notification className="row">Hvis du udfylder en medansøger øger du dine chancer for at få et lån. <br/> OBS. Medansøger skal afslutte ansøgning med eget NemID</Notification>
                                    <div className="row">
                                        <YesNoLayout>
                                            <SmallLabel> Vil du tilføje en medansøger? </SmallLabel>
                                            <YesNoContainer>
                                                <YesNoBtn type="button" className={values.coApplicant ? "clicked" : "ignored"} onClick={() => setFieldValue("coApplicant", true)}>Ja</YesNoBtn>
                                                <YesNoBtn type="button" className={values.coApplicant == false ? "clicked" : "ignored"} onClick={() => setFieldValue("coApplicant", false)}>Nej</YesNoBtn>
                                            </YesNoContainer>
                                        </YesNoLayout>
                                    </div>
                        {values.coApplicant && <CoAppContainer className="row">
                                        <InputGroup>
                                            <span className="has-float-label">
                                                <input className={values.coForeName.length > 0 ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.coForeName, touched.coForeName)} placeholder=" " name="coForeName" id="coForeName" onChange={handleChange} value={values.coForeName != "" ? values.coForeName : ""} />
                                                <label>Medansøgers fornavn</label>
                                            </span>
                                                {errors.coForeName && touched.coForeName &&
                                    <div className="col-12">
                                        <Error>
                                        <div>{errors.coForeName}</div>
                                        </Error>
                                    </div>
                                }
                            </InputGroup>
                                        <InputGroup>
                                            <span className="has-float-label">
                                                <input placeholder=" " className={values.coSurName.length > 0 ? "validated text-input form-input" : "text-input form-input"} style={getStyles(errors.coSurName, touched.coSurName)} name="coSurName" id="coSurName" onChange={handleChange} value={values.coSurName != "" ? values.coSurName : ""} />
                                                <label>Medansøgers efternavn</label>
                                            </span>
                                    {errors.coSurName && touched.coSurName &&
                                    <div className="col-12">
                                        <Error>
                                        <div>{errors.coSurName}</div>
                                        </Error>
                                    </div>
                                }
                                        </InputGroup>

                                        <InputGroup >
                                            <span className="has-float-label">
                                                <PhonePrefix maskOptions placeholder=" " className={values.coPhoneNumber.toString().length == 8 ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.coPhoneNumber, touched.coPhoneNumber)} name="coPhoneNumber" id="coPhoneNumber" onChange={(event) => changePhoneNumber(event.target.value, "coPhoneNumber")} />
                                                <label>Medansøgers mobilnummer</label>
                                           </span>
                                            {errors.coPhoneNumber && touched.coPhoneNumber &&
                                    <div className="col-12">
                                        <Error>
                                                    <div>{errors.coPhoneNumber}</div>
                                        </Error>
                                    </div>
                                }
                            </InputGroup>
                                        <InputGroup>
                                            <span className="has-float-label">
                                                <input className={(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(values.coEmail)) ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.coEmail, touched.coEmail)} placeholder=" " name="coEmail" id="coEmail" onChange={handleChange} value={values.coEmail != "" ? values.coEmail : ""} />
                                                <label>Medansøgers mailadresse</label>
                                           </span>
                                                {errors.coEmail && touched.coEmail &&
                                    <div className="col-12">
                                        <Error>
                                        <div>{errors.coEmail}</div>
                                        </Error>
                                    </div>
                                }
                            </InputGroup>
                                        <InputGroup>
                                            {values.cpr2Returning ?
                                                <Input className="validated" type="password" disabled placeholder="----------" />
                                                :
                                                <span className="has-float-label">
                                                    <MaskedInput autoComplete="tel" mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} name="coCprTest" id="cpr" className={values.coCpr != "standard1" && values.coCpr != "standard" ? "validated text-input form-input" : "form-input text-input"} style={values.coCpr == "standard1" ? { border: '2px solid #E76F51' } : {}} onChange={(event) => checkCPR(event)} onKeyUp={checkCPR} onFocus={() => setShowInfoCPR(true)} onBlur={() => setShowInfoCPR(false)} placeholder=" " />
                                                    <label>Medansøgers CPR-nummer</label>
                                                </span>
                                            }
                                                {showInfoCPR &&
                                <InputExtraInfoMasked className="form-group-textbox">
                                    <span>CPR nummer benyttes af bankerne til kreditvurdering</span>
                                    <TriangleDown />
                                </InputExtraInfoMasked>
                            }
                            {values.coCpr == "standard1" &&
                                <div className="col-12">
                                    <Error>
                                        <div>Indtast valid cpr-nummer</div>
                                    </Error>
                                </div>
                                            }

                            {values.coCpr == values.cpr &&
                                <div className="col-12">
                                    <Error>
                                        <div>Må ikke være identisk med eget cpr</div>
                                    </Error>
                                </div>
                            }
                            </InputGroup>
                                </CoAppContainer>}
                                </Card>
                            </div>
                            : ''
                        }
                        <Card>
                            <div className="row" style={{marginBottom: "15px"}}>
                                <TooltipWrapper>
                                    <SmallLabel>
                                        <p>Din bolig</p>
                                    </SmallLabel>
                                    <Info className="info-circle"><img onClick={() => toogleTooltip(false)} src="../images/information-tooltip.svg" alt="info" />
                                        {!showTooltip &&
                                            <InfoTextToolTip className="info-text">Vælg den boligform der passer bedst på dig<div className="info-triangle" />
                                                <CloseButton onClick={() => toogleTooltip(true)} type="button"></CloseButton>
                                            </InfoTextToolTip>
                                        }
                                    </Info>
                                </TooltipWrapper>
                                <InputGroup style={{marginBottom: 0}}>
                                <Select name="dwelling" id="dwelling" className={values.dwelling != "" ? "validated text-input" : "text-input"} style={getStyles(errors.dwelling, touched.dwelling)} onChange={dwellingChange} onBlur={handleBlur} value={values.dwelling != "" && values.dwelling}>
                            <option hidden value=""></option>
                            <option value={HouseType.Leje}>Bor til leje</option>
                            <option value={HouseType.Hus}>Bor i eget hus (husejer)</option>
                            <option value={HouseType.Ejer}>Bor i egen lejlighed (ejerlejlighed)</option>
                            <option value={HouseType.Andel}>Bor i andelsbolig</option>
                            <option value={HouseType.Hjemme}>Hjemmeboende</option>
                            <option value={HouseType.Room}>Lejet værelse</option>
                                    </Select>
                                    <SelectLabel className={values.dwelling != "" ? "select-chosen" : ""}>Boligform</SelectLabel>
                                </InputGroup>
                                {errors.dwelling && touched.dwelling &&
                                    <div>
                                        <Error>
                                            <div>{errors.dwelling}</div>
                                        </Error>
                                    </div>
                                }
                                </div>
                            {values.dwelling != HouseType.Hus && values.dwelling != HouseType.Ejer && values.dwelling != "" ?
                                <div className="row">
                                    <SmallLabel>Husleje per måned</SmallLabel>
                                    <InputGroup>
                                        <span className="has-float-label">
                                            <CurrencySuffix maskOptions placeholder=" " name="houseRentalAmount" id="houseRentalAmount" className={values.houseRentalAmount > 0 ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.houseRentalAmount, touched.houseRentalAmount)} onChange={(event) => changeNumber(event.target.value, "houseRentalAmount")} value={ThousandSeperator(values.houseRentalAmount > 0 ? values.houseRentalAmount : "")} />
                                        <label>Husleje</label>
                                        </span>
                                           <InputExtraInfo id="tooltip-rent">
                                            <span>Du skal ikke medtage udgifter til vand, el og varme</span>
                                            <TriangleDown />
                                        </InputExtraInfo>
                                        {errors.houseRentalAmount && touched.houseRentalAmount &&
                                            <div className="col-12">
                                                <Error>
                                                <div>{errors.houseRentalAmount}</div>
                                                </Error>
                                            </div>
                                        }
                                    </InputGroup>
                                </div>
                                : ''
                            }
                            {values.dwelling == HouseType.Hus || values.dwelling == HouseType.Ejer ?
                                <div className="row">
                                    <SmallLabel>Ejerudgifter per måned</SmallLabel>
                                    <InputGroup>
                                        <span className="has-float-label">
                                            <CurrencySuffix maskOptions placeholder=" " name="ownerExpense" id="ownerExpense" className={values.ownerExpense > 0 ? "validated text-input form-input" : "text-input form-input"} style={getStyles(errors.ownerExpense, touched.ownerExpense)} onChange={(event) => changeNumber(event.target.value, "ownerExpense")} onBlur={(event) => changeNumber(event.target.value, "ownerExpense")} value={ThousandSeperator(values.ownerExpense > 0 ? values.ownerExpense : "")} />
                                        <label>Ejerudgifter</label>
                                        </span>
                                           <InputExtraInfo id="tooltip-rent">
                                            <span>Ejerudgift er det beløb du indbetaler til din ejerforening per måned. Skal ikke indeholde udgifter til vand, el, og varme</span>
                                            <TriangleDown />
                                        </InputExtraInfo>
                                        {errors.ownerExpense && touched.ownerExpense &&
                                            <div className="col-12">
                                                <Error>
                                                <div>{errors.ownerExpense}</div>
                                                </Error>
                                            </div>
                                        }
                                    </InputGroup>
                                </div>
                                : ''
                            }
                        </Card>
                        <Card>
                            <div className="row">
                                <TooltipWrapper>
                                    <SmallLabel>
                                        <p>Indflytningsdato</p>
                                    </SmallLabel>
                                    <Info className="info-circle"><img onClick={() => toogleTooltip(false)} src="../images/information-tooltip.svg" alt="info" />
                                        {!showTooltip &&
                                            <InfoTextToolTip className="info-text">Indtast cirka tidspunkt for hvornår du er flyttet ind på din nuværende adresse<div className="info-triangle" />
                                                <CloseButton onClick={() => toogleTooltip(true)} type="button"></CloseButton>
                                            </InfoTextToolTip>
                                        }
                                    </Info>
                                </TooltipWrapper>
                                    <DateContainer>
                                                                            <DateDrop>
                                        <DropDownBtn className={values.addressSinceYear > 0 ? "validated dropbtn" : "dropbtn"} style={getStyles(errors.addressSinceYear, touched.addressSinceYear)} onClick={() => showDropdown("year")}>
                                            {values.addressSinceYear > 0 && <DateTitle className="dropbtn">År</DateTitle>}
                                            <span style={{ width: "80%" }} className={values.addressSinceYear > 0 ? "hidden-title dropbtn" : "dropbtn"}>{values.addressSinceYear > 0 ? values.addressSinceYear : "Vælg år"}</span>
                                            <img className={showYearDropdown ? "rotate dropbtn" : "dropbtn"} src="../images/drop-grey.svg" />
                                        </DropDownBtn>
                                        {showYearDropdown &&
                                            <div>
                                                <DropDown id="year-dropdown" className="scroll">
                                                    {drawYears()}
                                                </DropDown>
                                            </div>
                                        }
                                        {errors.addressSinceYear && touched.addressSinceYear &&
                                            <div className="col-12">
                                                <Error>
                                                    <div>{errors.addressSinceYear}</div>
                                                </Error>
                                            </div>
                                        }
                                        </DateDrop>
                                        <DateDrop>
                                        <DropDownBtn className={values.addressSinceMonthText != "" ? "validated dropbtn" : "dropbtn"} style={getStyles(errors.addressSinceMonth, touched.addressSinceMonth)} onClick={() => showDropdown("month")}>
                                            {values.addressSinceMonthText != "" && <DateTitle className="dropbtn">Måned</DateTitle>}
                                            <span style={{ width: "80%" }} className={values.addressSinceMonthText != "" ? "hidden-title dropbtn" : "dropbtn"}>{values.addressSinceMonthText != "" ? values.addressSinceMonthText : "Vælg måned"}</span>
                                            <img className={showMonthDropdown ? "rotate dropbtn" : "dropbtn"} src="../images/drop-grey.svg" />
                                        </DropDownBtn>
                                        {showMonthDropdown &&
                                            <div>
                                            <DropDown className="scroll">
                                                {values.addressSinceYear == currentYear && currentMonth < 1 ? "" : < DropDownValue className="dropvalue" onClick={() => setMonth("Januar", 1)}>Januar</DropDownValue>}
                                                {values.addressSinceYear == currentYear && currentMonth < 2 ? "" : <DropDownValue className="dropvalue" onClick={() => setMonth("Februar", 2)}>Februar</DropDownValue>}
                                                {values.addressSinceYear == currentYear && currentMonth < 3 ? "" : <DropDownValue className="dropvalue" onClick={() => setMonth("Marts", 3)}>Marts</DropDownValue>}
                                                {values.addressSinceYear == currentYear && currentMonth < 4 ? "" : <DropDownValue className="dropvalue" onClick={() => setMonth("April", 4)}>April</DropDownValue>}
                                                {values.addressSinceYear == currentYear && currentMonth < 5 ? "" : <DropDownValue className="dropvalue" onClick={() => setMonth("Maj", 5)}>Maj</DropDownValue>}
                                                {values.addressSinceYear == currentYear && currentMonth < 6 ? "" : <DropDownValue className="dropvalue" onClick={() => setMonth("Juni", 6)}>Juni</DropDownValue>}
                                                {values.addressSinceYear == currentYear && currentMonth < 7 ? "" : <DropDownValue className="dropvalue" onClick={() => setMonth("Juli", 7)}>Juli</DropDownValue>}
                                                {values.addressSinceYear == currentYear && currentMonth < 8 ? "" : <DropDownValue className="dropvalue" onClick={() => setMonth("August", 8)}>August</DropDownValue>}
                                                {values.addressSinceYear == currentYear && currentMonth < 9 ? "" : <DropDownValue className="dropvalue" onClick={() => setMonth("September", 9)}>September</DropDownValue>}
                                                {values.addressSinceYear == currentYear && currentMonth < 10 ? "" : <DropDownValue className="dropvalue" onClick={() => setMonth("Oktober", 10)}>Oktober</DropDownValue>}
                                                {values.addressSinceYear == currentYear && currentMonth < 11 ? "" : <DropDownValue className="dropvalue" onClick={() => setMonth("November", 11)}>November</DropDownValue>}
                                                {values.addressSinceYear == currentYear && currentMonth < 12 ? "" : <DropDownValue className="dropvalue" onClick={() => setMonth("December", 12)}>December</DropDownValue>}
                                                </DropDown>
                                            </div>
                                        }
                                        {errors.addressSinceMonth && touched.addressSinceMonth &&
                                            <div className="col-12">
                                                <Error>
                                                    <div>{errors.addressSinceMonth}</div>
                                                </Error>
                                            </div>
                                        }
                                        </DateDrop>
                                    </DateContainer>
                                        </div>
                        </Card>
                    </InnerWrapper>
                </ContentBox>
            </div>
        </div>
    )
}

export default StepThree