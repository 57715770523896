/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SamleLaan
 */
export interface SamleLaan {
    /**
     * 
     * @type {number}
     * @memberof SamleLaan
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SamleLaan
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SamleLaan
     */
    udbyder?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SamleLaan
     */
    restGaeld?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SamleLaan
     */
    maanedligYdelse?: number | null;
}

export function SamleLaanFromJSON(json: any): SamleLaan {
    return SamleLaanFromJSONTyped(json, false);
}

export function SamleLaanFromJSONTyped(json: any, ignoreDiscriminator: boolean): SamleLaan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'udbyder': !exists(json, 'udbyder') ? undefined : json['udbyder'],
        'restGaeld': !exists(json, 'restGaeld') ? undefined : json['restGaeld'],
        'maanedligYdelse': !exists(json, 'maanedligYdelse') ? undefined : json['maanedligYdelse'],
    };
}

export function SamleLaanToJSON(value?: SamleLaan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'udbyder': value.udbyder,
        'restGaeld': value.restGaeld,
        'maanedligYdelse': value.maanedligYdelse,
    };
}


