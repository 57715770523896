/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CarType,
    CarTypeFromJSON,
    CarTypeFromJSONTyped,
    CarTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface DbApplicationCars
 */
export interface DbApplicationCars {
    /**
     * 
     * @type {number}
     * @memberof DbApplicationCars
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DbApplicationCars
     */
    dbApplicationId?: string;
    /**
     * 
     * @type {CarType}
     * @memberof DbApplicationCars
     */
    carType?: CarType;
    /**
     * 
     * @type {number}
     * @memberof DbApplicationCars
     */
    leaseAmount?: number;
}

export function DbApplicationCarsFromJSON(json: any): DbApplicationCars {
    return DbApplicationCarsFromJSONTyped(json, false);
}

export function DbApplicationCarsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbApplicationCars {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dbApplicationId': !exists(json, 'dbApplicationId') ? undefined : json['dbApplicationId'],
        'carType': !exists(json, 'carType') ? undefined : CarTypeFromJSON(json['carType']),
        'leaseAmount': !exists(json, 'leaseAmount') ? undefined : json['leaseAmount'],
    };
}

export function DbApplicationCarsToJSON(value?: DbApplicationCars | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'dbApplicationId': value.dbApplicationId,
        'carType': CarTypeToJSON(value.carType),
        'leaseAmount': value.leaseAmount,
    };
}


