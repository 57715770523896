/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DbApplicationSamleLaan
 */
export interface DbApplicationSamleLaan {
    /**
     * 
     * @type {number}
     * @memberof DbApplicationSamleLaan
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DbApplicationSamleLaan
     */
    dbApplicationId?: string;
    /**
     * 
     * @type {number}
     * @memberof DbApplicationSamleLaan
     */
    restGaeld?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplicationSamleLaan
     */
    maanedligYdelse?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplicationSamleLaan
     */
    udbyder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplicationSamleLaan
     */
    type?: string | null;
}

export function DbApplicationSamleLaanFromJSON(json: any): DbApplicationSamleLaan {
    return DbApplicationSamleLaanFromJSONTyped(json, false);
}

export function DbApplicationSamleLaanFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbApplicationSamleLaan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dbApplicationId': !exists(json, 'dbApplicationId') ? undefined : json['dbApplicationId'],
        'restGaeld': !exists(json, 'restGaeld') ? undefined : json['restGaeld'],
        'maanedligYdelse': !exists(json, 'maanedligYdelse') ? undefined : json['maanedligYdelse'],
        'udbyder': !exists(json, 'udbyder') ? undefined : json['udbyder'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function DbApplicationSamleLaanToJSON(value?: DbApplicationSamleLaan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'dbApplicationId': value.dbApplicationId,
        'restGaeld': value.restGaeld,
        'maanedligYdelse': value.maanedligYdelse,
        'udbyder': value.udbyder,
        'type': value.type,
    };
}


