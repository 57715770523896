/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NEMIDResponse
 */
export interface NEMIDResponse {
    /**
     * 
     * @type {string}
     * @memberof NEMIDResponse
     */
    pid?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof NEMIDResponse
     */
    validPid?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NEMIDResponse
     */
    goAgain?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NEMIDResponse
     */
    isCoApp?: boolean;
}

export function NEMIDResponseFromJSON(json: any): NEMIDResponse {
    return NEMIDResponseFromJSONTyped(json, false);
}

export function NEMIDResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NEMIDResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pid': !exists(json, 'pid') ? undefined : json['pid'],
        'validPid': !exists(json, 'validPid') ? undefined : json['validPid'],
        'goAgain': !exists(json, 'goAgain') ? undefined : json['goAgain'],
        'isCoApp': !exists(json, 'isCoApp') ? undefined : json['isCoApp'],
    };
}

export function NEMIDResponseToJSON(value?: NEMIDResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pid': value.pid,
        'validPid': value.validPid,
        'goAgain': value.goAgain,
        'isCoApp': value.isCoApp,
    };
}


