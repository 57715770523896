import * as Yup from 'yup';
import { HouseType, CarOwnership, LoanReason, CitizenshipCategory, CivilStatus } from './Enums';

var cprRegex = /^(((0[1-9]|[12][0-9]|30)(0[13-9]|1[012])|31(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])02)[0-9]{2}|2902((([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))(-|\s)?\d{4}/;
var phoneRegex = /[2-9][0-9]{7}/;

function equalTo(ref, msg) {
    return this.test({
        name: 'equalTo',
        exclusive: false,
        message: msg || '${path} must be the same as ${reference}',
        params: {
            reference: ref.path
        },
        test: function (value) {
            return value === this.resolve(ref)
        }
    })
};

Yup.addMethod(Yup.string, 'equalTo', equalTo);

export const validationSchema = Yup.object().shape({
    email: Yup.string()
        .when('step', {
            is: step => step >= 2,
            then: Yup.string()
                .email("Den angivne email er ikke gyldig")
                .required("Angiv din email")
        })
    , consent: Yup.bool()
        .when('step', {
            is: step => step >= 9,
            then: Yup.bool().oneOf([true], "Mangler at blive checked")
                .required('Mangler at blive checked')

        })
    , facitConsent: Yup.bool()
        .when('step', {
            is: step => step >= 9,
            then: Yup.bool().oneOf([true], "Mangler at blive checked")
                .required('Mangler at blive checked')

        })
    , phoneNumber: Yup.string()
        .when('step', {
            is: step => step >= 2,
            then: Yup.string().required("Angiv mobilnummer").test('len', 'Angiv rigtigt telefonnummer', (val) => { if (val) return val.length == 8; })

        })
    , loanReason: Yup.string()
        .when('step', {
            is: step => step >= 1,
            then: Yup.string().required("Hvad vil du låne til?")

        })
    , otherReason: Yup.string()
        .when('loanReason', {
            is: loanReason => loanReason == LoanReason.Other,
            then: Yup.string().required("Du skal angive andet formål")
        })
    , foreName: Yup.string()
        .when('step', {
            is: step => step >= 2,
            then: Yup.string().required("Angiv dit fornavn")
        })
    , surName: Yup.string()
        .when('step', {
            is: step => step >= 2,
            then: Yup.string().required("Angiv dit efternavn")
        })
    , cpr: Yup.string()
        .when('step', {
            is: step => step >= 2,
            then: Yup.string().when("inSession", {
                is: inSession => inSession == false,
                then: Yup.string().matches(cprRegex, "Cpr nummer skal være gyldigt (ddmmyyxxyy)").required("Du skal angive CPR")
            })
        })
    , maritalStatus: Yup.string()
        .when('step', {
            is: step => step >= 3,
            then: Yup.string().required("Vælg din civilstatus")
        })
    , coForeName: Yup.string()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.string().when('step', {
                is: step => step >= 3,
                then: Yup.string().required("Angiv din medansøgers fornavn")
            })
        })
    , coPhoneNumber: Yup.string()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.string().when('step', {
                is: step => step >= 2,
                then: Yup.string().required("Angiv mobilnummer").test('len', 'Angiv rigtigt telefonnummer', (val) => { if (val) return val.length == 8; })
            })
        })
    , coSurName: Yup.string()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.string().when('step', {
                is: step => step >= 3,
                then: Yup.string().required("Indtast din medansøgers efternavn")
            })
        })
    , coEmail: Yup.string()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.string().when('step', {
                is: step => step >= 3,
                then: Yup.string()
                    .email("indtast en rigtigt mailadresse")
                    .required("Indtast din medansøgers mailadresse")
            })
        })
    , coCpr: Yup.string()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.string().when('step', {
                is: step => step >= 3,
                then: Yup.string().when("inSession", {
                    is: inSession => inSession == false,
                    then: Yup.string().matches(cprRegex, "Cpr nummer skal være gyldigt (ddmmyyxxyy)").required("Du skal angive CPR")
                })
            })
        })
    , dwelling: Yup.string()
        .when('step', {
            is: step => step >= 3,
            then: Yup.string().required("Vælg din boligform")
        })
    , houseRentalAmount: Yup.number()
        .when('step', {
            is: step => step >= 3,
            then: Yup.number().when('dwelling', {
                is: dwelling => dwelling != HouseType.Hus && dwelling != HouseType.Ejer && dwelling != "",
                then: Yup.number().moreThan(0, "Indtast din månedlige husleje")
            })
        })
    , ownerExpense: Yup.number()
        .when('step', {
            is: step => step >= 3,
            then: Yup.number().when('dwelling', {
                is: dwelling => dwelling == HouseType.Hus || dwelling == HouseType.Ejer,
                then: Yup.number().moreThan(0, "Indtast ejerudgifter per måned")
            })
        })
    , addressSinceMonth: Yup.number()
        .when('step', {
            is: step => step >= 3,
            then: Yup.number().moreThan(0, "Vælg måned").required("Vælg måned")
        })
    , householdShare: Yup.number()
        .when('step', {
            is: step => step >= 4,
            then:  Yup.number().lessThan(101, "Du kan maksimum betale 100%").moreThan(-1, "Angiv procentdel af udgifter")
        })
    , ancestralHome: Yup.string()
        .when('step', {
            is: step => step >= 5,
            then: Yup.string().when('isDanish', {
                is: isDanish => isDanish == false,
                then: Yup.string().required("Indtast ejerudgifter per måned")
            })
        })
    , addressSinceYear: Yup.number()
        .when('step', {
            is: step => step >= 3,
            then: Yup.number().moreThan(0, "Vælg år").required("Vælg år")
        })
    , householdHeatingExpense: Yup.number()
        .when('step', {
            is: step => step >= 4,
            then: Yup.number().moreThan(-1, "Angiv månedlig udgift til varme")
        })
    , householdUtilitiesExpense: Yup.number()
        .when('step', {
            is: step => step >= 4,
            then: Yup.number().moreThan(-1, "Angiv månedlig udgift til el og vand").required("Angiv månedlig udgift til el og vand")
        })
    , householdDigitalMediaExpense: Yup.number()
        .when('step', {
            is: step => step >= 4,
            then: Yup.number().moreThan(-1, "Angiv månedlig udgift til telefon, tv og internet").required("Angiv månedlig udgift til telefon, tv og internet")
        })
    , householdTransportationOtherExpense: Yup.number()
        .when('step', {
            is: step => step >= 7,
            then: Yup.number().moreThan(-1, "Angiv månedlige udgifter til andet transport").required("Angiv Månedlige udgifter til andet transport")
        })
    , employmentType: Yup.number()
        .when('step', {
            is: step => step >= 4,
            then: Yup.number().moreThan(-1, "Vælg din beskæftigelsestype").required("Vælg din beskæftigelsestype")
        })
    , employerIdentificationNumber: Yup.number()
        .when('step', {
            is: step => step >= 4,
            then: Yup.number().when('employmentType', {
                is: employmentType => employmentType == 6,
                then: Yup.number().moreThan(10000000, "Angiv CVR-nummer for dit firma").lessThan(100000000, "Dit CVR-nummer er for langt").required("Angiv CVR-nummer for dit firma")
            })
        })
    , ownVacationHouse: Yup.string()
        .when('step', {
            is: step => step >= 4,
            then: Yup.string().required("Ejer du et sommerhus?")
        })
    , hasHouseSupport: Yup.number()
        .when('step', {
            is: step => step >= 4,
            then: Yup.number()
                .when('dwelling', {
                    is: dwelling => dwelling != HouseType.Ejer || dwelling != HouseType.Hus,
                    then: Yup.number().moreThan(-1, "")
                })
        })
    , houseSupport: Yup.number()
        .when('dwelling', {
            is: dwelling => dwelling != HouseType.Ejer || dwelling != HouseType.Hus,
            then: Yup.number()
                .when('step', {
                    is: step => step >= 4,
                    then: Yup.number().when('hasHouseSupport', {
                        is: hasHouseSupport => hasHouseSupport == 1,
                        then: Yup.number().moreThan(0, "Angiv din boligstøtte om måneden")
                    })
                })
        })
    , employmentSinceMonth: Yup.number()
        .when('step', {
            is: step => step >= 4,
            then: Yup.number().moreThan(0, "Vælg måned").required("Vælg måned")
        })
    , employmentSinceYear: Yup.number()
        .when('step', {
            is: step => step >= 4,
            then: Yup.number().moreThan(0, "Vælg år").required("Vælg år")
        })
    , householdInsurance: Yup.number()
        .when('step', {
            is: step => step >= 7,
            then: Yup.number().moreThan(-1, "Vælg din årlige udgifter til bil, indbo, hus og ulykke")
        })
    , samleLaan: Yup.array()
        .when('isSamleLaan', {
            is: isSamleLaan => isSamleLaan == true,
            then: Yup.array().of(
                Yup.object({
                    restGaeld: Yup.number().nullable().required("Du skal indtaste restgæld").min(100, "Beløbet er for lavt"),
                    udbyder: Yup.string().nullable().required("Du skal indtaste udbyder")
                }))
        })
    , cars: Yup.array()
        .when('step', {
            is: step => step >= 7,
            then: Yup.array().
                when('householdCarsNumber', {
                    is: householdCarsNumber => householdCarsNumber >= 1,
                    then: Yup.array().of(
                        Yup.object({
                            carType: Yup.number().nullable().moreThan(-1, "Vælg bilens type").required("Vælg bilens type"),
                            leaseAmount: Yup.number().when('carType', {
                                is: carType => carType == "Leaset",
                                then: Yup.number().moreThan(0, "Angiv din Månedlige udgifter til leasing").required("Du skal indtaste leasing beløb")
                            })
                        }))
                })
        })
    , children: Yup.array()
        .when('step', {
            is: step => step >= 5,
            then: Yup.array().
                when('numOfChildren', {
                    is: numOfChildren => numOfChildren >= 1,
                    then: Yup.array().of(
                        Yup.object({
                            age: Yup.number().moreThan(-1, "Angiv barnets alder").required("Angiv barnets alder")
                        }))
                })
        })
    , otherChildSupport: Yup.number()
        .when('step', {
            is: step => step >= 5,
            then: Yup.number()
                .when('numOfChildren', {
                    is: numOfChildren => numOfChildren > 0,
                    then: Yup.number().moreThan(-1, "Vælg om du modtager andet børnebidrag")
                })
        })
    , daycare: Yup.number()
        .when('step', {
            is: step => step >= 5,
            then: Yup.number()
                .when('numOfChildren', {
                    is: numOfChildren => numOfChildren > 0,
                    then: Yup.number().moreThan(-1, "Vælg om du har børn i dagpleje")
                })
        })
    , childSupport: Yup.number()
        .when('step', {
            is: step => step >= 5,
            then: Yup.number()
                .when('numOfChildren', {
                    is: numOfChildren => numOfChildren > 0,
                    then: Yup.number()
                        .when('otherChildSupport', {
                            is: otherChildSupport => otherChildSupport == 1,
                            then: Yup.number().moreThan(0, "indtast beløb")
                        })
                })
        })
    , childExpense: Yup.number()
        .when('step', {
            is: step => step >= 5,
            then: Yup.number()
                .when('numOfChildren', {
                    is: numOfChildren => numOfChildren > 0,
                    then: Yup.number()
                        .when('daycare', {
                            is: daycare => daycare == 1,
                            then: Yup.number().moreThan(0, "indtast beløb")
                        })
                })
        })


    , jobInsuranceStatus: Yup.number()
        .when('step', {
            is: step => step >= 5,
            then: Yup.number().moreThan(-1, "Vælg måned").required("Vælg måned")
        })
    , jobInsuranceSinceYear: Yup.number()
        .when('step', {
            is: step => step >= 5,
            then: Yup.number()
                .when('jobInsuranceStatus', {
                    is: jobInsuranceStatus => jobInsuranceStatus == 1,
                    then: Yup.number().moreThan(0, "Vælg hvor længe du har haft a-kasse")
                })
        })
    // , rkiMember: Yup.string()
    //     .when('step', {
    //         is: step => step >= 2,
    //         then: Yup.string().required("Vælg måned").test('len', 'Angiv rigtigt telefonnummer', (val) => { if (val) return val.length > 1; })
    //     })
    , citizenship: Yup.number()
        .when('step', {
            is: step => step >= 5,
            then: Yup.number().moreThan(-1, "Vælg måned").required("Vælg måned")
        })
    , citizenshipCategory: Yup.number()
        .when('step', {
            is: step => step >= 5,
            then: Yup.number()
                .when('citizenship', {
                    is: citizenship => citizenship == 0,
                    then: Yup.number().moreThan(0, "Vælg type statsborgerskab")
                })
        })
    , permitToStay: Yup.number()
        .when('step', {
            is: step => step >= 5,
            then: Yup.number().when('citizenshipCategory', {
                is: citizenshipCategory => citizenshipCategory == CitizenshipCategory.Other,
                then: Yup.number().moreThan(0, "Vælg type af opholdstilladelse").required("Vælg type af opholdstilladelse")
            })
        })
    , permitTypeTemporary: Yup.string()
        .when('step', {
            is: step => step >= 5,
            then: Yup.string().when('citizenshipCategory', {
                is: citizenshipCategory => citizenshipCategory == CitizenshipCategory.Other,
                then: Yup.string().when('permitToStay', {
                    is: permitToStay => permitToStay == 1,
                    then: Yup.string().required("Vælg type af midlertidig opholdstilladelse")
                })
            })
        })
    , permitTypePermanent: Yup.string()
        .when('step', {
            is: step => step >= 5,
            then: Yup.string().when('citizenshipCategory', {
                is: citizenshipCategory => citizenshipCategory == CitizenshipCategory.Other,
                then: Yup.string().when('permitToStay', {
                    is: permitToStay => permitToStay == 2,
                    then: Yup.string().required("Vælg type af permanent opholdstilladelse")
                })
            })
        })
    , permitForeignerNumber: Yup.string()
        .when('step', {
            is: step => step >= 5,
            then: Yup.string().when('citizenshipCategory', {
                is: citizenshipCategory => citizenshipCategory == CitizenshipCategory.Other,
                then: Yup.string().required("Angiv udlændingenummer").test('len', 'udlændingenummer er for langt', (val) => { if (val) return val.length <= 12; })
            })
        })
    , permitNumber: Yup.string()
        .when('step', {
            is: step => step >= 5,
            then: Yup.string().when('citizenshipCategory', {
                is: citizenshipCategory => citizenshipCategory == CitizenshipCategory.Other,
                then: Yup.string().when('permitToStay', {
                    is: permitToStay => permitToStay == 2 || permitToStay == 1,
                    then: Yup.string().required("Angiv løbenummer").test('len', 'løbenummer er for langt', (val) => { if (val) return val.length <= 8; })
                })
            })
        })
    , incomeGrossAmount: Yup.number()
        .when('step', {
            is: step => step >= 6,
            then: Yup.number().required("Angiv din indkomst før skat").moreThan(0, "Angiv din indkomst før skat")
        })
    , incomeNetAmount: Yup.number()
        .when('step', {
            is: step => step >= 6,
            then: Yup.number().required("Angiv din indkomst efter skat").moreThan(0, "Angiv din indkomst efter skat")
                .lessThan(Yup.ref("incomeGrossAmount"), "Indkomst efter skal være mindre en indkom før skat")
        })
    , incomeOtherType: Yup.number()
        .when('step', {
            is: step => step >= 6,
            then: Yup.number().required("Angiv anden indkomst pr. måned efter skat").moreThan(-1, "Angiv anden indkomst pr. måned efter skat")
        })
    , incomeOtherAmount: Yup.number()
        .when('step', {
            is: step => step >= 6,
            then: Yup.number().when('incomeOtherType', {
                is: incomeOtherType => incomeOtherType == 1,
                then: Yup.number().required("Angiv beløb").moreThan(0, "Angiv beløb")
            })
        })
    , householdDiscretionaryIncome: Yup.number()
        .when('step', {
            is: step => step >= 6,
            then: Yup.number().required("Angiv dit månedlige rådighedsbeløb").moreThan(-1, "Angiv dit månedlige rådighedsbeløb")
        }).lessThan(Yup.ref("incomeNetAmount"), "Rådighedsbeløb kan ikke være højere end din indkomst efter skat")
    , creditCardStatus: Yup.number()
        .when('step', {
            is: step => step >= 6,
            then: Yup.number().required("Har du Dankort eller Visa/Dankort?").moreThan(-1, "Har du Dankort eller Visa/Dankort?")
        })
    , mastercardStatus: Yup.string()
        .when('step', {
            is: step => step >= 6,
            then: Yup.string().required("Har du Dankort eller Visa/Dankort?")
        })
    , debtSinceJan: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number().required("Angiv gæld siden 1. januar i år").moreThan(-1, "Angiv gæld siden 1. januar i år")
        })
    //, monthlyDebtExpense: Yup.number()
    //    .when('step', {
    //        is: step => step >= 8,
    //        then: Yup.number().required("Indtast beløb").moreThan(-1, "Indtast beløb (hvis du ikke har noget afdrag, skal du bare skrive 0)")
    //    })
    , coDebtSinceJan: Yup.number()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.number().when('step', {
                is: step => step >= 8,
                then: Yup.number().required("Har du Dankort eller Visa/Dankort?").moreThan(-1, "Angiv gæld siden 1. januar i år")
            })
        })
    , debtSinceLastTexReport: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number().when('debtSinceJan', {
                is: debtSinceJan => debtSinceJan == 1,
                then: Yup.number().required("Angiv gæld siden 1. januar i år").moreThan(0, "Angiv gæld siden 1. januar i år")
            })
        })
    , coDebtSinceLastTexReport: Yup.number()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.number().when('step', {
                is: step => step >= 8,
                then: Yup.number().when('coDebtSinceJan', {
                    is: coDebtSinceJan => coDebtSinceJan == 1,
                    then: Yup.number().required("Angiv gæld siden 1. januar i år").moreThan(0, "Angiv gæld siden 1. januar i år")
                })
            })
        })
    , householdOtherLoanRest: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number().when('otherLoan', {
                is: otherLoan => otherLoan == 1,
                then: Yup.number().required("Angiv restgæld").moreThan(0, "Angiv restgæld")
            })
        })
    , householdOtherLoanExpense: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number().when('otherLoan', {
                is: otherLoan => otherLoan == 1,
                then: Yup.number().required("Angiv afdrag").moreThan(-1, "Angiv afdrag")
            })
        })
    , carLoanRest: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number().when('carLoan', {
                is: carLoan => carLoan == 1,
                then: Yup.number().required("Angiv restgæld").moreThan(0, "Angiv restgæld")
            })
        })
    , carLoanExpense: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number().when('carLoan', {
                is: carLoan => carLoan == 1,
                then: Yup.number().required("Angiv afdrag").moreThan(-1, "Angiv afdrag")
            })
        })
    , studyLoanRest: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number().when('studyLoan', {
                is: studyLoan => studyLoan == 1,
                then: Yup.number().required("Angiv restgæld").moreThan(0, "Angiv restgæld")
            })
        })
    , studyLoanExpense: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number().when('studyLoan', {
                is: studyLoan => studyLoan == 1,
                then: Yup.number().required("Angiv afradg").moreThan(-1, "Angiv afdrag")
            })
        })
    , publicDebtRest: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number().when('publicDebt', {
                is: publicDebt => publicDebt == 1,
                then: Yup.number().required("Angiv restgæld").moreThan(0, "Angiv restgæld")
            })
        })
    , publicDebtExpense: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number().when('publicDebt', {
                is: publicDebt => publicDebt == 1,
                then: Yup.number().required("Angiv afradg").moreThan(-1, "Angiv afdrag")
            })
        })
    , consumerLoanRest: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number().when('consumerLoan', {
                is: consumerLoan => consumerLoan == 1,
                then: Yup.number().required("Angiv restgæld").moreThan(0, "Angiv restgæld")
            })
        })
    , consumerLoanExpense: Yup.number() 
        .when('step', {
            is: step => step >= 8,
            then: Yup.number().when('consumerLoan', {
                is: consumerLoan => consumerLoan == 1,
                then: Yup.number().required("Angiv afdrag").moreThan(-1, "Angiv afdrag")
            })
        })
    , otherLoan: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number().required("Har du Dankort eller Visa/Dankort?").moreThan(-1, "Har du Dankort eller Visa/Dankort?")
        })
    , carLoan: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number().required("Har du Dankort eller Visa/Dankort?").moreThan(-1, "Har du Dankort eller Visa/Dankort?")
        })
    , studyLoan: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number().required("Har du Dankort eller Visa/Dankort?").moreThan(-1, "Har du Dankort eller Visa/Dankort?")
        })
    , publicDebt: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number().required("Har du Dankort eller Visa/Dankort?").moreThan(-1, "Har du Dankort eller Visa/Dankort?")
        })
    , consumerLoan: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number().required("Har du Dankort eller Visa/Dankort?").moreThan(-1, "Har du Dankort eller Visa/Dankort?")
        })
    , hasOtherDebt: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number().required("har du øvrig gæld").moreThan(-1, "har du øvrig gæld")
        })
    , otherDebt: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number()
                .when('hasOtherDebt', {
                    is: hasOtherDebt => hasOtherDebt == 1,
                    then: Yup.number().required("Angiv øvrig gæld").moreThan(0, "Angiv øvrig gæld")
                })

        })
    , otherDebtExpense: Yup.number()
        .when('step', {
            is: step => step >= 8,
            then: Yup.number()
                .when('hasOtherDebt', {
                    is: hasOtherDebt => hasOtherDebt == 1,
                    then: Yup.number().required("Indtast afdrag").moreThan(-1, "Indtast afdrag")
                })

        }) 
    , creditPaymentBankCode: Yup.string()
        .when('step', {
            is: step => step >= 9,
            then: Yup.string().required("Angiv registreringsnummer").test('len', 'registreringsnummer er ikke validt', (val) => { if (val) return val.length == 4; }).matches(/^\d+$/, "registreringsnummer er ikke validt")
        })
    , creditPaymentAccountNumber: Yup.string()
        .when('step', {
            is: step => step >= 9,
            then: Yup.string().required("Angiv kontonummer").test('len', 'Kontonummer skal indeholde 10 ciffer inkl. evt. 0-taller foran kontonummer', (val) => { if (val) return val.length == 10; }).matches(/^\d+$/, "Kontonummer skal indeholde 10 ciffer inkl. evt. 0-taller foran kontonummer")
        })
    , bankSinceYear: Yup.number()
        .when('step', {
            is: step => step >= 9,
            then: Yup.number().required("Har du Dankort eller Visa/Dankort?").moreThan(0, "Har du Dankort eller Visa/Dankort?")
        })
    , coEmploymentType: Yup.number()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.number().when('step', {
                is: step => step >= 4,
                then: Yup.number().moreThan(-1, "Vælg medansøgers beskæftigelsestype").required("Vælg medansøgers beskæftigelsestype")
            })
        })
    , coEmploymentSinceMonth: Yup.number()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.number().when('step', {
                is: step => step >= 4,
                then: Yup.number().moreThan(0, "Vælg måned").required("Vælg måned")
            })
        })
    , coEmploymentSinceYear: Yup.number()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.number().when('step', {
                is: step => step >= 4,
                then: Yup.number().moreThan(0, "Vælg år").required("Vælg år")
            })
        })
    , coJobInsuranceStatus: Yup.number()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.number().when('step', {
                is: step => step >= 5,
                then: Yup.number().moreThan(-1, "Vælg måned").required("Vælg måned")
            })
        })
    , coCitizenship: Yup.number()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.number()
                .when('step', {
                    is: step => step >= 5,
                    then: Yup.number().moreThan(-1, "Vælg måned").required("Vælg måned")
                })
        })
    , coPermitToStay: Yup.number()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.number()
                .when('step', {
                    is: step => step >= 5,
                    then: Yup.number().when('coCitizenship', {
                        is: coCitizenship => coCitizenship == 0,
                        then: Yup.number().moreThan(0, "Vælg type af opholdstilladelse").required("Vælg type af opholdstilladelse")
                    })
                })
        })
    , coPermitTypeTemporary: Yup.string()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.string()
                .when('step', {
                    is: step => step >= 5,
                    then: Yup.string().when('coCitizenship', {
                        is: coCitizenship => coCitizenship == 0,
                        then: Yup.string().when('coPermitToStay', {
                            is: coPermitToStay => coPermitToStay == 1,
                            then: Yup.string().required("Vælg type af midlertidig opholdstilladelse")
                        })
                    })
                })
        })
    , coPermitTypePermanent: Yup.string()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.string()
                .when('step', {
                    is: step => step >= 5,
                    then: Yup.string().when('coCitizenship', {
                        is: coCitizenship => coCitizenship == 0,
                        then: Yup.string().when('coPermitToStay', {
                            is: coPermitToStay => coPermitToStay == 2,
                            then: Yup.string().required("Vælg type af permanent opholdstilladelse")
                        })
                    })
                })
        })
    , coPermitForeignerNumber: Yup.string()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.string()
                .when('step', {
                    is: step => step >= 5,
                    then: Yup.string().when('coCitizenship', {
                        is: coCitizenship => coCitizenship == 0,
                        then: Yup.string().required("Angiv udlændingenummer").test('len', 'udlændingenummer er for langt', (val) => { if (val) return val.length <= 12; })
                    })
                })
        })
    , coPermitNumber: Yup.string()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.string()
                .when('step', {
                    is: step => step >= 5,
                    then: Yup.string().when('coCitizenship', {
                        is: coCitizenship => coCitizenship == 0,
                        then: Yup.string().when('coPermitToStay', {
                            is: coPermitToStay => coPermitToStay == 2 || coPermitToStay == 1,
                            then: Yup.string().required("Angiv løbenummer").test('len', 'løbenummer er for langt', (val) => { if (val) return val.length <= 8; })
                        })
                    })
                })
        })
    , coPermitExpiryMonth: Yup.number()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.number().when('step', {
                is: step => step >= 5,
                then: Yup.number().when('coPermitToStay', {
                    is: coPermitToStay => coPermitToStay == 1,
                    then: Yup.number().moreThan(0, "Vælg måned").required("Vælg måned")
                })
            })
        })
    , coPermitExpiryYear: Yup.number()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.number().when('step', {
                is: step => step >= 5,
                then: Yup.number().when('coPermitToStay', {
                    is: coPermitToStay => coPermitToStay == 1,
                    then: Yup.number().moreThan(0, "Vælg år").required("Vælg år")
                })
            })
        })
    , permitExpiryMonth: Yup.number()
            .when('step', {
                is: step => step >= 5,
                then: Yup.number().when('coPermitToStay', {
                    is: coPermitToStay => coPermitToStay == 1,
                    then: Yup.number().moreThan(0, "Vælg måned").required("Vælg måned")
                })
            })
    , permitExpiryYear: Yup.number()
            .when('step', {
                is: step => step >= 5,
                then: Yup.number().when('coPermitToStay', {
                    is: coPermitToStay => coPermitToStay == 1,
                    then: Yup.number().moreThan(0, "Vælg år").required("Vælg år")
                })
            })
    , livedSinceMonth: Yup.number()
            .when('step', {
                is: step => step >= 5,
                then: Yup.number().when('citizenshipCategory', {
                    is: citizenshipCategory => citizenshipCategory == 3,
                    then: Yup.number().moreThan(0, "Vælg måned").required("Vælg måned")
                })
            })
    , livedSinceYear: Yup.number()
            .when('step', {
                is: step => step >= 5,
                then: Yup.number().when('citizenshipCategory', {
                    is: citizenshipCategory => citizenshipCategory == 3,
                    then: Yup.number().moreThan(0, "Vælg år").required("Vælg år")
                })
            })
    , coIncomeGrossAmount: Yup.number()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.number()
                .when('step', {
                    is: step => step >= 6,
                    then: Yup.number().required("Angiv månedlig indkomst før skat").moreThan(0, "månedlig indkomst før skat skal være højere end 0")
                })
        })
    , coIncomeNetAmount: Yup.number()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.number()
                .when('step', {
                    is: step => step >= 6,
                    then: Yup.number().required("Angiv månedlig indkomst efter skat")
                        .moreThan(0, "månedlig indkomst efter skat skal være højere end 0")
                        .lessThan(Yup.ref("coIncomeGrossAmount"), "Needs to be less than gross")
                })
        })
    , coIncomeOtherType: Yup.number()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.number()
                .when('step', {
                    is: step => step >= 6,
                    then: Yup.number().required("Har du anden indtægtskilde?").moreThan(-1, "Har du anden indtægtskilde?")
                })
        })
    , coIncomeOtherAmount: Yup.number()
        .when('coApplicant', {
            is: coApplicant => coApplicant == true,
            then: Yup.number()
                .when('step', {
                    is: step => step >= 6,
                    then: Yup.number().when('coIncomeOtherType', {
                        is: coIncomeOtherType => coIncomeOtherType == 1,
                        then: Yup.number().required("Angiv månedlig indkomst efter skat").moreThan(0, "Angiv månedlig indkomst efter skat")
                    })
                })
        })
    , rki: Yup.number()
        .when('step', {
            is: step => step >= 2,
            then: Yup.number().required("Er du registreret i RKI?").moreThan(-1, "Er du registreret i RKI?")
        })
});