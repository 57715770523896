/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DbApplicationSecureInfo
 */
export interface DbApplicationSecureInfo {
    /**
     * 
     * @type {number}
     * @memberof DbApplicationSecureInfo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DbApplicationSecureInfo
     */
    dbApplicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof DbApplicationSecureInfo
     */
    regNr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplicationSecureInfo
     */
    accountNr?: string | null;
}

export function DbApplicationSecureInfoFromJSON(json: any): DbApplicationSecureInfo {
    return DbApplicationSecureInfoFromJSONTyped(json, false);
}

export function DbApplicationSecureInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbApplicationSecureInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dbApplicationId': !exists(json, 'dbApplicationId') ? undefined : json['dbApplicationId'],
        'regNr': !exists(json, 'regNr') ? undefined : json['regNr'],
        'accountNr': !exists(json, 'accountNr') ? undefined : json['accountNr'],
    };
}

export function DbApplicationSecureInfoToJSON(value?: DbApplicationSecureInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'dbApplicationId': value.dbApplicationId,
        'regNr': value.regNr,
        'accountNr': value.accountNr,
    };
}


