declare var window: {
    dataLayer: any[]
}

export function trackEvent(
    category: string,
    action: string,
    label: string,
    value?: any //? gør den optional
) {
    if (window.dataLayer) {
        window.dataLayer.push({
            'event': 'GAEvent',
            'eventCategory': category,
            'eventAction': action,
            'eventLabel': label,
            'eventValue': value
        });
    }
}


export function trackEcommerce(
    transactionId: string,
    Amount: number,
    Time: number,



) {
    if (window.dataLayer) {
        window.dataLayer.push({
            'event': 'ECEvent',
            'transactionId': transactionId,
            'transactionTotal': (Amount * 0.1),

            'transactionProducts': [{
                'sku': 'LD' + transactionId,
                'name': 'Beløb: ' + Amount + ' Tid:' + Time,
                'price': Amount,
                'quantity': 1
            }]
        });
    }
}