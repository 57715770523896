export const options = [
    { name: 'Alfalån', value: `AlfaLån` },
    { name: 'Alm. Brand Bank', value: `Alm. Brand Bank` },
    { name: 'Anna Finans', value: `Anna Finans` },
    { name: 'Arbejdernes Landsbank', value: `Arbejdernes Landsbank` },
    { name: 'Aurora Lån', value: `Aurora Lån` },
    { name: 'Bank Norwegian', value: `Bank Norwegian` },
    { name: 'Basis Bank', value: `Basis Bank` },
    { name: 'Bedre Kredit', value: `Bedre Kredit` },
    { name: 'BG Bank', value: `BG Bank` },
    { name: 'Bonuslån', value: `Bonuslån` },
    { name: 'Cashper', value: `Cashper` },
    { name: 'Coop Ny Bank', value: `Coop Ny Bank` },
    { name: 'D:E:R', value: `D:E:R` },
    { name: 'Danaktiv', value: `Danaktiv` },
    { name: 'Danske Bank', value: `Danske Bank` },
    { name: 'Ekspert Lån', value: `Ekspert Lån` },
    { name: 'Ellos', value: `Ellos` },
    { name: 'Entercard', value: `Entercard` },
    { name: 'Express Bank A/S', value: `Express Bank A/S` },
    { name: 'Extra Cash', value: `Extra Cash` },
    { name: 'Ferratum', value: `Ferratum` },
    { name: 'Folkelånet', value: `Folkelånet` },
    { name: 'Forbrugsforeningen', value: `Forbrugsforeningen` },
    { name: 'Fønix Privatlån', value: `Fønix Privatlån` },
    { name: 'GE Money Bank', value: `GE Money Bank` },
    { name: 'Go Kredit', value: `Go Kredit` },
    { name: 'HG Finans', value: `HG Finans` },
    { name: 'Hotlån', value: `Hotlån` },
    { name: 'Ikano Bank', value: `Ikano Bank` },
    { name: 'Intrum / GreenCredit', value: `Intrum / GreenCredit` },
    { name: 'IQ Finans', value: `IQ Finans` },
    { name: 'Jyske Bank', value: `Jyske Bank` },
    { name: 'Kassekreditten', value: `Kassekreditten` },
    { name: 'Klarna', value: `Klarna` },
    { name: 'Kredit NU', value: `Kredit NU` },
    { name: 'Kreditstatus', value: `Kreditstatus` },
    { name: 'Kvikto', value: `Kvikto` },
    { name: 'Leasy', value: `Leasy` },
    { name: 'Lendon', value: `Lendon` },
    { name: 'Lån og Sparbank', value: `Lån og Sparbank` },
    { name: 'LånLet', value: `LånLet` },
    { name: 'Mini Finans', value: `Mini Finans` },
    { name: 'Mini Lån', value: `Mini Lån` },
    { name: 'Modus Finans', value: `Modus Finans` },
    { name: 'Moment Lån', value: `Moment Lån` },
    { name: 'Nordea', value: `Nordea` },
    { name: 'Nordea Finans', value: `Nordea Finans` },
    { name: 'Nordea Frihed30', value: `Nordea Frihed30` },
    { name: 'Nordic Lån', value: `Nordic Lån` },
    { name: 'Nordisk Lån', value: `Nordisk Lån` },
    { name: 'Nykredit', value: `Nykredit` },
    { name: 'Paymark Finans', value: `Paymark Finans` },
    { name: 'Prima Lån', value: `Prima Lån` },
    { name: 'Re:member', value: `Re:member` },
    { name: 'Resurs Bank', value: `Resurs Bank` },
    { name: 'Robotlån', value: `Robotlån` },
    { name: 'SEB Kort', value: `SEB Kort` },
    { name: 'Santander', value: `Santander` },
    { name: 'Selene Finans', value: `Selene Finans` },
    { name: 'Simbo', value: `Simbo` },
    { name: 'Spar Nord', value: `Spar Nord` },
    { name: 'Spargo Finans', value: `Spargo Finans` },
    { name: 'Sparxpres', value: `Sparxpres` },
    { name: 'SU Styrelsen', value: `SU Styrelsen` },
    { name: 'Tjenestemændenes Låneforening', value: `Tjenestemændenes Låneforening` },
    { name: 'Turbolån', value: `Turbolån` },
    { name: 'Uno Finans', value: `Uno Finans` },
    { name: 'Viabill', value: `Viabill` },
    { name: 'Vivus', value: `Vivus` },
    { name: 'Zetfinans', value: `Zetfinans` },
    { name: 'Zaplo', value: `Zaplo` },
    { name: 'Øvrige', value: `Øvrige` },


]