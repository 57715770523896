/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BinaryBool {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}

export function BinaryBoolFromJSON(json: any): BinaryBool {
    return BinaryBoolFromJSONTyped(json, false);
}

export function BinaryBoolFromJSONTyped(json: any, ignoreDiscriminator: boolean): BinaryBool {
    return json as BinaryBool;
}

export function BinaryBoolToJSON(value?: BinaryBool | null): any {
    return value as any;
}

