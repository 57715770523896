/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SignicatAuthMethod,
    SignicatAuthMethodFromJSON,
    SignicatAuthMethodToJSON,
    SignicatRedirect,
    SignicatRedirectFromJSON,
    SignicatRedirectToJSON,
} from '../models';

export interface GetAuthenticationRedirectUriRequest {
    method?: SignicatAuthMethod;
    returnUrl?: string | null;
}

/**
 * 
 */
export class AuthenticationApi extends runtime.BaseAPI {

    /**
     */
    async getAuthenticationRedirectUriRaw(requestParameters: GetAuthenticationRedirectUriRequest): Promise<runtime.ApiResponse<SignicatRedirect>> {
        const queryParameters: any = {};

        if (requestParameters.method !== undefined) {
            queryParameters['method'] = requestParameters.method;
        }

        if (requestParameters.returnUrl !== undefined) {
            queryParameters['returnUrl'] = requestParameters.returnUrl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/authentication/redirect`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SignicatRedirectFromJSON(jsonValue));
    }

    /**
     */
    async getAuthenticationRedirectUri(requestParameters: GetAuthenticationRedirectUriRequest): Promise<SignicatRedirect> {
        const response = await this.getAuthenticationRedirectUriRaw(requestParameters);
        return await response.value();
    }

}
