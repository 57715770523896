import { useFormikContext } from "formik";
import React from "react";
import styled from "styled-components";


export const ProgressCon = styled.div`
position: absolute;
display: flex;
align-items: center;
bottom: 20px;
left: 0;
right: 0;
margin-left: auto;
margin-right: auto;
width: 90%;
@media only screen and (max-width: 1300px) {
width: 60%;

    }

`

export const ProgressCircle = styled.div`
width: 15px;
height: 15px;
background-color: #2A9D8F;
opacity: 0.4;
border-radius: 100%;
display: flex;
justify-content: center;
align-items: center;
img{
width: 8px;
}
`

export const ProgressLine = styled.div`
width: 21px;
height: 5px;
border-radius: 3px;
background-color: #2A9D8F;
margin-left: 15px;
opacity: 0.4;
`



const ProgressBar: React.FunctionComponent = () => {
    const { values, setFieldValue, handleSubmit, isSubmitting, submitForm, handleChange } = useFormikContext<any>();
    return (
        <div>
            <ProgressCon>
                <ProgressLine style={values.step >= 3  ? { opacity: 1 } : {}}></ProgressLine>
                <ProgressLine style={values.step >= 6 ? { opacity: 1 } : {}}></ProgressLine>
                <ProgressLine style={values.step >= 7 ? { opacity: 1 } : {}}></ProgressLine>
                <ProgressLine style={values.step >= 8 ? { opacity: 1 } : {}}></ProgressLine>
                <ProgressLine style={values.step >= 9 ? { opacity: 1 } : {}}></ProgressLine>
            </ProgressCon>
        </div>
    )
}

export default ProgressBar;