var counter = 0

export class SamleLaan {
    id: number;
    type?: string;
    udbyder?: string;
    restGaeld?: number;
    maanedligYdelse?: number;

    constructor() {
        this.id = counter++;
        this.type = null;
        this.udbyder = null;
        this.restGaeld = 0;
        this.maanedligYdelse = 0;
    }
}