/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DbApplicationCarInfo
 */
export interface DbApplicationCarInfo {
    /**
     * 
     * @type {number}
     * @memberof DbApplicationCarInfo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DbApplicationCarInfo
     */
    dbApplicationId?: string;
    /**
     * 
     * @type {number}
     * @memberof DbApplicationCarInfo
     */
    carPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof DbApplicationCarInfo
     */
    carPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof DbApplicationCarInfo
     */
    carPaymentPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof DbApplicationCarInfo
     */
    carYear?: number;
}

export function DbApplicationCarInfoFromJSON(json: any): DbApplicationCarInfo {
    return DbApplicationCarInfoFromJSONTyped(json, false);
}

export function DbApplicationCarInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbApplicationCarInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dbApplicationId': !exists(json, 'dbApplicationId') ? undefined : json['dbApplicationId'],
        'carPayment': !exists(json, 'carPayment') ? undefined : json['carPayment'],
        'carPrice': !exists(json, 'carPrice') ? undefined : json['carPrice'],
        'carPaymentPercentage': !exists(json, 'carPaymentPercentage') ? undefined : json['carPaymentPercentage'],
        'carYear': !exists(json, 'carYear') ? undefined : json['carYear'],
    };
}

export function DbApplicationCarInfoToJSON(value?: DbApplicationCarInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'dbApplicationId': value.dbApplicationId,
        'carPayment': value.carPayment,
        'carPrice': value.carPrice,
        'carPaymentPercentage': value.carPaymentPercentage,
        'carYear': value.carYear,
    };
}


