/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum BankEnum {
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7,
    NUMBER_8 = 8,
    NUMBER_9 = 9
}

export function BankEnumFromJSON(json: any): BankEnum {
    return BankEnumFromJSONTyped(json, false);
}

export function BankEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): BankEnum {
    return json as BankEnum;
}

export function BankEnumToJSON(value?: BankEnum | null): any {
    return value as any;
}

