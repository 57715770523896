import { useFormikContext } from 'formik';
import Slider, { createSliderWithTooltip } from "rc-slider";
import React from "react";
import styled from "styled-components";
import CurrencyInput from "./Components/CurrencyInput";
import { InnerWrapper } from "./FormContainer";

const interest = 0.07 / 12;
var url = window.location.pathname;
var initAmount = 150000;
var data = (url.match(/(\d+)/g));
if (data != null) {
    initAmount = parseInt(data[0]);
}
const SliderWithTooltip = createSliderWithTooltip(Slider);
export const SliderHolder = styled.div`
    color:#0F3166;
    text-align:center;
    font-size:20px;
    width: 100%;
    margin: 60px auto;
    label{
        font-size:16px;
        opacity:0.5;
      
    }

`

export const Header = styled.h3`
color: #315665;
text-align: left;
font-weight: 600;
font-style: normal;
margin: 0;
font-size: 24px;

        @media only screen and (max-width: 1300px) {
        font-size: 26px;
    }

@media only screen and (max-width: 980px) {
        font-size: 22px;
    }

@media only screen and (max-width: 400px) {
        width: 100%;
        margin-left: -10px;
        margin-right: -15px;
    }

`

export const HeaderContainer = styled.div`
text-align: center;
width: 80%;
display: flex;
justify-content: space-between;
align-items: center;
margin: 15px auto;
margin-bottom: 30px;

@media only screen and (max-width: 980px) {
        width: 90%;
    }

`

export const ValutaInputContainer = styled.div`
width: 100%;
display: flex;
font-size: 22px;
background-color: rgba(204, 213, 217, 0.23);
border-bottom: 2px solid #315665;
font-weight: 600;


`

export const Valuta = styled.div`
font-size: 20px;
display: flex;
align-items: center;

`

export const ValutaInput = styled.input`
text-align: center;
border: 0px;
color: #315665;
width: 73%;
padding: 3px 0;
font-size: 24px;
font-weight: 600;

background-color: transparent;
&:focus{
outline: none !important;
}
font-style: normal;
        @media only screen and (max-width: 1300px) {
    font-size: 24px;
    }
@media only screen and (max-width: 980px) {
        font-size: 22px;
    }
`

export const SliderValues = styled.div`
width: 90%;
margin: auto;
margin-bottom: 5px;

`
export const SliderValue = styled.span`
color: #315665;
margin-top: -10px;

`
export const LowValue = styled(SliderValue)`
float: left;

`

export const HighValue = styled(SliderValue)`
float: right;

`

export const ValueButton = styled.div`
    color: #2A9D8F;
    height: 30px;
    width: 32px;
    border-radius: 100%;
    border: 2px solid #2A9D8F;
    background-color: transparent;
    position: relative;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    &:hover{
    color: white;
    background-color: #2A9D8F;
    cursor: pointer;
    }
    span{
    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
    height: 20px;
 display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 2px;
    }
    
        @media only screen and (max-width: 1300px) {
    height: 30px;
    width: 30px;
    font-size: 26px;
}
`

export const DurationValue = styled.span`
display: flex;
align-items: center;
justify-content: center;
font-size: 19px;
font-weight: 600;
font-style: normal;
    @media only screen and (max-width: 1300px) {
    font-size: 18px;
}

@media only screen and (max-width: 580px) {
            font-size: 16px;
        }
`

export const Left = styled.div`
float: left;
`

export const Right = styled.div`
float: right;
`



export const RoundBoxes = styled.div`
display: flex;
margin-top: 30px;
margin-bottom: 30px;
align-items: center;
`
export const RoundBox = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding: 5px;
h5{
font-size: 13px;
text-align: center;
}
`

export const RoundValue = styled.div`
border-radius: 23px;
display: flex;
align-items: center;
width: 90%;
margin: auto;
min-height: 48px;
        @media only screen and (max-width: 500px) {
        width: 95%;
        min-height: 46px;
        }

`



export const MonthlyText = styled(RoundValue)`
border: 1px solid #3C5F6D;
font-size: 24px;
justify-content: center;

span{
font-size: 20px;
margin-left: 10px;
} 
`

export const LoanTime = styled(RoundValue)`
    background-color: rgba(204,213,217,0.23);
    padding: 10px 10px;
justify-content: space-around;
position: relative;
    z-index: 0;
`

export const CollectedCarLoan = styled(RoundValue)`
    background-color: rgba(204,213,217,0.23);
    border: 1px solid #6F8993;
    justify-content: space-around;
    z-index: 0;
    width: 80%;
    font-size: 20px;
    min-height: 43px;
    font-weight: 600;

@media (max-width: 350px) {
    width: 90%;

}


`

export const LoanAmountNotification = styled.div`
border: 1px solid #5FB5AB;
padding: 0px 15px;
width: 90%;
margin: auto;
margin-top: 20px;
display: flex;
align-items: center;
font-size: 12px;
background-color: #f0f9f8;
p{
padding: 10px;
margin: 0;
}
img{
width: 25%;
}
`

export function ThousandSeperator(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}


const NormalLoan: React.FunctionComponent = () => {
    const { values, setFieldValue, handleChange, handleBlur } = useFormikContext<any>();
    //const [amount, setAmount] = useGlobal<string>('test');
    const [step, setStep] = React.useState(5000);
    const [minimum, setMinimum] = React.useState(5000);
    if (initAmount == 150000) {
        initAmount = values.loanAmount;
    }
    const [loanAmount, setLoanAmount] = React.useState(initAmount);
    function changeTime(add: boolean) {
        if (add == true) {
            if (values.durationYears < 12) {
                setFieldValue("durationYears", values.durationYears + 1);
            }
        }
        else {
            if (values.durationYears > 1) {
                setFieldValue("durationYears", values.durationYears - 1);
            }
        }

    }

    function getValue(e) {
        var amount = parseInt(e.replaceAll(".", ""));
        if (amount == NaN) {
            setLoanAmount(10000);
        }

        else if (amount >= 4000 && amount <= 350000) {
            setLoanAmount(amount);
            setFieldValue("loanAmount", amount);
        }
        //else if (amount <= 10000) {
        //    setLoanAmount(10000);
        //    setFieldValue("loanAmount", 10000);
/*        } */
    
        else if(amount > 350000)  {
            setLoanAmount(350000);
            setFieldValue("loanAmount", 350000);
        }


    }
    React.useEffect(() => {
        if (loanAmount < 10000) {
            setStep(1000);
            setMinimum(1000)
        }
        else {
            setStep(5000);
            setMinimum(5000);
        }



    }, [loanAmount])

    React.useEffect(() => {
        if (loanAmount < 10000) {
            setStep(1000);
        }
        else {
            setStep(5000);
        }
    }, [])
    



    return (
        <InnerWrapper style={values.isSamleLaan ? { marginTop: "80px" } : {}}>
            <RoundBoxes className="col-12">
                <div className="col-6">
                    <Header>Lånebeløb:</Header>
                </div>
                <div className="col-6 mb-no-padding">
                    <ValutaInputContainer>
                        <CurrencyInput maskOptions className="valuta-input" type="text" min={10000} max={350000} onChange={(event) => getValue(event.target.value)} value={loanAmount} onBlur={() => setFieldValue("loanAmount", loanAmount)}></CurrencyInput>
                        <Valuta>kr.</Valuta>
                    </ValutaInputContainer>
                </div>
            </RoundBoxes>
            <SliderWithTooltip
                name="loanAmount"
                tipProps={{ defaultVisible: false, visible: false }}
                step={step}
                min={minimum}
                max={350000}
                defaultValue={loanAmount}
                value={loanAmount}
                onChange={(e) => setLoanAmount(e)}
                onAfterChange={(e) => setFieldValue("loanAmount", e)}
                className={loanAmount == 150000 ? "slider-ripple" : ""}

            />
            <SliderValues className="col-lg-15">
                <LowValue>1.000</LowValue>
                <HighValue>350.000</HighValue>
            </SliderValues>
            {values.loanAmount <= 25000 && values.durationYears > 2 || values.loanAmount >= 200000 && values.durationYears < 8 ?
                <LoanAmountNotification className="col-15">
                    <img src="../images/lamp-green.svg" />
                    {values.loanAmount <= 25000 && <p>Overvej en kortere løbetid for denne type lån. Vi anbefaler 1-2 år. Det giver lavere omkostninger og øger mulighederne for godkendelse.</p>}
                    {values.loanAmount >= 200000 && <p>Vi anbefaler en længere løbetid på denne type lån. Det øger muligheder for godkendelse. Du kan tilbagebetale dit lån før tid, hvis du vil.</p>}
                </LoanAmountNotification>
                : ''
            }
            <RoundBoxes className="col-12">
                <RoundBox className="col-6 mb-no-padding">
                    <h5>Lånetid (1-12 år)</h5>
                    <LoanTime >
                        <div className={loanAmount != 150000 && values.durationYears == 10 ? "request-loader value-ripple" : "request-loader"} onClick={() => changeTime(false)}><span>-</span></div>
                        <DurationValue>{values.durationYears} år</DurationValue>
                        <div className={loanAmount != 150000 && values.durationYears == 10 ? "request-loader value-ripple" : "request-loader"} onClick={() => changeTime(true)} ><span>+</span></div>
                    </LoanTime>
                </RoundBox>
                <RoundBox className="col-6">
                    <h5>Månedlig ydelse*</h5>
                    <MonthlyText>
                        <strong>{ThousandSeperator(((values.isSamleLaan ? values.samleLoanAmount : loanAmount) * interest / (1 - Math.pow(1 + interest, -(values.durationYears * 12)))).toFixed(0))}</strong>
                        <span>kr.</span>
                    </MonthlyText>
                </RoundBox>
            </RoundBoxes>
        </InnerWrapper>
    )
}

export default NormalLoan