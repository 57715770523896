import { useFormikContext } from 'formik';
import React from "react";
import styled from "styled-components";
import { Error } from "../src/ApplicationForm";
import CurrencySuffix from "./Components/CurrencySuffix";
import { getStyles, getYesNoStyles, Input } from "./InputFields";
import { YesNoBtn, YesNoContainer, CloseButton } from "./StepFour";
import { Card, InfoText, InputGroup, SmallLabel, SmallLabelFullWidth, SuffixLabel } from "./StepThree";
import { InputExtraInfo, TriangleDown } from "./StepTwo";


export const YesNoLayout = styled.div`
        width: 100%;
     @media only screen and (min-width: 600px) {
        width: 100%;
        display:flex;
        align-items: center;
        jutify-content: flex-end;

        p {
            margin-bottom: 0px;
        }
     }
`
export const Info = styled.i`
    background-color: #C0DFDD;
    padding: 2px 6px;
    border-radius: 65%;
    height: 18px !important;
    font-size: 8px;
    margin-bottom: 4px;
    text-align: center;
    margin-left: 4px;
    margin-top: -18px;
    position: relative;
    vertical-align: middle;

    @media (max-width: 1799px) and (min-width: 1701px) {
        margin-left: 2px;
        padding: 2px 4px;
     }

    img {
        width: 8px;
        padding: 1.6px;
    }

    .smallHeading{
    margin-left: 0px;
    }


`

export const InfoSmaller = styled(Info)`
        padding: 4px 6px;

        @media (max-width: 1799px) and (min-width: 1701px) {
        padding: 2px 4px;
     }

    img {
        padding: 1.6px;
    }

`

export const LabelSpan = styled.div`
        p {
            display: inline;
        }
        

       @media only screen and (max-width: 600px)  {
        width: 100%;
        display: contents;

        p {
            width: 80%;
        }

     }
`

export const WrapperLabel = styled.div`
        width: 100%;
        display: flex;
        justify-content: space-between;
`

export const InfoTextYesNo = styled(InfoText)`
    right: -85px;
    top: -83px;

    @media only screen and (max-width: 600px) {
            right: -9px;
        }

    .info-triangle {
        right: 80px;

    @media only screen and (max-width: 600px)
        {
             right: 7px;
        }
    }
`

const StepEight: React.FunctionComponent = () => {
    const { values, setFieldValue, handleChange, isSubmitting, errors, validateOnChange, touched, handleBlur } = useFormikContext<any>();
    const [showTooltip1, setShowTooltip1] = React.useState(true);
    const [showTooltip, setTooltip] = React.useState(false);

    function changeNumber(e, value) {
        var amount = parseInt(e.replaceAll(".", ""))
        setFieldValue(value, amount);
        if(value == "publicDebtExpense")
        console.log(values.publicDebtExpense)
    }
    var date = new Date();
    let currentYear: number;
    currentYear = date.getFullYear();

    function toogleTooltip(value) {
        setTooltip(value);
    }

    return (
        <div>
            <Card>
                <div className="row">
                    <YesNoLayout>
                        <SmallLabel>
                            <LabelSpan><p>Har du et realkreditlån, andelsboliglån eller boliglån?</p>
                                <InfoSmaller className="info-circle"><img onClick={() => toogleTooltip(false)} src="../images/information-tooltip.svg" alt="info" />
                                    {!showTooltip &&
                                        <InfoTextYesNo className="info-text">Indtast din cirka restgæld samt hvor meget du adfrager per måned<div className="info-triangle" />
                                            <CloseButton onClick={() => toogleTooltip(true)} type="button"></CloseButton>
                                        </InfoTextYesNo>
                                    }

                                </InfoSmaller>
                            </LabelSpan>
                        </SmallLabel>
                        <YesNoContainer>
                            <YesNoBtn type="button" style={getYesNoStyles(errors.otherLoan, touched.otherLoan)} className={values.otherLoan == 1 ? "clicked" : ""} onClick={() => setFieldValue("otherLoan", 1)}>Ja<span /></YesNoBtn>
                            <YesNoBtn type="button" style={getYesNoStyles(errors.otherLoan, touched.otherLoan)} className={values.otherLoan == 0 ? "clicked" : ""} onClick={() => setFieldValue("otherLoan", 0)}>Nej<span /></YesNoBtn>
                        </YesNoContainer>
                    </YesNoLayout>
                    {values.otherLoan == 1 &&
                        <div className="full-width">
                            <SmallLabel>Hvad er din restgæld? </SmallLabel>
                            <InputGroup>
                                <span className="has-float-label">
                                    <CurrencySuffix maskOptions placeholder=" " name="householdOtherLoanRest" id="householdOtherLoanRest" className={values.householdOtherLoanRest > 0 ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.householdOtherLoanRest, touched.householdOtherLoanRest)} onBlur={(event) => changeNumber(event.target.value, "householdOtherLoanRest")} value={values.householdOtherLoanRest > 0 ? values.householdOtherLoanRest : ""} />
                                    <label>Restgæld</label>
                                </span>
                                {errors.householdOtherLoanRest && touched.householdOtherLoanRest &&
                                    <div className="col-12">
                                        <Error>
                                            <div>{errors.householdOtherLoanRest}</div>
                                        </Error>
                                    </div>
                                }
                            </InputGroup>
                            <SmallLabel>Hvad er dit månedlige afdrag?</SmallLabel>
                            <InputGroup>
                                <span className="has-float-label">
                                    <CurrencySuffix maskOptions placeholder=" " name="householdOtherLoanExpense" id="householdOtherLoanExpense" className={values.householdOtherLoanExpense >= 0 ? "validated text-input form-input" : "text-input form-input"} style={getStyles(errors.householdOtherLoanExpense, touched.householdOtherLoanExpense)} onBlur={(event) => changeNumber(event.target.value, "householdOtherLoanExpense")} value={values.householdOtherLoanExpense >= 0 ? values.householdOtherLoanExpense : ""} />
                                    <label>Månedligt afdrag</label>
                                </span>
                                <InputExtraInfo className="brutto-tooltip">
                                    <span>Dette skal ikke indebærer afdrag på <b>realkreditlån</b></span>
                                    <TriangleDown />
                                </InputExtraInfo>
                                {errors.householdOtherLoanExpense && touched.householdOtherLoanExpense &&
                                    <div className="col-12">
                                        <Error>
                                            <div>{errors.householdOtherLoanExpense}</div>
                                        </Error>
                                    </div>
                                }
                            </InputGroup>
                        </div>
                    }
                </div>
            </Card>
            <Card>
                <div className="row">
                    <YesNoLayout>
                        <SmallLabelFullWidth>
                            <LabelSpan>Har du et billån?
                                <InfoSmaller className="info-circle"><img onClick={() => toogleTooltip(false)} src="../images/information-tooltip.svg" alt="info" />
                                    {!showTooltip &&
                                        <InfoTextYesNo className="info-text">Indtast din cirka restgæld samt hvor meget du adfrager per måned<div className="info-triangle" />
                                            <CloseButton onClick={() => toogleTooltip(true)} type="button"></CloseButton>
                                        </InfoTextYesNo>
                                    }
                                </InfoSmaller>
                            </LabelSpan>
                        </SmallLabelFullWidth>
                        <YesNoContainer>
                            <YesNoBtn type="button" style={getYesNoStyles(errors.carLoan, touched.carLoan)} className={values.carLoan == 1 ? "clicked" : ""} onClick={() => setFieldValue("carLoan", 1)}>Ja<span /></YesNoBtn>
                            <YesNoBtn type="button" style={getYesNoStyles(errors.carLoan, touched.carLoan)} className={values.carLoan == 0 ? "clicked" : ""} onClick={() => setFieldValue("carLoan", 0)}>Nej<span /></YesNoBtn>
                        </YesNoContainer>
                    </YesNoLayout>
                    {values.carLoan == 1 &&
                        <div className="full-width">
                            <SmallLabel>Hvad er din restgæld for bil?</SmallLabel>
                            <InputGroup>
                                <span className="has-float-label">
                                    <CurrencySuffix maskOptions placeholder=" " name="carLoanRest" id="carLoanRest" className={values.carLoanRest > 0 ? "validated text-input form-input" : "text-input form-input"} style={getStyles(errors.carLoanRest, touched.carLoanRest)} onBlur={(event) => changeNumber(event.target.value, "carLoanRest")} value={values.carLoanRest > 0 ? values.carLoanRest : ""} />
                                    <label>Restgæld</label>
                                </span>
                                {errors.carLoanRest && touched.carLoanRest &&
                                    <div className="col-12">
                                        <Error>
                                            <div>{errors.carLoanRest}</div>
                                        </Error>
                                    </div>
                                }
                            </InputGroup>
                            <SmallLabel>Hvad er dit månedlige afdrag for bil?</SmallLabel>
                            <InputGroup>
                                <span className="has-float-label">
                                    <CurrencySuffix maskOptions placeholder=" " name="carLoanExpense" id="carLoanExpense" className={values.carLoanExpense >= 0 ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.carLoanExpense, touched.carLoanExpense)} onBlur={(event) => changeNumber(event.target.value, "carLoanExpense")} value={values.carLoanExpense >= 0 ? values.carLoanExpense : ""} />
                                    <label>Månedligt afdrag</label>
                                </span>
                                {errors.carLoanExpense && touched.carLoanExpense &&
                                    <div className="col-12">
                                        <Error>
                                            <div>{errors.carLoanExpense}</div>
                                        </Error>
                                    </div>
                                }
                            </InputGroup>
                        </div>
                    }
                </div>
            </Card>
            <Card>
                <div className="row">
                    <YesNoLayout>
                        <SmallLabel>
                            <LabelSpan>Har du et studielån?
                                <InfoSmaller className="info-circle"><img onClick={() => toogleTooltip(false)} src="../images/information-tooltip.svg" alt="info" />
                                    {!showTooltip &&
                                        <InfoTextYesNo className="info-text">Indtast din cirka restgæld samt hvor meget du adfrager per måned<div className="info-triangle" />
                                            <CloseButton onClick={() => toogleTooltip(true)} type="button"></CloseButton>
                                        </InfoTextYesNo>
                                    }
                                </InfoSmaller>
                            </LabelSpan>
                        </SmallLabel>
                        <YesNoContainer>
                            <YesNoBtn type="button" style={getYesNoStyles(errors.studyLoan, touched.studyLoan)} className={values.studyLoan == 1 ? "clicked" : ""} onClick={() => setFieldValue("studyLoan", 1)}>Ja<span /></YesNoBtn>
                            <YesNoBtn type="button" style={getYesNoStyles(errors.studyLoan, touched.studyLoan)} className={values.studyLoan == 0 ? "clicked" : ""} onClick={() => setFieldValue("studyLoan", 0)}>Nej<span /></YesNoBtn>
                        </YesNoContainer>
                    </YesNoLayout>
                    {values.studyLoan == 1 &&
                        <div className="full-width">
                            <SmallLabel>Hvad er din restgæld for studie?</SmallLabel>
                            <InputGroup>
                                <span className="has-float-label">
                                    <CurrencySuffix maskOptions placeholder=" " name="studyLoanRest" id="studyLoanRest" className={values.studyLoanRest > 0 ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.studyLoanRest, touched.studyLoanRest)} onBlur={(event) => changeNumber(event.target.value, "studyLoanRest")} value={values.studyLoanRest > 0 ? values.studyLoanRest : ""} />
                                    <label>Restgæld</label>
                                </span>
                                {errors.studyLoanRest && touched.studyLoanRest &&
                                    <div className="col-12">
                                        <Error>
                                            <div>{errors.studyLoanRest}</div>
                                        </Error>
                                    </div>
                                }
                            </InputGroup>
                            <SmallLabel>Hvad er dit månedlige afdrag for studie?</SmallLabel>
                            <InputGroup>
                                <span className="has-float-label">
                                    <CurrencySuffix maskOptions placeholder=" " name="studyLoanExpense" id="studyLoanExpense" className={values.studyLoanExpense >= 0 ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.studyLoanExpense, touched.studyLoanExpense)} onBlur={(event) => changeNumber(event.target.value, "studyLoanExpense")} value={values.studyLoanExpense >= 0 ? values.studyLoanExpense : ""} />
                                    <label>Månedligt afdrag</label>
                                </span>
                                {errors.studyLoanExpense && touched.studyLoanExpense &&
                                    <div className="col-12">
                                        <Error>
                                            <div>{errors.studyLoanExpense}</div>
                                        </Error>
                                    </div>
                                }
                            </InputGroup>
                        </div>
                    }
                </div>
            </Card>
            <Card>
                <div className="row">
                    <YesNoLayout>
                        <SmallLabel>
                            <LabelSpan>Har du et forbrugslån?
                                <InfoSmaller className="info-circle"><img onClick={() => toogleTooltip(false)} src="../images/information-tooltip.svg" alt="info" />
                                    {!showTooltip &&
                                        <InfoTextYesNo className="info-text">Indtast din cirka restgæld samt hvor meget du adfrager per måned<div className="info-triangle" />
                                            <CloseButton onClick={() => toogleTooltip(true)} type="button"></CloseButton>
                                        </InfoTextYesNo>
                                    }

                                </InfoSmaller>
                            </LabelSpan>
                        </SmallLabel>
                        <YesNoContainer>
                            <YesNoBtn type="button" style={getYesNoStyles(errors.consumerLoan, touched.consumerLoan)} className={values.consumerLoan == 1 ? "clicked" : ""} onClick={() => setFieldValue("consumerLoan", 1)}>Ja<span /></YesNoBtn>
                            <YesNoBtn type="button" style={getYesNoStyles(errors.consumerLoan, touched.consumerLoan)} className={values.consumerLoan == 0 ? "clicked" : ""} onClick={() => setFieldValue("consumerLoan", 0)}>Nej<span /></YesNoBtn>
                        </YesNoContainer>
                    </YesNoLayout>
                    {values.consumerLoan == 1 &&
                        <div className="full-width">
                            <SmallLabel>Hvad er din restgæld for forbrug?</SmallLabel>
                            <InputGroup>
                                <span className="has-float-label">
                                    <CurrencySuffix maskOptions placeholder=" " name="consumerLoanRest" id="consumerLoanRest" className={values.consumerLoanRest > 0 ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.consumerLoanRest, touched.consumerLoanRest)} onBlur={(event) => changeNumber(event.target.value, "consumerLoanRest")} value={values.consumerLoanRest > 0 ? values.consumerLoanRest : ""} />
                                    <label>Restgæld</label>
                                </span>
                                {errors.consumerLoanRest && touched.consumerLoanRest &&
                                    <div className="col-12">
                                        <Error>
                                            <div>{errors.consumerLoanRest}</div>
                                        </Error>
                                    </div>
                                }
                            </InputGroup>
                            <SmallLabel>Hvad er dit månedlige afrag for forbrug?</SmallLabel>
                            <InputGroup>
                                <span className="has-float-label">
                                    <CurrencySuffix maskOptions placeholder=" " name="consumerLoanExpense" id="consumerLoanExpense" className={values.consumerLoanExpense >= 0 ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.consumerLoanExpense, touched.consumerLoanExpense)} onBlur={(event) => changeNumber(event.target.value, "consumerLoanExpense")} value={values.consumerLoanExpense >= 0 ? values.consumerLoanExpense : ""} />
                                    <label>Månedligt afdrag</label>
                                </span>
                                {errors.consumerLoanExpense && touched.consumerLoanExpense &&
                                    <div className="col-12">
                                        <Error>
                                            <div>{errors.consumerLoanExpense}</div>
                                        </Error>
                                    </div>
                                }
                            </InputGroup>
                        </div>
                    }
                </div>
            </Card>
            <Card>
                <div className="row">
                    <YesNoLayout>

                        <SmallLabel>
                            <LabelSpan>Har du gæld til det offentlige?

                                <InfoSmaller className="info-circle"><img onClick={() => toogleTooltip(false)} src="../images/information-tooltip.svg" alt="info" />
                                    {!showTooltip &&
                                        <InfoTextYesNo className="info-text">Gæld til det offentlige kan være både til gældsstyrelsen, udbetaling danmark, osv.<div className="info-triangle" />
                                            <CloseButton onClick={() => toogleTooltip(true)} type="button"></CloseButton>
                                        </InfoTextYesNo>
                                    }
                                </InfoSmaller>
                            </LabelSpan>
                        </SmallLabel>


                        <YesNoContainer>
                            <YesNoBtn type="button" style={getYesNoStyles(errors.publicDebt, touched.publicDebt)} className={values.publicDebt == 1 ? "clicked" : ""} onClick={() => setFieldValue("publicDebt", 1)}>Ja<span /></YesNoBtn>
                            <YesNoBtn type="button" style={getYesNoStyles(errors.publicDebt, touched.publicDebt)} className={values.publicDebt == 0 ? "clicked" : ""} onClick={() => setFieldValue("publicDebt", 0)}>Nej<span /></YesNoBtn>
                        </YesNoContainer>
                    </YesNoLayout>
                    {values.publicDebt == 1 &&
                        <div className="full-width">
                        <SmallLabel>Hvor meget offentlig gæld har du?</SmallLabel>
                            <InputGroup>
                                <span className="has-float-label">
                                <CurrencySuffix maskOptions placeholder=" " name="publicDebtRest" id="publicDebtRest" className={values.publicDebtRest > 0 ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.publicDebtRest, touched.publicDebtRest)} onBlur={(event) => changeNumber(event.target.value, "publicDebtRest")} value={values.publicDebtRest > 0 ? values.publicDebtRest : ""} />
                                    <label>Restgæld</label>
                                </span>
                            {errors.publicDebtRest && touched.publicDebtRest &&
                                    <div className="col-12">
                                        <Error>
                                    <div>{errors.publicDebtRest}</div>
                                        </Error>
                                    </div>
                                }
                            </InputGroup>
                        <SmallLabel>Hvad er dit månedlige afdrag for offentlig gæld?</SmallLabel>
                            <InputGroup>
                                <span className="has-float-label">
                                <CurrencySuffix maskOptions placeholder=" " name="publicDebtExpense" id="publicDebtExpense" className={values.publicDebtExpense >= 0 ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.publicDebtExpense, touched.publicDebtExpense)} onBlur={(event) => changeNumber(event.target.value, "publicDebtExpense")} value={values.publicDebtExpense >= 0 ? values.publicDebtExpense : ""} />
                                    <label>Månedligt afdrag</label>
                                </span>
                            {errors.publicDebtExpense && touched.publicDebtExpense &&
                                    <div className="col-12">
                                        <Error>
                                    <div>{errors.publicDebtExpense}</div>
                                        </Error>
                                    </div>
                                }
                            </InputGroup>
                        </div>
                    }
                </div>
            </Card>
            <Card>
                <div className="row">
                    <YesNoLayout>
                        <SmallLabel>
                            <LabelSpan>Har du øvrig gæld?

                                <InfoSmaller className="info-circle"><img onClick={() => toogleTooltip(false)} src="../images/information-tooltip.svg" alt="info" />
                                    {!showTooltip &&
                                        <InfoTextYesNo className="info-text">Hvis du har andre former for gæld end dem du har indtastet ovenfor skal du indtaste det her.<div className="info-triangle" />
                                            <CloseButton onClick={() => toogleTooltip(true)} type="button"></CloseButton>
                                        </InfoTextYesNo>
                                    }
                                </InfoSmaller>
                            </LabelSpan>
                        </SmallLabel>
                        <YesNoContainer>
                            <YesNoBtn type="button" style={getYesNoStyles(errors.hasOtherDebt, touched.hasOtherDebt)} className={values.hasOtherDebt == 1 ? "clicked" : ""} onClick={() => setFieldValue("hasOtherDebt", 1)}>Ja<span /></YesNoBtn>
                            <YesNoBtn type="button" style={getYesNoStyles(errors.hasOtherDebt, touched.hasOtherDebt)} className={values.hasOtherDebt == 0 ? "clicked" : ""} onClick={() => setFieldValue("hasOtherDebt", 0)}>Nej<span /></YesNoBtn>
                        </YesNoContainer>
                    </YesNoLayout>
                    {values.hasOtherDebt == 1 &&
                        <div className="full-width">
                            <SmallLabel>Hvor meget øvrig gæld har du? </SmallLabel>
                            <InputGroup>
                                <span className="has-float-label">
                                    <CurrencySuffix maskOptions placeholder=" " name="otherDebt" id="otherDebt" className={values.otherDebt > 0 ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.otherDebt, touched.otherDebt)} onBlur={(event) => changeNumber(event.target.value, "otherDebt")} value={values.otherDebt > 0 ? values.otherDebt : ""} />
                                    <label>Øvrig gæld</label>
                                </span>
                                {errors.otherDebt && touched.otherDebt &&
                                    <div className="col-12">
                                        <Error>
                                            <div>{errors.otherDebt}</div>
                                        </Error>
                                    </div>
                                }
                            </InputGroup>
                            <SmallLabel>Hvad er dit månedlige afdrag for øvrig gæld?</SmallLabel>
                            <InputGroup>
                                <span className="has-float-label">
                                    <CurrencySuffix maskOptions placeholder=" " name="otherDebtExpense" id="otherDebtExpense" className={values.otherDebtExpense >= 0 ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.otherDebtExpense, touched.otherDebtExpense)} onBlur={(event) => changeNumber(event.target.value, "otherDebtExpense")} value={values.otherDebtExpense >= 0 ? values.otherDebtExpense : ""} />
                                    <label>Indtast afdrag</label>
                                </span>
                                {errors.otherDebtExpense && touched.otherDebtExpense &&
                                    <div className="col-12">
                                        <Error>
                                            <div>{errors.otherDebtExpense}</div>
                                        </Error>
                                    </div>
                                }
                            </InputGroup>
                        </div>
                    }
                </div>
            </Card>
        </div>
    )
}

export default StepEight;