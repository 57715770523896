import { useFormikContext } from 'formik';
import React from "react";
import SelectSearch from 'react-select-search';
import styled from "styled-components";
import { SamleLaan } from "../Models/Samlelaan";
import { Error } from "../src/ApplicationForm";
import { LoanType } from "../src/Enums";
import { options } from "./Components/LoanProviders";
import CurrencyInput from "./Components/CurrencyInput";
import CurrencySuffix from "./Components/CurrencySuffix";
import { InnerCard, InnerWrapper } from "./FormContainer";
import { FormGroup } from "./InputFields";
import { DurationValue, Header, LoanTime, MonthlyText, RoundBox, RoundBoxes, ThousandSeperator, Valuta, ValutaInputContainer } from "./NormalLoan";


export const SuffixLabel = styled.label`
position: absolute;
    right: 5%;
    top: 16px;
    font-size: 14px;
    opacity: 0.5;
        @media only screen and (max-width: 500px) {
        font-size: 13px;
        }
}
`


export const Headline2 = styled("h2")({
    color: "#0F3166",
    fontSize: "18px",
    textAlign: "left",
    fontWeight: "bold",
    marginTop: "40px"
})

const SamleLaanContainer = styled("div")({
    margin: "auto",
    marginTop: "20px",
    marginBottom: "20px",
    width: "90%",
})

const Headline3 = styled("h3")({
    color: "#0F3166",
    fontSize: "18px",
    textAlign: "left",
    float: "left",
    width: "90%",
    margin: "auto",
    marginBottom: "15px"
})

const RemoveButton = styled.button`
    float: right;
    background: inherit;
    border:none;
    color:#315665;
    position: absolute;
    font-size: 25px;
    top: 13px;
    right: 27px;

    &:hover img {
        filter: invert(48%) sepia(57%) saturate(509%) hue-rotate(123deg) brightness(97%) contrast(86%);
    }
    :focus{
    outline: none;
    }
`


const Button = styled.button`
width: 40%;
margin: auto;
border-radius: 24px;
color: #2A9D8F;
background-color: rgba(204,213,217,0.23);
outline: none;
border: none;
display: flex;
justify-content: space-around;
align-items: center;

padding: 10px;
&:focus{
    outline: none;
border: none;
}

div{
width: 22px;
height: 20px;
background-color: #2A9D8F;
border-radius: 70%;
color: white;
display: flex;
align-items: center;
justify-content: center;
padding-top: 1px;

}

&:hover{
cursor: pointer;
}

        @media only screen and (max-width: 500px) {
        width: 50%;
        }
`

const LoanInfoContainer = styled("div")({
    paddingTop: "20px",
    width: "60%",
    color: "#0F3166",
    margin: "auto"
})

const LoanInfoRow = styled("div")({
    borderBottom: "1px solid #0F3166",
    padding: "25px 0 5px"
})

export const ButtonContainer = styled.div`
width: 22%;
float: left;
@media only screen and (max-width: 1300px) {
    width: 23%;

    }

@media only screen and (max-width: 500px) {
    width: 45%;
       &:nth-child(1), &:nth-child(2){
        margin-bottom: 15px;
        }
    }
`

export const ButtonContainerBig = styled(ButtonContainer)({
    width: "50%",

})



export const LoanTypeButton = styled.button`
    border-radius: 6px;
    color: #4C4C4C;
    background: inherit;
    padding: 10px 0;
    font-size: 14px;
    width: 100%;
    width: -moz-available;         
    width: -webkit-fill-available; 
    width: fill-available;
    outline:none !important;
    border: 2px solid rgba(76, 76, 76, 0.6);
    text-align: center;
    opacity: 0.7;
    position: relative;
    overflow: hidden;

    :hover {
        color: #2A9D8F;
        border-color: #2A9D8F;
        cursor: pointer;
    }
`;

export const SamleButtonContainer = styled.div`
display: flex;
justify-content: space-between;
width: 90%;
margin: auto;
@media only screen and (max-width: 1300px) {
    flex-wrap: wrap;
    width: 100%;

    }
`

export const AddSamleLoan = styled.div`
width: 40px;
height: 40px;
display: flex;
justify-content: center;
align-items: center;
color: white;
background-color: #2A9D8F;
border-radius: 100%;
font-size: 25px;
margin-left: 10px;
    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */

`

export const SamleCategory = styled.label`
text-align: left;
font: normal normal medium 18px/25px Poppins;
letter-spacing: 0.18px;
color: #17262C;
opacity: 1;
width: 100%;
padding-left: 5px;
`

export const Select = styled.select`
        width: 100%;
        height: 60px;
        border: 2px solid #E3E3E3;
        border-radius: 6px;
        display: block;
        background-color: white !important;
        padding-left: 15px;
        flex:1;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url(../images/drop-grey.svg) 96% / 6% no-repeat white;
        &:hover{
        cursor: pointer;
        }
        
        :focus {
        outline: 0 !important;
        border: 2px solid rgba(42, 157, 143, 0.3) !important;

        }
        @media only screen and (max-width: 1200px) {
        font-size: 13px;
    }
`

export const TriangleDown = styled.div`
    width: 0; 
    height: 0; 
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    bottom: -11px;
    right: 116px;
    position: absolute;
    border-top: 20px solid #315665;

`
export const InputHelp = styled.div`
    font-size: 11px;
    line-height: 12px;
    color: white;
    background: #315665;
    border-radius: 3px;
    position: absolute;
    min-width: 250px;
    bottom: 100%;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 18px;
    margin-bottom: 10px;
    margin-right: -15px;
    display: none;
@media (max-width: 1800px) {
        margin-right: -15%;
    }
`

export const SamleLoanContainer = styled.div`

`

const LoanAmount = styled.h4`
font-weight: bold;
text-align: center;
`

const HiddenTitle = styled.label`
    font-size: 12px;
    position: absolute;
    left: 19px;
    top: 3px;
    z-index: 1;
        @media only screen and (max-width: 500px) {
    font-size: 11px;
    }
`

const interest = 0.07 / 12;


const SamleLoan: React.FunctionComponent = () => {
    const { values, setFieldValue, handleChange, errors, touched, handleBlur } = useFormikContext<any>();
    function addSamleLaan() {
        values.samleLaan.push(new SamleLaan)
        setFieldValue("samleLaan", values.samleLaan); //bliver brugt til at opdatere DOM
    }

    function removeSamleLaan(i: number) {
        values.samleLoanAmount -= values.samleLaan[i].restGaeld;
        values.samleLaan.splice(i, 1)
        setFieldValue("samleLaan", values.samleLaan);//bliver brugt til at opdatere DOM
    }

    function selectLoanType(i: number, type: LoanType) {
        values.samleLaan[i].type = type
        let buttons = document.getElementById("samle" + i).getElementsByClassName("clicked");
        if (buttons.length > 0)
            buttons[0].classList.remove("clicked")
        let button = document.getElementById(type.toString() + i)
        button.classList.add("clicked")
    }

    function changeTime(add: boolean) {
        if (add == true) {
            if (values.durationYears < 12) {
                setFieldValue("durationYears", values.durationYears + 1);
            }
        }
        else {
            if (values.durationYears > 1) {
                setFieldValue("durationYears", values.durationYears - 1);
            }
        }

    }

    function getObjectStyles(value, touched) {
        if (value == null && touched) {
            return {
                border: '2px solid #E76F51'
            }
        }
    }

    function change(i, e) {
        setFieldValue("samleLaan[" + i + "].udbyder", e)
    }

    function changeAmount(i, e) {
        var specificAmount = parseInt(e.target.value.replace(".", ""))

        if (specificAmount > 0) {
            setFieldValue("samleLaan[" + i + "].restGaeld", specificAmount)

            values.samleLaan[i].restGaeld = specificAmount;
        }

        var amount = 0;
        for (let i = 0; i < values.samleLaan.length; i++) {
            amount = amount + values.samleLaan[i].restGaeld;
        }
        setFieldValue("samleLoanAmount", amount);
        setFieldValue("loanAmount", values.samleLoanAmount);
    }

    function changeYdelse(i, e) {
        var specificAmount = parseInt(e.target.value.replace(".", ""))

        if (specificAmount > 0) {
            setFieldValue("samleLaan[" + i + "].maanedligYdelse", specificAmount)

            values.samleLaan[i].maanedligYdelse = specificAmount;
        }
    }

    const drawSamleloans = () => {
        let samleLoansHtml = [];
        for (let i = 0; i < values.samleLaan.length; i++) {
            let samleLaan = values.samleLaan[i];
            samleLoansHtml.push(
                <div className="row">
                    <div className="col-12" style={values.step >= 3 ? { marginTop: "64px" } : {}}>
                        <SamleLaanContainer id={"samle" + i} className="row" key={"samle" + samleLaan.id}>
                            <Headline3>Eksisterende lån {i + 1}</Headline3>{values.samleLaan.length != 1 && <RemoveButton type="button" onClick={() => removeSamleLaan(i)}><img className="fa" src="images/fa-trash.svg" /></RemoveButton>}
                            <SamleButtonContainer>
                                <ButtonContainer><LoanTypeButton type="button" id={LoanType.Car.toString() + i} onClick={() => selectLoanType(i, LoanType.Car)} className={values.samleLaan[i].type == LoanType.Car ? "clicked" : ""} >Billån<span></span></LoanTypeButton></ButtonContainer>
                                <ButtonContainer><LoanTypeButton type="button" id={LoanType.Housing.toString() + i} onClick={() => selectLoanType(i, LoanType.Housing)} className={values.samleLaan[i].type == LoanType.Housing ? "clicked" : ""}>Boliglån<span></span></LoanTypeButton></ButtonContainer>
                                <ButtonContainer><LoanTypeButton type="button" id={LoanType.SU.toString() + i} onClick={() => selectLoanType(i, LoanType.SU)} className={values.samleLaan[i].type == LoanType.SU ? "clicked" : ""}>SU-lån<span></span></LoanTypeButton></ButtonContainer>
                                <ButtonContainer><LoanTypeButton type="button" id={LoanType.Other.toString() + i} onClick={() => selectLoanType(i, LoanType.Other)} className={values.samleLaan[i].type == LoanType.Other ? "clicked" : ""}>Andet<span></span></LoanTypeButton></ButtonContainer>
                            </SamleButtonContainer>
                            <FormGroup>
                                <div>
                                    {values.samleLaan[i].udbyder != null &&
                                        <HiddenTitle style={{ zIndex: 2 }}>Låneudbyder</HiddenTitle>
                                    }
                                    <SelectSearch options={options} search placeholder="Vælg låneudbyder" value={values.samleLaan[i].udbyder} onChange={event => change(i, event)} className={errors.samleLaan && errors.samleLaan[i] && errors.samleLaan[i].udbyder && touched.samleLaan && touched.samleLaan[i] && touched.samleLaan[i].udbyder ? "select-search select-error" : (values.samleLaan[i].udbyder != null) ? "select-search select-validated" : "select-search"} />
                                </div>
                                {errors.samleLaan && errors.samleLaan[i] && errors.samleLaan[i].udbyder && touched.samleLaan && touched.samleLaan[i] && touched.samleLaan[i].udbyder &&
                                    <Error className="col-12" style={{ textAlign: "start" }}>
                                        {errors.samleLaan[i].udbyder}</Error>
                                }
                            </FormGroup>
                            <FormGroup>
                                <div>
                                    <span className="has-float-label">
                                        <CurrencySuffix maskOptions className={values.samleLaan[i].restGaeld != null && values.samleLaan[i].restGaeld != 0 ? "validated text-input form-input" : "form-input text-input"} name={"samleLaan[" + i + "].restGaeld"} id={"samleLaan[" + i + "].restGaeld"} style={errors.samleLaan && errors.samleLaan[i] && errors.samleLaan[i].restGaeld && touched.samleLaan && touched.samleLaan[i] && touched.samleLaan[i].restGaeld ? { border: '2px solid #E76F51' } : values.samleLaan[i].restGaeld > 0 ? { paddingTop: '8px', paddingBottom: '4px' } : {}} onChange={event => changeAmount(i, event)} onBlur={event => changeAmount(i, event)} placeholder=" " value={values.samleLaan[i].restGaeld > 0 ? values.samleLaan[i].restGaeld : ""} />
                                        <label>Restgæld</label>
                                    </span>
                                </div>
                                {errors.samleLaan && errors.samleLaan[i] && errors.samleLaan[i].restGaeld && touched.samleLaan && touched.samleLaan[i] && touched.samleLaan[i].restGaeld &&
                                    <Error className="col-12" style={{ textAlign: "start" }}>
                                        {errors.samleLaan[i].restGaeld}
                                    </Error>
                                }
                            </FormGroup>
                            <FormGroup>
                                <div>
                                    <span className="has-float-label">
                                        <CurrencySuffix maskOptions className={values.samleLaan[i].maanedligYdelse != null && values.samleLaan[i].maanedligYdelse != 0 ? "validated text-input form-input" : "form-input text-input"} name={"samleLaan[" + i + "].maanedligYdelse"} id={"samleLaan[" + i + "].maanedligYdelse"} style={errors.samleLaan && errors.samleLaan[i] && errors.samleLaan[i].maanedligYdelse && touched.samleLaan && touched.samleLaan[i] && touched.samleLaan[i].maanedligYdelse ? { border: '2px solid #E76F51' } : values.samleLaan[i].maanedligYdelse > 0 ? { paddingTop: '8px', paddingBottom: '4px' } : {}} onChange={event => changeYdelse(i, event)} onBlur={event => changeYdelse(i, event)} placeholder=" " value={values.samleLaan[i].maanedligYdelse > 0 ? values.samleLaan[i].maanedligYdelse : ""} />
                                        <label>Månedligt afdrag</label>
                                    </span>
                                </div>
                                {errors.samleLaan && errors.samleLaan[i] && errors.samleLaan[i].maanedligYdelse && touched.samleLaan && touched.samleLaan[i] && touched.samleLaan[i].maanedligYdelse &&
                                    <Error className="col-12" style={{ textAlign: "start" }}>
                                        {errors.samleLaan[i].maanedligYdelse}
                                    </Error>
                                }
                            </FormGroup>
                        </SamleLaanContainer>
                    </div>
                </div>
            );
        };
        return samleLoansHtml;
    }

    return (
        <InnerWrapper>
            <InnerCard style={{ marginLeft: "-1px", }}>
                <SamleLoanContainer>
                    {drawSamleloans()}
                </SamleLoanContainer>
                <Button onClick={addSamleLaan} type="button" >Tilføj et lån<div>+</div></Button>
            </InnerCard>
            <InnerCard style={{ borderRadius: "16px", marginTop: "25px" }}>

                <RoundBoxes className="col-12">
                    <div className="col-6">
                        <Header>Lånebeløb:</Header>
                    </div>
                    <div className="col-6">
                        <ValutaInputContainer>
                            <CurrencyInput className="valuta-input" maskOptions disabled name="loanAmount" min={5000} max={500000} value={values.samleLoanAmount} onChange={handleChange} onBlur={handleBlur} />
                            <Valuta>kr</Valuta>
                        </ValutaInputContainer>
                    </div>
                </RoundBoxes>
                <RoundBoxes style={{ marginBottom: "0px" }} className="col-12">
                    <RoundBox className="col-6">
                        <h5>Lånetid (1-12 år)</h5>
                        <LoanTime className="row">
                            <div className={values.loanAmount != 150000 ? "request-loader value-ripple" : "request-loader"} onClick={() => changeTime(false)}><span>-</span></div>
                            <DurationValue>{values.durationYears} år</DurationValue>
                            <div className={values.loanAmount != 150000 ? "request-loader value-ripple" : "request-loader"} onClick={() => changeTime(true)} ><span>+</span></div>
                        </LoanTime>
                    </RoundBox>
                    <RoundBox className="col-6">
                        <h5>Månedlig ydelse*</h5>
                        <MonthlyText>
                            <strong id="step2">{ThousandSeperator(((values.isSamleLaan ? values.samleLoanAmount : values.loanAmount) * interest / (1 - Math.pow(1 + interest, -(values.durationYears * 12)))).toFixed(0))}</strong>
                            <span>kr</span>
                        </MonthlyText>
                    </RoundBox>
                </RoundBoxes>
            </InnerCard>
        </InnerWrapper>
    )
}

export default SamleLoan