import { useFormikContext } from 'formik';
import React from "react";
import { useCookies } from "react-cookie";
import styled from "styled-components";
import { apiConfiguration } from "../api-configuration";
import { ApplicationApi, LoanBrokerApi } from "../lib-api";
import { CivilStatus, LoanReason } from "../src/Enums";
import ChangeValue from "./ChangeAmount";
import ProgressBar from "./Components/ProgressBar";
import InputFields from "./InputFields";
import Loader from "./Loader";
import NormalLoan from "./NormalLoan";
import SamleLoan from "./SamleLoan";
import TopPart from "./TopPart";
import { trackEvent } from "../util/gtm";
import { FormValues } from '../src/ApplicationForm';

const StepTwo = React.lazy(() => import('./StepTwo'));
const StepThree = React.lazy(() => import('./StepThree'));
const StepFour = React.lazy(() => import('./StepFour'));
const StepFive = React.lazy(() => import('./StepFive'));
const StepSix = React.lazy(() => import('./StepSix'));
const StepSeven = React.lazy(() => import('./StepSeven'));
const StepEight = React.lazy(() => import('./StepEight'));
const StepNine = React.lazy(() => import('./StepNine'));
const Finished = React.lazy(() => import('./Finished'));
const CarLoan = React.lazy(() => import('./CarLoan'));
const interest = 0.07 / 12;





export function ThousandSeperator(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const Headline = styled.h2`
    font-size: 32px;
    font-weight: bold;
    color: #1D275F;

    @media (max-width: 768px) {
        font-size: 24px;
    }
`;

const Button = styled(styled("button")({
    fontSize: "22px",
    border: "none",
    padding: "12px 36px",
    width: "40%",
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "transparent",

    ":focus": {
        outline: "none"
    }
}))`
    img {
    width: 15px;
    height: auto;
}
`
const Previous = styled(Button)`
    color: #007AB9;
    background: inherit;
    border-radius: 4px;

    img {
        transform: rotate(180deg)
    }

    @media (max-width: 768px) {
        border: 1px solid #007AB9;
        margin-bottom:10px;
    }
`;

export const Next = styled(Button)`
color: white;
background-color: #2A9D8F;
border-radius: 33px;
transition: 0.2s linear;

img{
transition: 0.2s linear;
}

&:disabled{
opacity: 0.5;
}


&:hover{
transition: 0.2s linear;
background-color: #279285;
img{
transition: 0.2s linear;
margin-right: -5px;
margin-left: 5px;
}
}

@media (max-width: 768px) {
        width: 42%;
        padding: 12px 26px;
        font-sizE: 18px;
    }
@media (max-width: 340px) {
        width: 46%;
        padding: 12px 26px;
        font-sizE: 18px;
    }
`

export const Continue = styled(Next)`
width: 75%;
        padding: 12px 30px;
font-size: 20px;

@media (max-width: 768px) {
        width: 80%;
        padding: 12px 21px;
        font-size: 16px;
}

`

export const Finish = styled(Continue)`
width: 60%;
@media (max-width: 768px) {
        width: 70%;
}
`

export const NextSmall = styled(Next)`
font-size: 19px;
    padding: 18px 20px;
    width: 53%;
margin-top: 60px;
margin-bottom: 60px;
`

export const NextPopUp = styled(Next)`
margin-top: 20px;
width: 60%;
font-size: 18px;
img{
width: 14% !important;
}
@media (max-width: 768px) {
        width: 55% !important;
    }
@media (max-width: 500px) {
        width: 85% !important;
    }
`


export const ToggleSwitchHolder = styled.div`
    position:absolute;
`;

export const ToggleSwitch = styled.div`

input{
	height: 0;
	width: 0;
	visibility: hidden;
}

label {
	cursor: pointer;
	text-indent: -9999px;
	width: 42px;
	height: 26px;
	background: #E0E7FF;
	display: block;
	border-radius: 25px;
	position: relative;
}

label:after {
	content: '';
	position: absolute;
	top: 4px;
	left: 5px;
	width: 18px;
	height: 18px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

input:checked + label {
	background: #3FABF3;
}

input:checked + label:after {
	left: calc(100% - 5px);
	transform: translateX(-100%);
}

label:active:after {
	width: 18px;
}
	display: flex;
	justify-content: center;
	align-items: center;

`;



export const Image = styled("img")({
    width: "120px",
    height: "auto"
})

export const InnerWrapper = styled("div")({
    margin: "auto",
    width: "100%",
})
export const SelectedLoanType = styled("div")({
    color: "#0F3166",
    fontWeight: "bold"
})
export const UnSelectedLoanType = styled("div")({
    color: "#0F3166",
    opacity: "0.5",
    fontWeight: "bold"
})
export const Toggle = styled("div")({
    margin: "auto",
    width: "80%",
})
export const Menu = styled("div")({

    borderBottom: "#E0E7FF solid 1px",
    padding: "23px"
})
const FormStyle = styled.form`
    padding-top: 30px;
    height: 500px;

    @media (max-width: 768px) {
            height: auto;
            padding-top: 0px;
    }
`

export const MontlhyPaymentContainer = styled.div`
    margin: 15px auto;
    width: 100%;
    border: 4px solid #315666;
    border-radius: 22px;
    background-color: white;
    text-align: center;
    padding: 14px 25px;
    color: rgba(76, 100, 124, 0.5);
    font-size:15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 1300px) {
        padding: 22px 15px;
        strong{
        font-size: 28px;    
    }
    }

    
`

export const LoanExample = styled.div`
opacity: 0.9;
width: 100%;
margin: auto;
font-size: 11px;
padding: 11px 0;
color: #848484;
text-align: center;
margin-top: 18px;
height: 45px;
`

const FormBottom = styled.div`
    width: 90%;
    margin: auto;

    @media (max-width: 1800px) {
        width: 100%;
    }
    
`

const FormCon = styled.div`
    padding: 13px 13px;
    border-radius: 25px;
    transition: 0.1s ease-in;
    position: relative;
    z-index: 2;
    color: #315665;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color: #F4F6FA !important;
    box-shadow: 0px 3px 6px #00000029;
@media only screen and (max-width: 500px) {
    min-height: auto;
    padding-bottom: 20px !important;
    }

`

export const MenuContainer = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
margin: auto;
position: relative;
z-index: 4;

`

export const MenuPoint = styled.div`
    width: 34%;
    text-align: center;
    color: #BABEC9;
    float: left;
    padding: 15px 0px 10px;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
    background-color: #395A67;
    opacity: 0.97;
    border-radius: 15px 15px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover{
        transition: all 0.2s;
        cursor: pointer;
        color: white;
        div {
        border-color: white;
        }
        }

    @media only screen and (max-width: 500px) {
        font-size: 16px;
        font-weight: 400;
        }
    
`

export const MenuPointIcon = styled.div`
width: 15px;
height: 15px;
border-radius: 70%;
border: 1px solid #BABEC9;
margin-left: 8px;
display: flex;
justify-content: center;
align-items: center;
`

const FormSelf = styled.form`
margin-top: -2px;
`

export const Hello = styled.div`
text-align: left;
color: #315665;
margin-bottom: 20px;
div{
display: flex;
align-items: center;
}
img{
width: 20px;
margin-right: 6px;
}
h4{
margin: 0;
font-size: 15px;
}
h2{
font-style: normal;
font-weight: 600;
font-size: 25px;

} 
`

export const Back = styled(Button)`
    img{
    transition: 0.2s linear;
    margin-right: 10px;
    }
    font-size: 18px;
    padding: 0;
    justify-content: left;
    align-items: center;


    &:hover{
    transition: 0.2s linear;
    img{
    transition: 0.2s linear;
    margin-right: 15px;
    margin-left: -5px;
    }
`

export const NavContainer = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
margin-bottom: 20px;
`

export const ThirdHeader = styled.h3`
color: #315665;
font-weight:600;
font-style: normal;
margin: 0;
font-size: 19px;
`

export const HR = styled.hr`
width: 80%;
margin: auto;
border: 1px solid #3C5F6D;
color: #3C5F6D;
opacity: 0.6;
margin-top: 10px;
`

export const FormTop = styled.div`
top: 0;
width: 100%;
left: 0;
`
export const InnerCard = styled.div`
width: 100%;
margin: auto;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #87989F;
border-top: 0px;
border-radius: 0 0 16px 16px;
position: relative;
padding: 15px 0px 15px;


       @media only screen and (max-width: 1300px) {
padding: 20px 0px 10px;

    }

`
export const TopCard = styled(InnerCard)`
padding: 15px 20px;
`

export const MonthlyText = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 80%;
    margin: auto;
    strong {
        font-size:29px;
        color:rgba(15, 49, 102, 0.9);
    }
    h3{
    font-size: 15px;
    color:rgba(15, 49, 102, 0.9);
    font-weight: 300;
    margin-top: 5px;
}

@media only screen and (max-width: 500px) {
        width: 100%;
        }

`

export const Dots = styled.img`
    position: absolute;
    z-index: 1;

`
export const DotsTop = styled(Dots)`
    top: -30px;
    right: -37px;
    @media only screen and (max-width: 767px) {
        display: none;
    }


`
export const DotsBot = styled(Dots)`
    bottom: -30px;
    left: -36px;
`

const FormContainer: React.FunctionComponent = () => {
    const [loanAmount, setLoanAmount] = React.useState(150000);
    const { values, setFieldValue, handleSubmit, isSubmitting, submitForm, handleChange, handleBlur, errors, touched } = useFormikContext<FormValues>();
    var applicationApi = new ApplicationApi(apiConfiguration);
    const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);

    var applicationId;
    async function goToMyPage() {
        applicationId = await applicationApi.apiApplicationsGetApplicationPut({
            cpr: values.cpr,
            phone: values.phoneNumber,
        })

        removeCookie("NotFinished")
        var Id = applicationId.replaceAll('"', '')
        setCookie("Validated", Id, { path: "/", maxAge: 36000 })

        window.location.href = '/mypage';


    }

    function goBack() {
        values.step--;
        var params = window.location.search;
        var mark;
        var regex = /((&|\?)step=)[0-9]{1,2}/gi;
        if (params != "") {
            if (params.includes("step")) {
                params = params.replace(regex, "$1" + values.step);
                //mark = "&step=" + values.step;
                console.log("ramt2")

            }
            else {
                mark = "&step=" + values.step;
                console.log("ramt1")

            }
        }
        else {
            console.log("ramt")
            mark = "?step=" + values.step;
        };

        if (mark == undefined)
            mark = "";

        var stepQuery = window.location.protocol + "//" + window.location.host + window.location.pathname + params + mark;
        window.history.pushState({ path: stepQuery }, '', stepQuery)
            const urlParams = new URLSearchParams(window.location.search);
            console.log(urlParams);
            var myParam = urlParams.get('step');
            if (myParam == null) {
                setFieldValue("step", 1);
            }
            else {
                setFieldValue("step", myParam);
            }
        document.getElementById('formTop').scrollIntoView();

    }

    return (
        <div style={{ position: "relative" }}>
            <DotsTop src="../images/dots.svg" alt="dots" />
            <React.Suspense fallback={Loader}>
                <FormCon className="form-con" >
                    <TopPart />
                    <FormSelf onSubmit={handleSubmit} className={values.step <= 2 ? "col-l2" : "col-l2 form-three"}>
                        <InnerCard style={values.step > 2 && values.editLoan == false ? { padding: "0", border: "0px" } : {}} className={values.isSamleLaan && "samlelaanCard"}>


                            {values.step <= 2 &&
                                <div>
                                    {!values.isSamleLaan && !values.isCarLoan && <NormalLoan />}
                                    {values.isSamleLaan && <SamleLoan />}
                                    {values.isCarLoan && <CarLoan/> }
                                    <InnerCard style={values.isSamleLaan == true && values.step == 2 ? { borderRadius: "16px", marginTop: "25px" } : values.step == 1 ? { padding: 0, marginTop: 0, border: "0px" } : {}} className={!values.isSamleLaan && "samlelaanCard"}>
                                        {!values.isSamleLaan && !values.isCarLoan &&
                                            <InputFields />
                                        }
                                        {values.step == 2 && <StepTwo />}
                                    </InnerCard>
                                </div>
                            }
                            {values.editLoan &&
                                <ChangeValue />
                            }

                        </InnerCard>
                        {values.isDone && <Finished />}

                        {values.isDone == false &&
                            <div>
                                {values.editLoan == false &&
                                    <div>
                                        {values.step == 3 && <StepThree />}
                                        {values.step == 4 && <StepFour />}
                                        {values.step == 5 && <StepFive />}
                                        {values.step == 6 && <StepSix />}
                                        {values.step == 7 && <StepSeven />}
                                        {values.step == 8 && <StepEight />}
                                        {values.step == 9 && <StepNine />}
                                    </div>
                                }
                            </div>
                        }
                        {values.step >= 3 && values.step < 10 && values.editLoan == false &&
                            <div>
                                <NavContainer>
                                    <Back type="button" onClick={goBack}><img src="../images/arrow-left-short.svg" />Tilbage</Back>
                                <Next style={values.step == 9 ? { padding: "12px 28px" } : {}} type="submit" disabled={isSubmitting}>Næste <img src="../images/arrow-right-short-white.svg" /></Next>
                                </NavContainer>
                            </div>
                        }
                        {values.isDone &&
                            <NavContainer>
                                <Finish type="button" onClick={goToMyPage}>GÅ TIL MIN SIDE <img src="../images/arrow-right-short-white.svg" /></Finish>
                            </NavContainer>
                        }
                        {values.step <= 2 && values.rki != 1 && <Next type="submit" disabled={values.loanAmount < 1000 || isSubmitting} style={{ marginTop: "25px" }}>Næste <img src="../images/arrow-right-short-white.svg" alt="Næste" /></Next>}
                    </FormSelf>
                    {values.step <= 2 && <LoanExample>* ved 7% deb. rente. Priseks.: Samlet kreditbeløb {ThousandSeperator(values.loanAmount)} kr. Var. deb. rente 3,60 - 20,95%. ÅOP 4,11 - 24,99%. Etb. omk. {ThousandSeperator((values.loanAmount / 8))} kr. Samlet tilbagebetalingsbeløb {ThousandSeperator((((values.loanAmount) * interest / (1 - Math.pow(1 + interest, -(values.durationYears * 12)))) * (values.durationYears * 12) + (values.loanAmount / 8)).toFixed(0))} .</LoanExample>}
                    {values.step >= 3 && values.editLoan == false && values.isDone == false && <ProgressBar />}

                </FormCon>
            </React.Suspense>
            <DotsBot src="../images/dots.svg" alt="dots" />

        </div>
    )
}


export default FormContainer;