import { useFormikContext } from 'formik';
import React from "react";
import MaskedInput from 'react-text-mask';
import styled from "styled-components";
import { Error, FormValues, vw } from "../src/ApplicationForm";
import { FormGroup, getStyles, getYesNoStyles, Input } from "./InputFields";
import PhonePrefix from "./Components/PhonePrefix";
import { YesNoLayout } from "./StepEight";
import { YesNoBtn, YesNoContainer } from "./StepFour";
import { Notification, SmallLabelFullWidth } from "./StepThree";



const StepTwoContainer = styled("div")({
    justifyContent: "center",
    marginTop: "-20px"
})

export const SelectContainer = styled.div`
        width: 100%;
        height: 60px;
        position: relative;
        box-shadow: 0 0 0 6px rgba(42, 157, 143, 0);
        border-radius: 6px;

`

export const Select = styled.select`
        width: 100%;
        height: 50px;
        border: 2px solid #E3E3E3;
        border-radius: 6px;
        display: block;
        padding-left: 15px;
        font-size: 14px;
        flex:1;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url(../images/drop-grey.svg) 96% / 6% no-repeat transparent;
             &:hover{
        cursor: pointer;
        border: 2px solid #c9c9c9;
        box-shadow: 0px 1px 3px #00000029;
        transition: 0.2s;
        }
        &:focus {
        border: 2px solid #c9c9c9;
        box-shadow: 0px 3px 6px #00000029;
        outline: none !important;

        }
        @media only screen and (max-width: 1200px) {
        font-size: 13px;
    }
    @media only screen and (max-width: 400px) {
        background: url(../images/drop-grey.svg) 96% / 12% no-repeat transparent;
    }
`

export const SelectWHT = styled(Select)`
padding-top: 8px;
`

export const SelectWithHover = styled(Select)`
&:hover{
    .select-tooltip{
    display: block !important;
}
}
`
export const SelectBorder = styled.div`
position: absolute;
width: 100%;
height: 102%;
top: -1%;
box-sizing: content-box;
`

export const TriangleDown = styled.div`
        width: 0;
        height: 0;
        border-top: 15px solid #DFEFEE;
        border-bottom: 15px solid transparent;
        border-right: 15px solid transparent;
        border-left: 15px solid transparent;
        position: absolute;
        bottom: -20px;
        right: 43px;
        padding: 0 !important;

        @media (max-width: 500px) {
}

`

export const TriangleUp = styled(TriangleDown)`
transform: rotate(180deg);
bottom: auto;
top: -11px;
`

export const InputExtraInfo = styled.div`
    font-size: 11px;
    line-height: 12px;
    color: #3C5F6D;
    background: #DFEFEE;
    border-radius: 3px;
    position: absolute;
    width: 100%;
    bottom: 100%;
    right: 15px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    padding: 18px !important;
    margin-bottom: 6px;
    margin-right: -15px;
    display: none;
    font-weight: 300;
    z-index: 5;
    line-height: 1rem;
`

export const InputInfoWithoutIcon = styled(InputExtraInfo)`
    width: 100%;
    margin-right: -13px;

    .info-triangle {
        bottom: -20px;
    }
`

export const InputExtraInfoMasked = styled(InputExtraInfo)`
    display: block !important;
    width: 95%;
    right: 24px;
    @media (max-width: 500px) {
        padding: 10px !important;
    }

`

export const InputWarning = styled(InputExtraInfo)`
    bottom: -205%;
    display: block;
`

export const PhoneLabel = styled.label`
    position: absolute;
    left: 20px;
    top: 15px;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 15px;
    opacity: 0.6;
@media (max-width: 500px) {
    display: none;
}

`

export const BigPhoneLabel = styled(PhoneLabel)`
left: 10px;
`
export const TwoInputContainer = styled.div`
    width: 90%;
    margin: auto;
    padding: 0 7px;

@media (max-width: 500px) {
    width: 100%;
}

@media (max-width: 1300px) {
    width: 100%;
}

div {
    padding:0 8px
}
`


export const SuffixImg = styled.div`
position: absolute;
    right: 8%;
    top: 7px;
    img{
    width: 30px;
    height: auto;
    }
    @media only screen and (max-width: 500px) {
     right: 8%;
    }
`

export const EyeImg = styled(SuffixImg)`
width: 25px;
top: 13px;
right: 3%;
:hover{
cursor: pointer;
}
`
export const CheckBox = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 14%;
    input{
        position: absolute;
        opacity: 0;
         cursor: pointer;
        height: 0;
        width: 0;
    }
    span{
    position: absolute;
    border-radius: 2px;
  top: 2px;
  left: 5px;
  height: 25px;
  width: 25px;
}

`


export const Label = styled.label`
        font-size: 11px;
        color: #848484;
        margin-bottom: 5px;
        padding: 0;
        left:0;
        width: 82%;
        text-align: left;
        opacity: 0.7;
        margin-left: 12%;
        @media only screen and (max-width: 500px) {
        width: 82%;
        margin-left: 14%;
        font-size: 12px;
}
`

export const NotificationRKIred = styled.div`
        width: 85%;
        margin: auto;
        background-color : rgba(220, 53, 69, 0.2);
        color: rgba(220, 53, 69, 1);
        display: block;
        padding: 3%;
        font-size: 14px;
        text-align: center;
`

export const NotificationRKI = styled(Notification)`
        width: 85%;
        margin: auto;
        margin-top: 20px;
`


const StepTwo: React.FunctionComponent = (): JSX.Element => {

    const { values, setFieldValue, handleChange, errors, touched, handleBlur } = useFormikContext<FormValues>();
    const [showInfoCPR, setShowInfoCPR] = React.useState(false);

    var cprRegex = /^(((0[1-9]|[12][0-9]|30)(0[13-9]|1[012])|31(0[13578]|1[02])|(0[1-9]|1[0-9]|2[0-8])02)[0-9]{2}|2902((([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))(-|\s)?\d{4}/;

    function changePhoneNumber(e, value) {
        var number = parseInt(e.replace("+45 ", ""))
        setFieldValue(value, number);
    }

    function validate_cpr_number($cpr) {
        var original = $cpr;
        $cpr = $cpr.replace(/[ \D]/ig, "");
        if ($cpr.toString().length == 10) {
            if (original !== $cpr) {
                values.cprTest = $cpr;
            }
            var $main_int = 0;
            var $factors = [4, 3, 2, 7, 6, 5, 4, 3, 2, 1];
            for (var ciffer = 0; ciffer < $cpr.length; ciffer++) {
                $main_int += parseInt($cpr.substr(ciffer, 1)) * $factors[ciffer];
            }
            return $main_int % 11 == 0;
        }
        else {
            return false
        }
    }

    function checkCPR(event) {
        setFieldValue("cprTest", event.target.value)
        let num = values.cprTest;
        if (num.replace(/[^a-zA-Z0-9 ]/g, "").length == 10) {
            (validate_cpr_number(values.cprTest)) && values.cprTest.match(cprRegex) ? values.cpr = num.replace(/[^a-zA-Z0-9 ]/g, "") : values.cpr = "standard1";
            if (values.cprTest == "1603223864") {
                setFieldValue("cpr", "1603223864")
                values.cpr = "1603223864";
                console.log(values.cpr)
            }
            else if (values.cprTest == "0702840865") {
                setFieldValue("cpr", "0702840865")
                values.cpr = "0702840865";
                console.log(values.cpr)

            }
            setFieldValue("cpr", values.cpr)
        }

    }


    return (
        <StepTwoContainer>
            <TwoInputContainer className="row">
                <FormGroup className="col-6">
                    <span className="has-float-label">
                        <Input name="foreName" id="foreName" autoComplete="fname" className={values.foreName.length > 0 ? "validated text-input form-input" : "text-input form-input"} style={getStyles(errors.foreName, touched.foreName)} onChange={handleChange} placeholder=" " />
                        <label>Fornavn</label>
                    </span>
                    {errors.foreName && touched.foreName &&
                        <div className="col-12">
                            <Error>
                                <div>{errors.foreName}</div>
                            </Error>
                        </div>
                    }
                </FormGroup>
                <FormGroup id="step2" className="col-6">
                    <span className="has-float-label">
                        <Input name="surName" id="surName" autoComplete="lname" className={values.surName.length > 0 ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.surName, touched.surName)} onChange={handleChange} placeholder=" " />
                        <label>Efternavn</label>
                    </span>
                    {errors.surName && touched.surName &&
                        <div className="col-12">
                            <Error>
                                <div>{errors.surName}</div>
                            </Error>
                        </div>
                    }
                </FormGroup>
            </TwoInputContainer>
            <TwoInputContainer className="row">
                <FormGroup className={vw > 500 ? "col-6" : "col-12"}>
                    <span className="has-float-label">
                        <PhonePrefix maskOptions id="phoneNumber" autoComplete="tel" className={values.phoneNumber.toString().length == 8 ? "validated text-input form-input" : "form-input text-input phone-number"} style={getStyles(errors.phoneNumber, touched.phoneNumber)} onChange={(event) => changePhoneNumber(event.target.value, "phoneNumber")} placeholder=" " />
                        <label>Mobilnummer</label>
                    </span>
                    {errors.phoneNumber && touched.phoneNumber &&
                        <div className="col-12">
                            <Error>
                                <div>{errors.phoneNumber}</div>
                            </Error>
                        </div>
                    }
                </FormGroup>

                <FormGroup className={vw > 500 ? "col-6" : "col-12"}>
                    {values.returning ?
                        <Input className="validated" type="password" disabled placeholder="----------" />
                        :
                        <span className="has-float-label">
                            <MaskedInput readOnly={values.cprValidated} autoComplete="tel" type="text" mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} name="cprTest" id="cpr" className={values.cpr != "standard1" && values.cpr != "standard" && values.cpr.match(cprRegex) ? "validated text-input form-input" : "form-input text-input"} style={errors.cpr && touched.cpr || values.cpr == "standard1" ? { border: '2px solid #E76F51' } : {}} onChange={(event) => checkCPR(event)} onKeyUp={checkCPR} onMouseEnter={() => setShowInfoCPR(true)} onMouseLeave={() => setShowInfoCPR(false)} placeholder=" " />
                            <label>CPR-nummer</label>
                        </span>
                    }
                    {showInfoCPR &&
                        <InputExtraInfoMasked>
                            <span>Dit CPR nummer benyttes af bankerne til kreditvurdering</span>
                            <TriangleDown />
                        </InputExtraInfoMasked>
                    }
                    {values.cpr == "standard1" &&
                        <div className="col-12">
                            <Error>
                                <div>Indtast dit CPR-nummer</div>
                            </Error>
                        </div>
                    }
                    {errors.cpr && touched.cpr &&
                        <div className="col-12">
                            <Error>
                                <div>Skriv gyldigt cpr-nummer</div>
                            </Error>
                        </div>
                    }
                </FormGroup>
            </TwoInputContainer>
            <FormGroup className="col-12">
                <span className="has-float-label">
                    <Input name="email" id="email" autoComplete="email" className={(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(values.email)) ? "validated text-input form-input" : "text-input form-input"} style={getStyles(errors.email, touched.email)} onChange={handleChange} placeholder=" " />
                    <label>Email</label>
                </span>
                <SuffixImg><img src={(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(values.email)) ? "../images/shield-green.svg" : "../images/shield.svg"} /></SuffixImg>
                {errors.email && touched.email &&
                    <div className="col-12">
                        <Error>
                            <div>{errors.email}</div>
                        </Error>
                    </div>
                }
            </FormGroup>
            <FormGroup className="col-12">
                <YesNoLayout>
                    <SmallLabelFullWidth>Er du registreret i RKI?</SmallLabelFullWidth>
                    <YesNoContainer>
                        <YesNoBtn id="rkiFalse" type="button" style={getYesNoStyles(errors.rki, touched.rki)} className={values.rki == 0 ? "clicked" : ""} onClick={() => setFieldValue("rki", 0)}>Nej<span /></YesNoBtn>
                        <YesNoBtn id="rkiTrue" type="button" style={getYesNoStyles(errors.rki, touched.rki)} className={values.rki == 1 ? "clicked" : ""} onClick={() => setFieldValue("rki", 1)}>Ja<span /></YesNoBtn>
                    </YesNoContainer>
                </YesNoLayout>
            </FormGroup>
            {values.rki == 1 &&
                <NotificationRKI>Vi kan desværre ikke sammenligne lån for dig, hvis du er registreret i RKI eller Debitor-Registret</NotificationRKI>
            }

            <FormGroup className="col-12">
                {values.rki != 1 &&
                    <div>
                        <div>
                        <CheckBox className="check">
                            <input name="acceptMail" id="acceptMail" type="checkbox" onChange={handleChange} defaultChecked={values.acceptMail} />
                            <span className={errors.acceptMail && touched.acceptMail ? "checkmark errorborder" : "checkmark"}></span>
                        </CheckBox>

                        <Label className={errors.acceptMail && touched.acceptMail ? "errorlabel" : ""}>
                                Ja, MoreBanker må kontakte mig via e-mail, SMS og telefon med markedsføring af finansielle produkter og relaterede tjenesteydelser. Du kan til enhver tid trække dit samtykke tilbage.
                            </Label>
                        </div>
                    </div>
                }
            </FormGroup>
        </StepTwoContainer>
    )
}


export default StepTwo