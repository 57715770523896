/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Permit {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4
}

export function PermitFromJSON(json: any): Permit {
    return PermitFromJSONTyped(json, false);
}

export function PermitFromJSONTyped(json: any, ignoreDiscriminator: boolean): Permit {
    return json as Permit;
}

export function PermitToJSON(value?: Permit | null): any {
    return value as any;
}

