import React from "react";
import * as ReactDOM from "react-dom";
import ApplicationForm from "./ApplicationForm"
import LoginForm from "./LoginForm";

const applicationForm = document.getElementsByTagName("applicationForm");
if (applicationForm.length > 0) {
    const element = applicationForm[0];
    ReactDOM.render(
        <ApplicationForm />,
        element
    );
}

const loginForm = document.getElementsByTagName("loginForm");
if (loginForm.length > 0) {
    const element = loginForm[0];
    ReactDOM.render(
        <LoginForm />,
        element
    );
}