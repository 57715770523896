import React from "react";
import styled from "styled-components";
import { BruttoInput, getStyles, getYesNoStyles } from "./InputFields";
import { useFormikContext } from 'formik';
import { CivilStatus, LoanReason } from "../src/Enums";
//import { InputExtraInfo, Select, SelectWithHover, SuffixImg, TriangleDown } from "./StepTwo";
//import { ButtonContainerSmall, SmallLabel, InfoText, InputGroup } from "./StepThree";
//import { CarBtn, CarBtnCon, ThreeOptions, YesNoBtn, YesNoContainer } from "./StepFour";
//import { YesNoLayout, Info } from "./StepEight";
import { InputExtraInfo, Select, SelectWithHover, TriangleDown, InputInfoWithoutIcon, SuffixImg } from "./StepTwo";
import { ButtonContainerSmall, SmallLabel, InfoText, InputGroup, TooltipWrapper, InfoTextToolTip } from "./StepThree";
import { CarBtn, CarBtnCon, ThreeOptions, YesNoBtn, YesNoContainer, CloseButton } from "./StepFour";
import { YesNoLayout, Info, InfoSmaller, LabelSpan, WrapperLabel } from "./StepEight";
import { useCookies } from "react-cookie";
import { Error, FormValues } from "../src/ApplicationForm";
import { Child } from "../Models/Child";
import ChildrenCounter from "./Components/ChildInfo";
import { FormTop, Hello, MenuContainer, MenuPoint, MenuPointIcon, TopCard } from "./FormContainer";
import CurrencySuffix from "./Components/CurrencySuffix";
import AccountNumber from "./Components/AccountNumber";
import RegNumber from "./Components/RegNumber";
import StepSign from "./StepSign";


export const SelectLabel = styled.label`
position: absolute;
font-size: 14px;
opacity: 0.7;
top: 0;
bottom: 0;
margin-top: auto;
margin-bottom: auto;
margin-left: 14px;
display: flex;
align-items: center;
pointer-events: none;
height: 20px;
color: #315665;
padding: 0 1px;
`
export const ThreeOptionsContainer = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
padding-bottom: 20px;
`

export function ThousandSeperator(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}




const TopPart: React.FunctionComponent = () => {
    const { values, setFieldValue, handleSubmit, isSubmitting, submitForm, handleChange, handleBlur, errors, touched } = useFormikContext<FormValues>();
    const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
    const urlParams = new URLSearchParams(window.location.search);
    var applicationId;
    const [showTooltip1, setShowTooltip1] = React.useState(false);
    const [AccountLength, setAccountLength] = React.useState(0);
    const [BankLength, setBankLength] = React.useState(0);
    const [showErrorIncomeValidation, setshowErrorIncomeValidation] = React.useState(false);
    const [showTooltip, setTooltip] = React.useState(false);
    const [coApp, setCoApp] = React.useState((urlParams.get('coApp') === 'true') ? true : (urlParams.get('coApp') === 'false') ? false : null)

    var date = new Date();
    let currentYear: number;
    currentYear = date.getFullYear();

    function setNumOfChildren(n) {
        if (values.children.length != 0) {
            values.children = [];
        }
        for (let array = values.children, i = 0; i < n; i++) {
            array.push(new Child)
        }
        setFieldValue("numOfChildren", values.children.length)
    }

    function maritalChange(e) {
        setFieldValue("maritalStatus", e.target.value)
        if (values.maritalStatus != CivilStatus.Gift && values.maritalStatus != CivilStatus.Samlevende) {
            setFieldValue("coApplicant", false)
        }
    }

    function Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }


    function samleLaan() {
        setFieldValue("isSamleLaan", true);
        setFieldValue("isCarLoan", false);
        setFieldValue("loanReason", LoanReason.Samlelaan)
    }

    function carLoan() {
        setFieldValue("isCarLoan", true);
        setFieldValue("loanReason", LoanReason.Car)
        setFieldValue("isSamleLaan", false);

    }

    function normalLoan() {
        setFieldValue("isSamleLaan", false);
        setFieldValue("isCarLoan", false);
        setFieldValue("loanReason", "")
    }

    function changeNumber(e, value) {
        var amount = parseInt(e.replaceAll(".", ""))
        if (amount > -1) {
            setFieldValue(value, amount);
        }

    }

    function changeAccountNumber(e, value) {
        setFieldValue(value, e);
        if (value == "creditPaymentAccountNumber") {
            var test = e.toString();
            setAccountLength(test.length);
        }
        else if (value == "creditPaymentBankCode") {
            var test = e.toString();
            setBankLength(test.length);
        }

    }

    function changeNumberIncomeValidation(e, value) {
        var amount = parseInt(e.replaceAll(".", ""))
        if (amount > -1) {
            setFieldValue(value, amount);
            if (amount <= 0 || amount >= values.incomeGrossAmount)
                setshowErrorIncomeValidation(true)
        }
    }

    function toogleTooltip(value) {
        setTooltip(value);
    }

    return (
        <div style={values.step > 9 ? { marginBottom: "45px", position: "relative" } : { position: "relative" }}>
            <FormTop >
                {values.isDone == false && values.step < 10 &&
                    <MenuContainer>
                        {(values.step <= 2 && !values.inSession) &&

                            <MenuPoint id="leftPointMenu" className={!values.isSamleLaan && !values.isCarLoan ? "active" : ""} onClick={normalLoan}>
                                <span>LÅN PENGE</span>
                                <MenuPointIcon>{!values.isSamleLaan && !values.isCarLoan ? <img src="../images/checkmark.svg" /> : <></> }</MenuPointIcon>
                            </MenuPoint>
                        }
                        {(values.step >= 3 || values.inSession) &&

                            <MenuPoint style={{width: "50%"}} className={values.editLoan == true ? "" : "active"} onClick={() => setFieldValue("editLoan", false)}>
                                <span>LÅN PENGE</span>
                                <MenuPointIcon>{values.editLoan == true ? "" : <img src="../images/checkmark.svg" />}</MenuPointIcon>
                            </MenuPoint>
                        }
                        {(values.step <= 2 && !values.inSession) &&

                            <MenuPoint id="midPointMenu" className={values.isSamleLaan == true ? "active" : ""} onClick={samleLaan}>
                                <span>SAMLELÅN</span>
                                <MenuPointIcon>{values.isSamleLaan == true ? <img src="../images/checkmark.svg" /> : ""}</MenuPointIcon>
                            </MenuPoint>
                        }
                        {(values.step <= 2 && !values.inSession) && 
                            <MenuPoint id="rightPointMenu" className={values.isCarLoan == true ? "active" : ""} onClick={carLoan}>
                                <span>BILLÅN</span>
                                <MenuPointIcon>{values.isCarLoan == true ? <img src="../images/checkmark.svg" /> : ""}</MenuPointIcon>
                            </MenuPoint>
                        
                        }

                        {(values.step >= 3 || values.inSession ) &&

                            <MenuPoint style={{ width: "50%" }} className={values.editLoan == true ? "active" : ""} onClick={() => setFieldValue("editLoan", true)}>
                                <span>RET BELØB</span>
                                <MenuPointIcon>{values.editLoan == true ? <img src="../images/checkmark.svg" /> : ""}</MenuPointIcon>
                            </MenuPoint>
                        }
                    </MenuContainer>
                }
            </FormTop>
            {values.step >= 3 && values.editLoan == false && values.isDone == false &&
                <TopCard style={values.step >= 10 ? { borderRadius: "16px", paddingTop: "42px", borderTop: "1px solid #87989F" } : {}}>
                <Hello id="hello" style={values.step == 3 ? { marginBottom: "20px" } : {}}>
                        {(coApp != null && coApp == true) ?
                            <h2>Hej {Capitalize(values.coForeName)}</h2>
                        :
                            <h2>Hej {Capitalize(values.foreName)}</h2>
                    }

                        <div className="col-12 no-padding">
                            <img src="../images/arrow-right-short.svg" />
                            {values.step == 3 && <h4>Lad os finde det bedste lån til dig på 2 minutter</h4>}
                            {values.step <= 5 && values.step > 3 && <h4> Oplysninger om dig og din familie</h4>}
                            {values.step == 6 && <h4>Oplysninger om din nuværende økonomi</h4>}
                            {values.step == 7 && <h4>Oplysninger om dine udgifter</h4>}
                            {values.step == 8 && <h4>Oplysninger om gæld</h4>}
                            {values.step == 9 && <h4>Bankoplysninger og samtykker</h4>}
                            {values.step == 10 && <h4>Færdiggør ansøgning med NemID eller MitID</h4>}
                        </div>
                    </Hello>
                {values.step == 3 &&
                    <div style={{marginBottom: "15px"}}>
                            <SmallLabel>Civilstand</SmallLabel>
                    <InputGroup style={{marginBottom: 0}}>
                                <Select className={values.maritalStatus != "" ? "validated select" : "select"} style={getStyles(errors.maritalStatus, touched.maritalStatus)} name="maritalStatus" id="maritalStatus" onChange={maritalChange} onBlur={handleBlur} value={values.maritalStatus != "" && values.maritalStatus}>
                                    <option hidden value=" "></option>
                                    <option value={CivilStatus.Single}>Single</option>
                                    <option value={CivilStatus.Gift}>Gift</option>
                                    <option value={CivilStatus.Samlevende}>Samlevende</option>
                                    <option value={CivilStatus.Skilt}>Skilt</option>
                                    <option value={CivilStatus.Skilt}>Sepereret</option>
                                    <option value={CivilStatus.Enke}>Enke/Enkemand</option>
                                </Select>
                                <SelectLabel className={values.maritalStatus != "" ? "select-chosen" : ""}>Din civilstand</SelectLabel>
                    </InputGroup>
                    {errors.maritalStatus && touched.maritalStatus &&
                        <div>
                            <Error>
                                <div>{errors.maritalStatus}</div>
                            </Error>
                        </div>
                    }
                        </div>
                    }
                    {values.step == 4 &&
                    <div>
                    <div style={{ marginBottom: "15px" }}>
                            <SmallLabel>Dit forbrug</SmallLabel>
                            <InputGroup style={{ marginBottom: 0 }} >
                                <SelectWithHover className={values.householdHeatingExpense > -1 ? "validated select" : "select"} style={getStyles(errors.householdHeatingExpense, touched.householdHeatingExpense)} name="householdHeatingExpense" id="householdHeatingExpense" onChange={handleChange} onBlur={handleBlur} value={values.householdHeatingExpense > -1 && values.householdHeatingExpense} onMouseEnter={() => setShowTooltip1(true)} onMouseLeave={() => setShowTooltip1(false)}>
                                    <option hidden value={-1}></option>
                                    <option value={0}>0 kr.</option>
                                    <option value={500}>1 - 500 kr.</option>
                                    <option value={1000}>501 - 1.000 kr.</option>
                                    <option value={1500}>1.001 - 1.500 kr.</option>
                                    <option value={2000}>1.501 - 2.000 kr.</option>
                                    <option value={2500}>2.001 - 2.500 kr.</option>
                                    <option value={3000}>2.501 - 3.000 kr.</option>
                                    <option value={3500}>3.001 - 3.500 kr.</option>
                                    <option value={4000}>Over 3.500 kr.</option>
                                </SelectWithHover>
                                <SelectLabel className={values.householdHeatingExpense > -1 ? "select-chosen" : ""}>Månedlige udgifter til varme og opvarmning</SelectLabel>
                                {showTooltip1 &&
                                    <InputExtraInfo className="select-tooltip">
                                        <span>En typisk single eller par i lejlighed bruger 480 kr. per måned på varme. En typisk familie i et hus med fjernvarme bruger 980 kr.</span>
                                        <TriangleDown />
                                    </InputExtraInfo>
                                }
                            </InputGroup>
                    {errors.householdHeatingExpense && touched.householdHeatingExpense &&
                        <div>
                            <Error>
                                <div>{errors.householdHeatingExpense}</div>
                            </Error>
                        </div>
                        }
                        </div>
                    <InputGroup>
                                <span className="has-float-label">
                                    <CurrencySuffix maskOptions placeholder=" " id="householdUtilitiesExpense" name="householdUtilitiesExpense" className={values.householdUtilitiesExpense > -1 && !errors.householdUtilitiesExpense ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.householdUtilitiesExpense, touched.householdUtilitiesExpense)} onChange={(event) => changeNumber(event.target.value, "householdUtilitiesExpense")} value={ThousandSeperator(values.householdUtilitiesExpense > -1 ? values.householdUtilitiesExpense : "")} />
                                    <label>Månedlige udgifter til el og vand</label>
                                </span>
                                <InputExtraInfo className="brutto-tooltip">
                                    <span>Hvad er dit forbrug per måned. Et cirka tal er helt fint.</span>
                                    <TriangleDown />
                                </InputExtraInfo>
                                { errors.householdUtilitiesExpense && touched.householdUtilitiesExpense &&
                                    <div>
                                        <Error>
                                            <div>{errors.householdUtilitiesExpense}</div>
                                        </Error>
                                    </div>
                                }
                    </InputGroup>
                            <InputGroup>
                                <span className="has-float-label">
                                    <CurrencySuffix maskOptions placeholder=" " id="householdDigitalMediaExpense" className={!errors.householdDigitalMediaExpense && values.householdDigitalMediaExpense > -1 ? "validated text-input form-input" : "text-input form-input"} style={getStyles(errors.householdDigitalMediaExpense, touched.householdDigitalMediaExpense)} onChange={(event) => changeNumber(event.target.value, "householdDigitalMediaExpense")} value={ThousandSeperator(values.householdDigitalMediaExpense > -1 && values.householdDigitalMediaExpense)} />
                                    <label>Månedlig udgifter til tlf, tv og internet</label>
                                </span>
                                {errors.householdDigitalMediaExpense && touched.householdDigitalMediaExpense &&
                                    <div>
                                        <Error>
                                            <div>{errors.householdDigitalMediaExpense}</div>
                                        </Error>
                                    </div>
                                }
                            </InputGroup>
                        </div>
                    }
                    {values.step == 5 &&
                        <div id="children">
                            <SmallLabel>Antal hjemmeboende børn</SmallLabel>
                            <CarBtnCon>
                                <ButtonContainerSmall><CarBtn onClick={() => setNumOfChildren(0)} className={values.numOfChildren === 0 ? "clicked" : ""}>0<span /></CarBtn></ButtonContainerSmall>
                                <ButtonContainerSmall><CarBtn onClick={() => setNumOfChildren(1)} className={values.numOfChildren == 1 ? "clicked" : ""} >1<span /></CarBtn></ButtonContainerSmall>
                                <ButtonContainerSmall><CarBtn onClick={() => setNumOfChildren(2)} className={values.numOfChildren == 2 ? "clicked" : ""} >2<span /></CarBtn></ButtonContainerSmall>
                                <ButtonContainerSmall><CarBtn onClick={() => setNumOfChildren(3)} className={values.numOfChildren == 3 ? "clicked" : ""} >3<span /></CarBtn></ButtonContainerSmall>
                                <ButtonContainerSmall><CarBtn onClick={() => setNumOfChildren(4)} className={values.numOfChildren == 4 ? "clicked" : ""} >4<span /></CarBtn></ButtonContainerSmall>
                                <ButtonContainerSmall><CarBtn onClick={() => setNumOfChildren(5)} className={values.numOfChildren == 5 ? "clicked" : ""} >5<span /></CarBtn></ButtonContainerSmall>
                            </CarBtnCon>
                            <ChildrenCounter />
                        </div>
                    }
                    {values.step == 6 &&
                        <div>
                            <SmallLabel>Din månedlige brutto indkomst</SmallLabel>
                            <InputGroup>
                                <span className="has-float-label">
                                    <CurrencySuffix maskOptions placeholder=" " name="incomeGrossAmount" id="incomeGrossAmount" className={values.incomeGrossAmount > 0 ? "validated text-input form-input" : "text-input form-input"} style={getStyles(errors.incomeGrossAmount, touched.incomeGrossAmount)} onBlur={(event) => changeNumber(event.target.value, "incomeGrossAmount")} value={ThousandSeperator(values.incomeGrossAmount > 0 ? values.incomeGrossAmount : "")} />
                                    <label>Indkomst før skat</label>
                                </span>
                        <InputInfoWithoutIcon className="brutto-tooltip">
                            <span>Indtast det beløb du får i løn eller understøttelse før skat.</span>
                            <TriangleDown />
                        </InputInfoWithoutIcon>
                                {errors.incomeGrossAmount && touched.incomeGrossAmount &&
                                    <div className="col-12">
                                        <Error>
                                            <div>{errors.incomeGrossAmount}</div>
                                        </Error>
                                    </div>
                                }
                            </InputGroup>

                    <SmallLabel>Din månedlige netto indkomst</SmallLabel>
                        <InputGroup>
                        <span className="has-float-label">
                            <CurrencySuffix maskOptions placeholder=" " name="incomeNetAmount" id="incomeNetAmount"
                                className={values.incomeNetAmount > 0 && values.incomeNetAmount < values.incomeGrossAmount ? "validated text-input form-input" : "form-input text-input"}
                                style={showErrorIncomeValidation ? { border: "2px solid rgb(231, 111, 81)" } : {}}
                                onChange={(event) => changeNumberIncomeValidation(event.target.value, "incomeNetAmount")}
                                value={values.incomeNetAmount > 0 ? values.incomeNetAmount : ""} />
                            <label>Indkomst efter skat </label>
                        </span>
                        <InputInfoWithoutIcon className="brutto-tooltip">
                            <span>Indtast det beløb du får udbetalt per måned</span>
                            <TriangleDown />
                        </InputInfoWithoutIcon>
                        {showErrorIncomeValidation &&
                                <div className="col-12">
                                    <Error>
                                        <div>{errors.incomeNetAmount}</div>
                                    </Error>
                                </div>
                            }
                        </InputGroup>
                        {values.coApplicant &&
                            <div style={{ width: "100%" }}>
                            </div>
                    }

                        </div>
                    }
                {values.step == 7 &&
                    <div style={{ marginBottom: "15px" }}>
                    <TooltipWrapper>
                        <SmallLabel>
                            Årlige forsikringsudgifter
                        </SmallLabel>
                            <Info className="info-circle">
                                <img onClick={() => toogleTooltip(false)} src="../images/information-tooltip.svg" alt="info" />
                                {!showTooltip &&
                                <InfoTextToolTip className="info-text">Dette kunne fx være udgifter til indbo, ulykke, hus eller bil<div className="info-triangle" />
                                        <CloseButton onClick={() => toogleTooltip(true)} type="button"></CloseButton>
                            </InfoTextToolTip>
                                }
                            </Info>
                        
                    </TooltipWrapper>

                    <InputGroup style={{marginBottom: 0}} >
                            <Select className={values.householdInsurance > -1 ? "validated select" : "select"} style={getStyles(errors.householdInsurance, touched.householdInsurance)} name="householdInsurance" id="householdInsurance" value={values.householdInsurance > -1 && values.householdInsurance} onChange={handleChange} onBlur={handleBlur}>
                                <option hidden value={-1}></option>
                                <option value={0}>0 kr.</option>
                                <option value={5000}>1 - 5.000 kr.</option>
                                <option value={10000}>5.001 - 10.000 kr.</option>
                                <option value={15000}>10.001 - 15.000 kr.</option>
                                <option value={20000}>15.001 - 20.000 kr.</option>
                                <option value={25000}>20.001 - 25.000 kr.</option>
                                <option value={30000}>Over 25.000 kr.</option>
                            </Select>
                            <SelectLabel className={values.householdInsurance > -1 ? "select-chosen" : ""}>Årlige forsikringsudgifter</SelectLabel>
                    </InputGroup>
                    {errors.householdInsurance && touched.householdInsurance &&
                        <div className="col-12" style={{padding: 0}}>
                            <Error>
                                <div>{errors.householdInsurance}</div>
                            </Error>
                        </div>
                    }
                    </div>}
                    {values.step == 8 &&
                        <div>
                    <YesNoLayout>
                        <TooltipWrapper>
                            <SmallLabel>
                                <LabelSpan><p>Har du optaget gæld siden 1. januar i år?</p>
                                    <InfoSmaller className="info-circle"><img onClick={() => toogleTooltip(false)} src="../images/information-tooltip.svg" alt="info" />
                                        {!showTooltip &&
                                            <InfoText className="info-text">Kan eksempelvis være realkreditlån eller forbrugslån<div className="info-triangle" />
                                                <CloseButton onClick={() => toogleTooltip(true)} type="button"></CloseButton>
                                            </InfoText>
                                        }
                                    </InfoSmaller>
                                </LabelSpan>
                            </SmallLabel>
                        </TooltipWrapper>

                        <YesNoContainer>
                            <YesNoBtn type="button" style={getYesNoStyles(errors.debtSinceJan, touched.debtSinceJan)} className={values.debtSinceJan == 1 ? "clicked" : ""} onClick={() => setFieldValue("debtSinceJan", 1)}>Ja<span></span></YesNoBtn>
                            <YesNoBtn type="button" style={getYesNoStyles(errors.debtSinceJan, touched.debtSinceJan)} className={values.debtSinceJan == 0 ? "clicked" : ""} onClick={() => setFieldValue("debtSinceJan", 0)}>Nej<span></span></YesNoBtn>
                        </YesNoContainer>
                    </YesNoLayout>
                            {values.debtSinceJan == 1 &&
                                <div className="full-width">
                                    <SmallLabel>Hvor meget gæld har du optaget?</SmallLabel>
                                    <InputGroup>
                                        <span className="has-float-label">
                                            <CurrencySuffix maskOptions placeholder=" " name="debtSinceLastTexReport" id="debtSinceLastTexReport" className={values.debtSinceLastTexReport > 0 ? "validated text-input form-input" : "text-input form-input"} style={getStyles(errors.debtSinceLastTexReport, touched.debtSinceLastTexReport)} onBlur={(event) => changeNumber(event.target.value, "debtSinceLastTexReport")} value={values.debtSinceLastTexReport > 0 ? values.debtSinceLastTexReport : ""} />
                                            <label>Gæld siden 1. januar</label>
                                        </span>
                                        {errors.debtSinceLastTexReport && touched.debtSinceLastTexReport &&
                                            <div className="col-12">
                                                <Error>
                                                    <div>{errors.debtSinceLastTexReport}</div>
                                                </Error>
                                            </div>
                                        }
                                    </InputGroup>
                                </div>
                            }
                            {values.coApplicant &&
                                <div>
                                    <YesNoContainer>
                                        <SmallLabel>Har medansøger optaget gæld siden 1. januar i år?</SmallLabel>
                                        <YesNoContainer id="debtSinceJan">
                                            <YesNoBtn type="button" style={getYesNoStyles(errors.coDebtSinceJan, touched.coDebtSinceJan)} className={values.coDebtSinceJan == 1 ? "clicked" : ""} onClick={() => setFieldValue("coDebtSinceJan", 1)}>Ja<span></span></YesNoBtn>
                                            <YesNoBtn type="button" style={getYesNoStyles(errors.coDebtSinceJan, touched.coDebtSinceJan)} className={values.coDebtSinceJan == 0 ? "clicked" : ""} onClick={() => setFieldValue("coDebtSinceJan", 0)}>Nej<span></span></YesNoBtn>
                                        </YesNoContainer>
                                    </YesNoContainer>
                                    {values.coDebtSinceJan == 1 &&
                                        <div className="full-width">
                                            <SmallLabel>Hvor meget gæld har medansøger optaget?</SmallLabel>
                                            <InputGroup>
                                                <span className="has-float-label">
                                                    <CurrencySuffix maskOptions placeholder=" " name="coDebtSinceLastTexReport" id="coDebtSinceLastTexReport" className={values.coDebtSinceLastTaxReport > 0 ? "validated text-input form-input" : "form-input text-input"} style={getStyles(errors.coDebtSinceLastTaxReport, touched.coDebtSinceLastTaxReport)} onBlur={(event) => changeNumber(event.target.value, "coDebtSinceLastTexReport")} value={values.coDebtSinceLastTaxReport > 0 ? values.coDebtSinceLastTaxReport : ""} />
                                                    <label>Gæld siden 1. januar</label>
                                                </span>
                                                {errors.coDebtSinceLastTaxReport && touched.coDebtSinceLastTaxReport &&
                                                    <div className="col-12">
                                                        <Error>
                                                            <div>{errors.coDebtSinceLastTaxReport}</div>
                                                        </Error>
                                                    </div>
                                                }
                                            </InputGroup>
                                        </div>
                                    }
                                </div>
                            }

                        </div>
                    }
                    {values.step == 9 &&
                    <div className="wrap">
                    <SmallLabel style={{ marginBottom: "15px" }}>Registrerings- og kontonummer</SmallLabel>
                    <p style={{fontSize: "12px", marginTop:"-12px"}}>Vi opbevarer dine oplysninger sikkert og deler dem kun med de banker, som vi samarbejder med. Dit registrerings- og kontonummer benyttes kun, hvis du accepterer et lånetilbud.</p>
                    <div className="row" style={{margin: 0}}>
                            <div className="col-4 no-padding">
                                <InputGroup>
                                    <span className="has-float-label">
                                            <RegNumber maskOptions placeholder=" " name="creditPaymentBankCode" id="creditPaymentBankCode" className={values.creditPaymentBankCode.length == 4 ? "validated text-input form-input hover-tooltip" : "hover-tooltip form-input text-input"} style={getStyles(errors.creditPaymentBankCode, touched.creditPaymentBankCode)} onBlur={(event) => changeAccountNumber(event.target.value, "creditPaymentBankCode")} value={values.creditPaymentBankCode != "" ? values.creditPaymentBankCode : ""} />
                                        <label>Reg. Nr.</label>
                                    </span>
                                 <InputExtraInfo style={{ width: "100%" }} className="brutto-tooltip">
                                    <span>Kan findes på bagsiden af dit betalingskort eller i netbank</span>
                                        <TriangleDown />
                                    </InputExtraInfo>
                                    {errors.creditPaymentBankCode && touched.creditPaymentBankCode &&
                                        <div className="col-12">
                                            <Error>
                                                <div>{errors.creditPaymentBankCode}</div>
                                            </Error>
                                        </div>
                                    }
                                </InputGroup>
                            </div>
                        <div className="col-8 mobile-no-padding" style={{paddingRight: 0}}>
                                <InputGroup>
                                <span className="has-float-label">
                                            <AccountNumber maskOptions placeholder=" " name="creditPaymentAccountNumber" id="creditPaymentAccountNumber" className={values.creditPaymentAccountNumber.length == 10 ? "validated text-input form-input hover-tooltip" : "hover-tooltip text-input form-input"} style={getStyles(errors.creditPaymentAccountNumber, touched.creditPaymentAccountNumber)} onBlur={(event) => changeAccountNumber(event.target.value, "creditPaymentAccountNumber")} value={values.creditPaymentAccountNumber != "" ? values.creditPaymentAccountNumber : "" } />
                                        <label>Kontonummer</label>
                                </span>
                                <SuffixImg><img style={{ marginTop: "4px", width: "23px"}} src="../images/padlock.svg"/></SuffixImg>
                                    <InputExtraInfo style={{ width: "100%" }} className="brutto-tooltip">
                                        <span>Kontonummer skal indeholde 10 cifrer inkl. evt. 0'er foran kontonummer</span>
                                        <TriangleDown />
                                    </InputExtraInfo>

                                    {errors.creditPaymentAccountNumber && touched.creditPaymentAccountNumber &&
                                        <div className="col-12">
                                            <Error>
                                                <div>{errors.creditPaymentAccountNumber}</div>
                                            </Error>
                                        </div>
                                    }
                                </InputGroup>
                            </div>
                        </div>
                    <TooltipWrapper>
                        <SmallLabel>
                            <LabelSpan>Kunde i din bank siden</LabelSpan>
                            <InfoSmaller className="info-circle">
                                <img onClick={() => toogleTooltip(false)} src="../images/information-tooltip.svg" alt="info" />
                                {!showTooltip &&
                                    <InfoText className="info-text">Hvor lang tid har du været kunde i din nuværende bank?<div className="info-triangle" />
                                        <CloseButton onClick={() => toogleTooltip(true)} type="button"></CloseButton>
                                    </InfoText>
                                }
                            </InfoSmaller>
                        </SmallLabel>
                    </TooltipWrapper>
                            <ThreeOptionsContainer id="bankSinceYear">
                                <ThreeOptions type="button" style={getYesNoStyles(errors.bankSinceYear, touched.bankSinceYear)} className={values.bankSinceYear == currentYear - 3 ? "clicked" : ""} onClick={() => setFieldValue("bankSinceYear", currentYear - 3)}>0 - 3 år<span /></ThreeOptions>
                                <ThreeOptions type="button" style={getYesNoStyles(errors.bankSinceYear, touched.bankSinceYear)} className={values.bankSinceYear == currentYear - 7 ? "clicked" : ""} onClick={() => setFieldValue("bankSinceYear", currentYear - 7)}>4 - 7 år<span /></ThreeOptions>
                                <ThreeOptions type="button" style={getYesNoStyles(errors.bankSinceYear, touched.bankSinceYear)} className={values.bankSinceYear == currentYear - 8 ? "clicked" : ""} onClick={() => setFieldValue("bankSinceYear", currentYear - 8)}>8+ år<span /></ThreeOptions>
                            </ThreeOptionsContainer>
                        </div>
                }
                {values.step == 10 && <StepSign/>}
                </TopCard>
            }

        </div>
    )
}


export default TopPart;