/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CarType,
    CarTypeFromJSON,
    CarTypeFromJSONTyped,
    CarTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Car
 */
export interface Car {
    /**
     * 
     * @type {CarType}
     * @memberof Car
     */
    carType?: CarType;
    /**
     * 
     * @type {number}
     * @memberof Car
     */
    leaseAmount?: number;
}

export function CarFromJSON(json: any): Car {
    return CarFromJSONTyped(json, false);
}

export function CarFromJSONTyped(json: any, ignoreDiscriminator: boolean): Car {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'carType': !exists(json, 'carType') ? undefined : CarTypeFromJSON(json['carType']),
        'leaseAmount': !exists(json, 'leaseAmount') ? undefined : json['leaseAmount'],
    };
}

export function CarToJSON(value?: Car | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'carType': CarTypeToJSON(value.carType),
        'leaseAmount': value.leaseAmount,
    };
}


