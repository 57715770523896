import { bool, number, shape, string } from "prop-types";
import React from "react";
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';



const defaultMaskOptions = {
    prefix: '', 
    includeThousandsSeparator: false,
    allowDecimal: false,
    integerLimit: 10, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: true,
}

const AccountNumber = ({ maskOptions, ...inputProps }) => {
    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
        ...maskOptions,
    })

    return <MaskedInput mask={currencyMask} {...inputProps} />
}

AccountNumber.defaultProps = {
    inputMode: 'numeric',
    maskOptions: {},
}

AccountNumber.propTypes = {
    inputmode: string,
    maskOptions: shape({
        prefix: string,
        includeThousandsSeparator: bool,
        allowDecimal: bool,
        integerLimit: number,
        allowLeadingZeroes: bool,
    }),
}

export default AccountNumber
