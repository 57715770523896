/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CarType {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

export function CarTypeFromJSON(json: any): CarType {
    return CarTypeFromJSONTyped(json, false);
}

export function CarTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CarType {
    return json as CarType;
}

export function CarTypeToJSON(value?: CarType | null): any {
    return value as any;
}

