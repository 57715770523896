import { bool, number, shape, string } from "prop-types";
import React from "react";
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';



const defaultMaskOptions = {
    prefix: '',
    includeThousandsSeparator: false,
    allowDecimal: false,
    integerLimit: 4, // limit length of integer numbers
    allowLeadingZeroes: true,

}

const RegNumber = ({ maskOptions, ...inputProps }) => {
    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
        ...maskOptions,
    })

    return <MaskedInput mask={currencyMask} {...inputProps} />
}

RegNumber.defaultProps = {
    inputMode: 'numeric',
    maskOptions: {},
}

RegNumber.propTypes = {
    inputmode: string,
    maskOptions: shape({
        prefix: string,
        includeThousandsSeparator: bool,
        allowDecimal: bool,
        integerLimit: number,
        allowLeadingZeroes: bool,
    }),
}

export default RegNumber
