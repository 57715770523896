/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApplicationBody,
    ApplicationBodyFromJSON,
    ApplicationBodyToJSON,
    DbApplicationDTO,
    DbApplicationDTOFromJSON,
    DbApplicationDTOToJSON,
    YdelseResponse,
    YdelseResponseFromJSON,
    YdelseResponseToJSON,
} from '../models';

export interface ApiApplicationsCreateApplicationPostRequest {
    url?: string | null;
    applicationBody?: ApplicationBody;
}

export interface ApiApplicationsGetApplicationPutRequest {
    cpr?: string | null;
    phone?: string | null;
}

export interface GetApplicationByIdRequest {
    id?: string | null;
}

export interface GetDibaYdelseRequest {
    carPrice?: number;
    carPayment?: number;
    carYear?: number;
    durationYears?: number;
    isFast?: boolean;
}

export interface UpdateApplicationRequest {
    applicationBody?: ApplicationBody;
}

/**
 * 
 */
export class ApplicationApi extends runtime.BaseAPI {

    /**
     */
    async apiApplicationsCreateApplicationPostRaw(requestParameters: ApiApplicationsCreateApplicationPostRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.url !== undefined) {
            queryParameters['url'] = requestParameters.url;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/applications/CreateApplication`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationBodyToJSON(requestParameters.applicationBody),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiApplicationsCreateApplicationPost(requestParameters: ApiApplicationsCreateApplicationPostRequest): Promise<string> {
        const response = await this.apiApplicationsCreateApplicationPostRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiApplicationsGetApplicationPutRaw(requestParameters: ApiApplicationsGetApplicationPutRequest): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters.cpr !== undefined) {
            queryParameters['cpr'] = requestParameters.cpr;
        }

        if (requestParameters.phone !== undefined) {
            queryParameters['phone'] = requestParameters.phone;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/applications/GetApplication`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiApplicationsGetApplicationPut(requestParameters: ApiApplicationsGetApplicationPutRequest): Promise<string> {
        const response = await this.apiApplicationsGetApplicationPutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getApplicationByIdRaw(requestParameters: GetApplicationByIdRequest): Promise<runtime.ApiResponse<DbApplicationDTO>> {
        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/applications/getapplication`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DbApplicationDTOFromJSON(jsonValue));
    }

    /**
     */
    async getApplicationById(requestParameters: GetApplicationByIdRequest): Promise<DbApplicationDTO> {
        const response = await this.getApplicationByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getDibaYdelseRaw(requestParameters: GetDibaYdelseRequest): Promise<runtime.ApiResponse<YdelseResponse>> {
        const queryParameters: any = {};

        if (requestParameters.carPrice !== undefined) {
            queryParameters['carPrice'] = requestParameters.carPrice;
        }

        if (requestParameters.carPayment !== undefined) {
            queryParameters['carPayment'] = requestParameters.carPayment;
        }

        if (requestParameters.carYear !== undefined) {
            queryParameters['carYear'] = requestParameters.carYear;
        }

        if (requestParameters.durationYears !== undefined) {
            queryParameters['durationYears'] = requestParameters.durationYears;
        }

        if (requestParameters.isFast !== undefined) {
            queryParameters['isFast'] = requestParameters.isFast;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/applications/getdibaydelse`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => YdelseResponseFromJSON(jsonValue));
    }

    /**
     */
    async getDibaYdelse(requestParameters: GetDibaYdelseRequest): Promise<YdelseResponse> {
        const response = await this.getDibaYdelseRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateApplicationRaw(requestParameters: UpdateApplicationRequest): Promise<runtime.ApiResponse<ApplicationBody>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/applications/application`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationBodyToJSON(requestParameters.applicationBody),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationBodyFromJSON(jsonValue));
    }

    /**
     */
    async updateApplication(requestParameters: UpdateApplicationRequest): Promise<ApplicationBody> {
        const response = await this.updateApplicationRaw(requestParameters);
        return await response.value();
    }

}
