export enum LoanType {
    Car = 1,
    Housing = 2,
    SU = 3,
    Other = 4
}

export enum CivilStatus {
    Single = "Alene",
    Gift = "Gift",
    Samlevende = "Samlevende",
    Skilt = "Skilt/Sepereret",
    Enke = "Enke/enkemand",
}

export enum HouseType {
    Leje = "Lejebolig",
    Hus = "Hus",
    Ejer = "EjerLejlighed",
    Andel = "Andelsbolig",
    Hjemme = "Bor hjemme",
    Room = "værelse/kollegie",
}

export enum CarOwnership {
    Privat = 0,
    Leaset = 1,
    Firmabil = 2,
}

export enum LoanReason {
    Samlelaan = "Samlelån",
    Car = "Køb a bil",
    Vehicle = "Køb af båd eller MC",
    Renovation = "Renovering af bolig",
    Travel = "Ferie, rejser, oplevelser",
    Special = "Særlige begivenheder",
    HouseDeposit = "Depositum til bolig",
    Dentist = "Tandlæge, operation",
    Debt = "Til dækning af gæld",
    Electronics = "Køb af elektronik",
    Other = "Andet",


}

export enum EmploymentType {
    Unemployed = 0,
    SalaryEmployed = 1,
    Hourly = 2,
    Management = 3,
    Homemaker = 4,
    Retired = 5,
    SelfEmployed = 6,
    Student = 7,
    Trainee = 8,
    EarlyRetirement = 9,
    Other = 10,
    Temporary = 11,

}

export enum CitizenshipCategory {
    Undefined = 0,
    Danish = 1,
    Nordic = 2,
    Other = 3,

}

export enum CitizenshipType {
    Temporary = 1,
    Permanent = 2,
    Registration = 3,
    Unlimited = 4,
}