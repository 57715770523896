/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BinaryBool,
    BinaryBoolFromJSON,
    BinaryBoolFromJSONTyped,
    BinaryBoolToJSON,
    Car,
    CarFromJSON,
    CarFromJSONTyped,
    CarToJSON,
    Child,
    ChildFromJSON,
    ChildFromJSONTyped,
    ChildToJSON,
    CitizenshipCategory,
    CitizenshipCategoryFromJSON,
    CitizenshipCategoryFromJSONTyped,
    CitizenshipCategoryToJSON,
    EmploymentType,
    EmploymentTypeFromJSON,
    EmploymentTypeFromJSONTyped,
    EmploymentTypeToJSON,
    Permit,
    PermitFromJSON,
    PermitFromJSONTyped,
    PermitToJSON,
    SamleLaan,
    SamleLaanFromJSON,
    SamleLaanFromJSONTyped,
    SamleLaanToJSON,
} from './';

/**
 * 
 * @export
 * @interface ApplicationBody
 */
export interface ApplicationBody {
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationBody
     */
    isSamleLaan?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    email?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationBody
     */
    acceptMail?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    foreName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    surName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    loanAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    durationYears?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    loanReason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    otherReason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    cpr?: string | null;
    /**
     * 
     * @type {Array<SamleLaan>}
     * @memberof ApplicationBody
     */
    samleLaan?: Array<SamleLaan> | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    step?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    coForeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    coSurName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    coEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    coCpr?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    maritalStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    rent?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    ownerExpense?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    rkiMember?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    dwelling?: string | null;
    /**
     * 
     * @type {Array<Child>}
     * @memberof ApplicationBody
     */
    children?: Array<Child> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationBody
     */
    coApplicant?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationBody
     */
    marketing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationBody
     */
    creditCheck?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationBody
     */
    eSkatConsent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationBody
     */
    insurance?: boolean;
    /**
     * 
     * @type {Array<Car>}
     * @memberof ApplicationBody
     */
    cars?: Array<Car> | null;
    /**
     * 
     * @type {BinaryBool}
     * @memberof ApplicationBody
     */
    hasOldLoan?: BinaryBool;
    /**
     * 
     * @type {BinaryBool}
     * @memberof ApplicationBody
     */
    sharedExpenses?: BinaryBool;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    coPhoneNumber?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationBody
     */
    addressSince?: Date;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    addressSinceMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    addressSinceYear?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    householdHeatingExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    householdUtilitiesExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    householdDigitalMediaExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    householdInsurance?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    householdTransportationOtherExpense?: number;
    /**
     * 
     * @type {EmploymentType}
     * @memberof ApplicationBody
     */
    employmentType?: EmploymentType;
    /**
     * 
     * @type {EmploymentType}
     * @memberof ApplicationBody
     */
    coEmploymentType?: EmploymentType;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    employerIdentificationNumber?: number;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationBody
     */
    employmentSinceDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationBody
     */
    coEmploymentSinceDate?: Date;
    /**
     * 
     * @type {BinaryBool}
     * @memberof ApplicationBody
     */
    jobInsuranceStatus?: BinaryBool;
    /**
     * 
     * @type {BinaryBool}
     * @memberof ApplicationBody
     */
    coJobInsuranceStatus?: BinaryBool;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    incomeGrossAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    coIncomeGrossAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    incomeNetAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    coIncomeNetAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    incomeOtherType?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    coIncomeOtherType?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    incomeOtherAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    coIncomeOtherAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    householdDiscretionaryIncome?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    studyLoanRest?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    studyLoanExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    carLoanRest?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    carLoanExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    consumerLoanRest?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    consumerLoanExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    otherDebt?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    otherDebtExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    publicDebtExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    publicDebtRest?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    debtSinceLastTexReport?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    coDebtSinceLastTaxReport?: number;
    /**
     * 
     * @type {BinaryBool}
     * @memberof ApplicationBody
     */
    creditCardStatus?: BinaryBool;
    /**
     * 
     * @type {BinaryBool}
     * @memberof ApplicationBody
     */
    mastercardStatus?: BinaryBool;
    /**
     * 
     * @type {BinaryBool}
     * @memberof ApplicationBody
     */
    citizenship?: BinaryBool;
    /**
     * 
     * @type {BinaryBool}
     * @memberof ApplicationBody
     */
    coCitizenship?: BinaryBool;
    /**
     * 
     * @type {Permit}
     * @memberof ApplicationBody
     */
    permitToStay?: Permit;
    /**
     * 
     * @type {Permit}
     * @memberof ApplicationBody
     */
    coPermitToStay?: Permit;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    permitTypeTemporary?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    coPermitTypeTemporary?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    permitNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    coPermitNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    permitTypePermanent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    coPermitTypePermanent?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    permitForeignerNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    coPermitForeignerNumber?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationBody
     */
    permitExpiryDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationBody
     */
    coPermitExpiryDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationBody
     */
    livedSinceDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    bankSinceYear?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    creditPaymentMethod?: number;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    creditPaymentBankCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    creditPaymentAccountNumber?: string | null;
    /**
     * 
     * @type {BinaryBool}
     * @memberof ApplicationBody
     */
    creditLookupConsentStatus?: BinaryBool;
    /**
     * 
     * @type {BinaryBool}
     * @memberof ApplicationBody
     */
    coCreditLookupConsentStatus?: BinaryBool;
    /**
     * 
     * @type {Date}
     * @memberof ApplicationBody
     */
    jobInsuranceSince?: Date;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    ownVacationHouse?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    householdOtherLoanRest?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    householdOtherLoanExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    readonly citizenshipPermit?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    readonly otherIncome?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    childSupport?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    childExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    houseSupport?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    houseRentalAmount?: number;
    /**
     * 
     * @type {CitizenshipCategory}
     * @memberof ApplicationBody
     */
    citizenshipCategory?: CitizenshipCategory;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationBody
     */
    aseConsent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationBody
     */
    isDone?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    pid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    coPid?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    householdShare?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationBody
     */
    oetAccept?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationBody
     */
    isCarLoan?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ApplicationBody
     */
    ancestralHome?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    carPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    carPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    carPaymentPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicationBody
     */
    carYear?: number;
}

export function ApplicationBodyFromJSON(json: any): ApplicationBody {
    return ApplicationBodyFromJSONTyped(json, false);
}

export function ApplicationBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isSamleLaan': !exists(json, 'isSamleLaan') ? undefined : json['isSamleLaan'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'acceptMail': !exists(json, 'acceptMail') ? undefined : json['acceptMail'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'foreName': !exists(json, 'foreName') ? undefined : json['foreName'],
        'surName': !exists(json, 'surName') ? undefined : json['surName'],
        'loanAmount': !exists(json, 'loanAmount') ? undefined : json['loanAmount'],
        'durationYears': !exists(json, 'durationYears') ? undefined : json['durationYears'],
        'loanReason': !exists(json, 'loanReason') ? undefined : json['loanReason'],
        'otherReason': !exists(json, 'otherReason') ? undefined : json['otherReason'],
        'cpr': !exists(json, 'cpr') ? undefined : json['cpr'],
        'samleLaan': !exists(json, 'samleLaan') ? undefined : (json['samleLaan'] === null ? null : (json['samleLaan'] as Array<any>).map(SamleLaanFromJSON)),
        'step': !exists(json, 'step') ? undefined : json['step'],
        'coForeName': !exists(json, 'coForeName') ? undefined : json['coForeName'],
        'coSurName': !exists(json, 'coSurName') ? undefined : json['coSurName'],
        'coEmail': !exists(json, 'coEmail') ? undefined : json['coEmail'],
        'coCpr': !exists(json, 'coCpr') ? undefined : json['coCpr'],
        'maritalStatus': !exists(json, 'maritalStatus') ? undefined : json['maritalStatus'],
        'rent': !exists(json, 'rent') ? undefined : json['rent'],
        'ownerExpense': !exists(json, 'ownerExpense') ? undefined : json['ownerExpense'],
        'rkiMember': !exists(json, 'rkiMember') ? undefined : json['rkiMember'],
        'dwelling': !exists(json, 'dwelling') ? undefined : json['dwelling'],
        'children': !exists(json, 'children') ? undefined : (json['children'] === null ? null : (json['children'] as Array<any>).map(ChildFromJSON)),
        'coApplicant': !exists(json, 'coApplicant') ? undefined : json['coApplicant'],
        'marketing': !exists(json, 'marketing') ? undefined : json['marketing'],
        'creditCheck': !exists(json, 'creditCheck') ? undefined : json['creditCheck'],
        'eSkatConsent': !exists(json, 'eSkatConsent') ? undefined : json['eSkatConsent'],
        'insurance': !exists(json, 'insurance') ? undefined : json['insurance'],
        'cars': !exists(json, 'cars') ? undefined : (json['cars'] === null ? null : (json['cars'] as Array<any>).map(CarFromJSON)),
        'hasOldLoan': !exists(json, 'hasOldLoan') ? undefined : BinaryBoolFromJSON(json['hasOldLoan']),
        'sharedExpenses': !exists(json, 'sharedExpenses') ? undefined : BinaryBoolFromJSON(json['sharedExpenses']),
        'coPhoneNumber': !exists(json, 'coPhoneNumber') ? undefined : json['coPhoneNumber'],
        'addressSince': !exists(json, 'addressSince') ? undefined : (new Date(json['addressSince'])),
        'addressSinceMonth': !exists(json, 'addressSinceMonth') ? undefined : json['addressSinceMonth'],
        'addressSinceYear': !exists(json, 'addressSinceYear') ? undefined : json['addressSinceYear'],
        'householdHeatingExpense': !exists(json, 'householdHeatingExpense') ? undefined : json['householdHeatingExpense'],
        'householdUtilitiesExpense': !exists(json, 'householdUtilitiesExpense') ? undefined : json['householdUtilitiesExpense'],
        'householdDigitalMediaExpense': !exists(json, 'householdDigitalMediaExpense') ? undefined : json['householdDigitalMediaExpense'],
        'householdInsurance': !exists(json, 'householdInsurance') ? undefined : json['householdInsurance'],
        'householdTransportationOtherExpense': !exists(json, 'householdTransportationOtherExpense') ? undefined : json['householdTransportationOtherExpense'],
        'employmentType': !exists(json, 'employmentType') ? undefined : EmploymentTypeFromJSON(json['employmentType']),
        'coEmploymentType': !exists(json, 'coEmploymentType') ? undefined : EmploymentTypeFromJSON(json['coEmploymentType']),
        'employerIdentificationNumber': !exists(json, 'employerIdentificationNumber') ? undefined : json['employerIdentificationNumber'],
        'employmentSinceDate': !exists(json, 'employmentSinceDate') ? undefined : (new Date(json['employmentSinceDate'])),
        'coEmploymentSinceDate': !exists(json, 'coEmploymentSinceDate') ? undefined : (new Date(json['coEmploymentSinceDate'])),
        'jobInsuranceStatus': !exists(json, 'jobInsuranceStatus') ? undefined : BinaryBoolFromJSON(json['jobInsuranceStatus']),
        'coJobInsuranceStatus': !exists(json, 'coJobInsuranceStatus') ? undefined : BinaryBoolFromJSON(json['coJobInsuranceStatus']),
        'incomeGrossAmount': !exists(json, 'incomeGrossAmount') ? undefined : json['incomeGrossAmount'],
        'coIncomeGrossAmount': !exists(json, 'coIncomeGrossAmount') ? undefined : json['coIncomeGrossAmount'],
        'incomeNetAmount': !exists(json, 'incomeNetAmount') ? undefined : json['incomeNetAmount'],
        'coIncomeNetAmount': !exists(json, 'coIncomeNetAmount') ? undefined : json['coIncomeNetAmount'],
        'incomeOtherType': !exists(json, 'incomeOtherType') ? undefined : json['incomeOtherType'],
        'coIncomeOtherType': !exists(json, 'coIncomeOtherType') ? undefined : json['coIncomeOtherType'],
        'incomeOtherAmount': !exists(json, 'incomeOtherAmount') ? undefined : json['incomeOtherAmount'],
        'coIncomeOtherAmount': !exists(json, 'coIncomeOtherAmount') ? undefined : json['coIncomeOtherAmount'],
        'householdDiscretionaryIncome': !exists(json, 'householdDiscretionaryIncome') ? undefined : json['householdDiscretionaryIncome'],
        'studyLoanRest': !exists(json, 'studyLoanRest') ? undefined : json['studyLoanRest'],
        'studyLoanExpense': !exists(json, 'studyLoanExpense') ? undefined : json['studyLoanExpense'],
        'carLoanRest': !exists(json, 'carLoanRest') ? undefined : json['carLoanRest'],
        'carLoanExpense': !exists(json, 'carLoanExpense') ? undefined : json['carLoanExpense'],
        'consumerLoanRest': !exists(json, 'consumerLoanRest') ? undefined : json['consumerLoanRest'],
        'consumerLoanExpense': !exists(json, 'consumerLoanExpense') ? undefined : json['consumerLoanExpense'],
        'otherDebt': !exists(json, 'otherDebt') ? undefined : json['otherDebt'],
        'otherDebtExpense': !exists(json, 'otherDebtExpense') ? undefined : json['otherDebtExpense'],
        'publicDebtExpense': !exists(json, 'publicDebtExpense') ? undefined : json['publicDebtExpense'],
        'publicDebtRest': !exists(json, 'publicDebtRest') ? undefined : json['publicDebtRest'],
        'debtSinceLastTexReport': !exists(json, 'debtSinceLastTexReport') ? undefined : json['debtSinceLastTexReport'],
        'coDebtSinceLastTaxReport': !exists(json, 'coDebtSinceLastTaxReport') ? undefined : json['coDebtSinceLastTaxReport'],
        'creditCardStatus': !exists(json, 'creditCardStatus') ? undefined : BinaryBoolFromJSON(json['creditCardStatus']),
        'mastercardStatus': !exists(json, 'mastercardStatus') ? undefined : BinaryBoolFromJSON(json['mastercardStatus']),
        'citizenship': !exists(json, 'citizenship') ? undefined : BinaryBoolFromJSON(json['citizenship']),
        'coCitizenship': !exists(json, 'coCitizenship') ? undefined : BinaryBoolFromJSON(json['coCitizenship']),
        'permitToStay': !exists(json, 'permitToStay') ? undefined : PermitFromJSON(json['permitToStay']),
        'coPermitToStay': !exists(json, 'coPermitToStay') ? undefined : PermitFromJSON(json['coPermitToStay']),
        'permitTypeTemporary': !exists(json, 'permitTypeTemporary') ? undefined : json['permitTypeTemporary'],
        'coPermitTypeTemporary': !exists(json, 'coPermitTypeTemporary') ? undefined : json['coPermitTypeTemporary'],
        'permitNumber': !exists(json, 'permitNumber') ? undefined : json['permitNumber'],
        'coPermitNumber': !exists(json, 'coPermitNumber') ? undefined : json['coPermitNumber'],
        'permitTypePermanent': !exists(json, 'permitTypePermanent') ? undefined : json['permitTypePermanent'],
        'coPermitTypePermanent': !exists(json, 'coPermitTypePermanent') ? undefined : json['coPermitTypePermanent'],
        'permitForeignerNumber': !exists(json, 'permitForeignerNumber') ? undefined : json['permitForeignerNumber'],
        'coPermitForeignerNumber': !exists(json, 'coPermitForeignerNumber') ? undefined : json['coPermitForeignerNumber'],
        'permitExpiryDate': !exists(json, 'permitExpiryDate') ? undefined : (new Date(json['permitExpiryDate'])),
        'coPermitExpiryDate': !exists(json, 'coPermitExpiryDate') ? undefined : (new Date(json['coPermitExpiryDate'])),
        'livedSinceDate': !exists(json, 'livedSinceDate') ? undefined : (new Date(json['livedSinceDate'])),
        'bankSinceYear': !exists(json, 'bankSinceYear') ? undefined : json['bankSinceYear'],
        'creditPaymentMethod': !exists(json, 'creditPaymentMethod') ? undefined : json['creditPaymentMethod'],
        'creditPaymentBankCode': !exists(json, 'creditPaymentBankCode') ? undefined : json['creditPaymentBankCode'],
        'creditPaymentAccountNumber': !exists(json, 'creditPaymentAccountNumber') ? undefined : json['creditPaymentAccountNumber'],
        'creditLookupConsentStatus': !exists(json, 'creditLookupConsentStatus') ? undefined : BinaryBoolFromJSON(json['creditLookupConsentStatus']),
        'coCreditLookupConsentStatus': !exists(json, 'coCreditLookupConsentStatus') ? undefined : BinaryBoolFromJSON(json['coCreditLookupConsentStatus']),
        'jobInsuranceSince': !exists(json, 'jobInsuranceSince') ? undefined : (new Date(json['jobInsuranceSince'])),
        'ownVacationHouse': !exists(json, 'ownVacationHouse') ? undefined : json['ownVacationHouse'],
        'householdOtherLoanRest': !exists(json, 'householdOtherLoanRest') ? undefined : json['householdOtherLoanRest'],
        'householdOtherLoanExpense': !exists(json, 'householdOtherLoanExpense') ? undefined : json['householdOtherLoanExpense'],
        'citizenshipPermit': !exists(json, 'citizenshipPermit') ? undefined : json['citizenshipPermit'],
        'otherIncome': !exists(json, 'otherIncome') ? undefined : json['otherIncome'],
        'childSupport': !exists(json, 'childSupport') ? undefined : json['childSupport'],
        'childExpense': !exists(json, 'childExpense') ? undefined : json['childExpense'],
        'houseSupport': !exists(json, 'houseSupport') ? undefined : json['houseSupport'],
        'houseRentalAmount': !exists(json, 'houseRentalAmount') ? undefined : json['houseRentalAmount'],
        'citizenshipCategory': !exists(json, 'citizenshipCategory') ? undefined : CitizenshipCategoryFromJSON(json['citizenshipCategory']),
        'aseConsent': !exists(json, 'aseConsent') ? undefined : json['aseConsent'],
        'isDone': !exists(json, 'isDone') ? undefined : json['isDone'],
        'pid': !exists(json, 'pid') ? undefined : json['pid'],
        'coPid': !exists(json, 'coPid') ? undefined : json['coPid'],
        'householdShare': !exists(json, 'householdShare') ? undefined : json['householdShare'],
        'oetAccept': !exists(json, 'oetAccept') ? undefined : json['oetAccept'],
        'isCarLoan': !exists(json, 'isCarLoan') ? undefined : json['isCarLoan'],
        'ancestralHome': !exists(json, 'ancestralHome') ? undefined : json['ancestralHome'],
        'carPrice': !exists(json, 'carPrice') ? undefined : json['carPrice'],
        'carPayment': !exists(json, 'carPayment') ? undefined : json['carPayment'],
        'carPaymentPercentage': !exists(json, 'carPaymentPercentage') ? undefined : json['carPaymentPercentage'],
        'carYear': !exists(json, 'carYear') ? undefined : json['carYear'],
    };
}

export function ApplicationBodyToJSON(value?: ApplicationBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isSamleLaan': value.isSamleLaan,
        'email': value.email,
        'acceptMail': value.acceptMail,
        'phoneNumber': value.phoneNumber,
        'foreName': value.foreName,
        'surName': value.surName,
        'loanAmount': value.loanAmount,
        'durationYears': value.durationYears,
        'loanReason': value.loanReason,
        'otherReason': value.otherReason,
        'cpr': value.cpr,
        'samleLaan': value.samleLaan === undefined ? undefined : (value.samleLaan === null ? null : (value.samleLaan as Array<any>).map(SamleLaanToJSON)),
        'step': value.step,
        'coForeName': value.coForeName,
        'coSurName': value.coSurName,
        'coEmail': value.coEmail,
        'coCpr': value.coCpr,
        'maritalStatus': value.maritalStatus,
        'rent': value.rent,
        'ownerExpense': value.ownerExpense,
        'rkiMember': value.rkiMember,
        'dwelling': value.dwelling,
        'children': value.children === undefined ? undefined : (value.children === null ? null : (value.children as Array<any>).map(ChildToJSON)),
        'coApplicant': value.coApplicant,
        'marketing': value.marketing,
        'creditCheck': value.creditCheck,
        'eSkatConsent': value.eSkatConsent,
        'insurance': value.insurance,
        'cars': value.cars === undefined ? undefined : (value.cars === null ? null : (value.cars as Array<any>).map(CarToJSON)),
        'hasOldLoan': BinaryBoolToJSON(value.hasOldLoan),
        'sharedExpenses': BinaryBoolToJSON(value.sharedExpenses),
        'coPhoneNumber': value.coPhoneNumber,
        'addressSince': value.addressSince === undefined ? undefined : (value.addressSince.toISOString()),
        'addressSinceMonth': value.addressSinceMonth,
        'addressSinceYear': value.addressSinceYear,
        'householdHeatingExpense': value.householdHeatingExpense,
        'householdUtilitiesExpense': value.householdUtilitiesExpense,
        'householdDigitalMediaExpense': value.householdDigitalMediaExpense,
        'householdInsurance': value.householdInsurance,
        'householdTransportationOtherExpense': value.householdTransportationOtherExpense,
        'employmentType': EmploymentTypeToJSON(value.employmentType),
        'coEmploymentType': EmploymentTypeToJSON(value.coEmploymentType),
        'employerIdentificationNumber': value.employerIdentificationNumber,
        'employmentSinceDate': value.employmentSinceDate === undefined ? undefined : (value.employmentSinceDate.toISOString()),
        'coEmploymentSinceDate': value.coEmploymentSinceDate === undefined ? undefined : (value.coEmploymentSinceDate.toISOString()),
        'jobInsuranceStatus': BinaryBoolToJSON(value.jobInsuranceStatus),
        'coJobInsuranceStatus': BinaryBoolToJSON(value.coJobInsuranceStatus),
        'incomeGrossAmount': value.incomeGrossAmount,
        'coIncomeGrossAmount': value.coIncomeGrossAmount,
        'incomeNetAmount': value.incomeNetAmount,
        'coIncomeNetAmount': value.coIncomeNetAmount,
        'incomeOtherType': value.incomeOtherType,
        'coIncomeOtherType': value.coIncomeOtherType,
        'incomeOtherAmount': value.incomeOtherAmount,
        'coIncomeOtherAmount': value.coIncomeOtherAmount,
        'householdDiscretionaryIncome': value.householdDiscretionaryIncome,
        'studyLoanRest': value.studyLoanRest,
        'studyLoanExpense': value.studyLoanExpense,
        'carLoanRest': value.carLoanRest,
        'carLoanExpense': value.carLoanExpense,
        'consumerLoanRest': value.consumerLoanRest,
        'consumerLoanExpense': value.consumerLoanExpense,
        'otherDebt': value.otherDebt,
        'otherDebtExpense': value.otherDebtExpense,
        'publicDebtExpense': value.publicDebtExpense,
        'publicDebtRest': value.publicDebtRest,
        'debtSinceLastTexReport': value.debtSinceLastTexReport,
        'coDebtSinceLastTaxReport': value.coDebtSinceLastTaxReport,
        'creditCardStatus': BinaryBoolToJSON(value.creditCardStatus),
        'mastercardStatus': BinaryBoolToJSON(value.mastercardStatus),
        'citizenship': BinaryBoolToJSON(value.citizenship),
        'coCitizenship': BinaryBoolToJSON(value.coCitizenship),
        'permitToStay': PermitToJSON(value.permitToStay),
        'coPermitToStay': PermitToJSON(value.coPermitToStay),
        'permitTypeTemporary': value.permitTypeTemporary,
        'coPermitTypeTemporary': value.coPermitTypeTemporary,
        'permitNumber': value.permitNumber,
        'coPermitNumber': value.coPermitNumber,
        'permitTypePermanent': value.permitTypePermanent,
        'coPermitTypePermanent': value.coPermitTypePermanent,
        'permitForeignerNumber': value.permitForeignerNumber,
        'coPermitForeignerNumber': value.coPermitForeignerNumber,
        'permitExpiryDate': value.permitExpiryDate === undefined ? undefined : (value.permitExpiryDate.toISOString()),
        'coPermitExpiryDate': value.coPermitExpiryDate === undefined ? undefined : (value.coPermitExpiryDate.toISOString()),
        'livedSinceDate': value.livedSinceDate === undefined ? undefined : (value.livedSinceDate.toISOString()),
        'bankSinceYear': value.bankSinceYear,
        'creditPaymentMethod': value.creditPaymentMethod,
        'creditPaymentBankCode': value.creditPaymentBankCode,
        'creditPaymentAccountNumber': value.creditPaymentAccountNumber,
        'creditLookupConsentStatus': BinaryBoolToJSON(value.creditLookupConsentStatus),
        'coCreditLookupConsentStatus': BinaryBoolToJSON(value.coCreditLookupConsentStatus),
        'jobInsuranceSince': value.jobInsuranceSince === undefined ? undefined : (value.jobInsuranceSince.toISOString()),
        'ownVacationHouse': value.ownVacationHouse,
        'householdOtherLoanRest': value.householdOtherLoanRest,
        'householdOtherLoanExpense': value.householdOtherLoanExpense,
        'childSupport': value.childSupport,
        'childExpense': value.childExpense,
        'houseSupport': value.houseSupport,
        'houseRentalAmount': value.houseRentalAmount,
        'citizenshipCategory': CitizenshipCategoryToJSON(value.citizenshipCategory),
        'aseConsent': value.aseConsent,
        'isDone': value.isDone,
        'pid': value.pid,
        'coPid': value.coPid,
        'householdShare': value.householdShare,
        'oetAccept': value.oetAccept,
        'isCarLoan': value.isCarLoan,
        'ancestralHome': value.ancestralHome,
        'carPrice': value.carPrice,
        'carPayment': value.carPayment,
        'carPaymentPercentage': value.carPaymentPercentage,
        'carYear': value.carYear,
    };
}


