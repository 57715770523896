/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface YdelseResponse
 */
export interface YdelseResponse {
    /**
     * 
     * @type {number}
     * @memberof YdelseResponse
     */
    ydelse?: number;
    /**
     * 
     * @type {number}
     * @memberof YdelseResponse
     */
    startupCost?: number;
}

export function YdelseResponseFromJSON(json: any): YdelseResponse {
    return YdelseResponseFromJSONTyped(json, false);
}

export function YdelseResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): YdelseResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ydelse': !exists(json, 'ydelse') ? undefined : json['ydelse'],
        'startupCost': !exists(json, 'startupCost') ? undefined : json['startupCost'],
    };
}

export function YdelseResponseToJSON(value?: YdelseResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ydelse': value.ydelse,
        'startupCost': value.startupCost,
    };
}


