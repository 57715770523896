/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NEMIDResponse,
    NEMIDResponseFromJSON,
    NEMIDResponseToJSON,
} from '../models';

export interface ApiNemidValidatePidPutRequest {
    cpr?: string | null;
    coApplicant?: boolean;
    coCpr?: string | null;
    body?: string | null;
}

/**
 * 
 */
export class NemidApi extends runtime.BaseAPI {

    /**
     */
    async apiNemidGenerateJsonPostRaw(): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/nemid/GenerateJson`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiNemidGenerateJsonPost(): Promise<string> {
        const response = await this.apiNemidGenerateJsonPostRaw();
        return await response.value();
    }

    /**
     */
    async apiNemidValidatePidPutRaw(requestParameters: ApiNemidValidatePidPutRequest): Promise<runtime.ApiResponse<NEMIDResponse>> {
        const queryParameters: any = {};

        if (requestParameters.cpr !== undefined) {
            queryParameters['cpr'] = requestParameters.cpr;
        }

        if (requestParameters.coApplicant !== undefined) {
            queryParameters['coApplicant'] = requestParameters.coApplicant;
        }

        if (requestParameters.coCpr !== undefined) {
            queryParameters['coCpr'] = requestParameters.coCpr;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/nemid/ValidatePid`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NEMIDResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiNemidValidatePidPut(requestParameters: ApiNemidValidatePidPutRequest): Promise<NEMIDResponse> {
        const response = await this.apiNemidValidatePidPutRaw(requestParameters);
        return await response.value();
    }

}
