/* tslint:disable */
/* eslint-disable */
/**
 * MorebankerDK API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BinaryBool,
    BinaryBoolFromJSON,
    BinaryBoolFromJSONTyped,
    BinaryBoolToJSON,
    DbApplicant,
    DbApplicantFromJSON,
    DbApplicantFromJSONTyped,
    DbApplicantToJSON,
    DbApplicationCarInfo,
    DbApplicationCarInfoFromJSON,
    DbApplicationCarInfoFromJSONTyped,
    DbApplicationCarInfoToJSON,
    DbBank,
    DbBankFromJSON,
    DbBankFromJSONTyped,
    DbBankToJSON,
} from './';

/**
 * 
 * @export
 * @interface DbApplication
 */
export interface DbApplication {
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof DbApplication
     */
    created?: Date;
    /**
     * 
     * @type {DbApplicant}
     * @memberof DbApplication
     */
    applicant?: DbApplicant;
    /**
     * 
     * @type {DbApplicant}
     * @memberof DbApplication
     */
    coApplicant?: DbApplicant;
    /**
     * 
     * @type {DbApplicationCarInfo}
     * @memberof DbApplication
     */
    carInfo?: DbApplicationCarInfo;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    loanAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    durationMonths?: number;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    reasonOfLoan?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DbApplication
     */
    coidSynced?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DbApplication
     */
    lastStatusCheck?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof DbApplication
     */
    accepted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DbApplication
     */
    sibSynced?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DbApplication
     */
    sibSignedUp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DbApplication
     */
    isFinished?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    clientId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    cpr?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    nrOfCars?: number;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    payOutAmount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DbApplication
     */
    gaSynced?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    utmSource?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    utmMedium?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    utmCampaign?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    heylinkId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    referrer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    publisher?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    glid?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    martialStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    dwelling?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    housingExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    houseHoldHeatingExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    householdUtilitiesExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    householdDigitalMediaExpense?: number;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    ownVacationHouse?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    nrOfChildren?: number;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    rkiMember?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    discretionaryIncome?: number;
    /**
     * 
     * @type {BinaryBool}
     * @memberof DbApplication
     */
    creditCardStatus?: BinaryBool;
    /**
     * 
     * @type {BinaryBool}
     * @memberof DbApplication
     */
    mastercard?: BinaryBool;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    insurance?: number;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    transportationOtherExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    otherLoan?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    otherLoanExpense?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    carLoan?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    carLoanExpense?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    studyLoan?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    studyLoanExpense?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    consumerLoan?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    consumerLoanExpense?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    publicDebt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    publicDebtExpense?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    otherDebt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    otherDebtExpense?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    bankSinceYear?: number;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    coApplicantId?: string;
    /**
     * 
     * @type {Date}
     * @memberof DbApplication
     */
    addressSince?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DbApplication
     */
    jobInsuranceSince?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DbApplication
     */
    livedSinceDate?: Date | null;
    /**
     * 
     * @type {Array<DbBank>}
     * @memberof DbApplication
     */
    banks?: Array<DbBank> | null;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    step?: number;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    childSupport?: number;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    childExpense?: number;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    houseSupport?: number;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    cvr?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    otherReason?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DbApplication
     */
    active?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof DbApplication
     */
    completionTime?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DbApplication
     */
    lastUpdated?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    startLandingPage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    ipAddress?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DbApplication
     */
    marketingConsent?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    oldLoanAmount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    transactionId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DbApplication
     */
    transactionIdSynced?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    fbclid?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DbApplication
     */
    addToCartSynced?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DbApplication
     */
    householdShare?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DbApplication
     */
    oetAccept?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DbApplication
     */
    isCarLoan?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DbApplication
     */
    ancestralHome?: string | null;
}

export function DbApplicationFromJSON(json: any): DbApplication {
    return DbApplicationFromJSONTyped(json, false);
}

export function DbApplicationFromJSONTyped(json: any, ignoreDiscriminator: boolean): DbApplication {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'created': !exists(json, 'created') ? undefined : (new Date(json['created'])),
        'applicant': !exists(json, 'applicant') ? undefined : DbApplicantFromJSON(json['applicant']),
        'coApplicant': !exists(json, 'coApplicant') ? undefined : DbApplicantFromJSON(json['coApplicant']),
        'carInfo': !exists(json, 'carInfo') ? undefined : DbApplicationCarInfoFromJSON(json['carInfo']),
        'loanAmount': !exists(json, 'loanAmount') ? undefined : json['loanAmount'],
        'durationMonths': !exists(json, 'durationMonths') ? undefined : json['durationMonths'],
        'reasonOfLoan': !exists(json, 'reasonOfLoan') ? undefined : json['reasonOfLoan'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'coidSynced': !exists(json, 'coidSynced') ? undefined : json['coidSynced'],
        'lastStatusCheck': !exists(json, 'lastStatusCheck') ? undefined : (json['lastStatusCheck'] === null ? null : new Date(json['lastStatusCheck'])),
        'accepted': !exists(json, 'accepted') ? undefined : json['accepted'],
        'sibSynced': !exists(json, 'sibSynced') ? undefined : json['sibSynced'],
        'sibSignedUp': !exists(json, 'sibSignedUp') ? undefined : json['sibSignedUp'],
        'isFinished': !exists(json, 'isFinished') ? undefined : json['isFinished'],
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'cpr': !exists(json, 'cpr') ? undefined : json['cpr'],
        'nrOfCars': !exists(json, 'nrOfCars') ? undefined : json['nrOfCars'],
        'payOutAmount': !exists(json, 'payOutAmount') ? undefined : json['payOutAmount'],
        'gaSynced': !exists(json, 'gaSynced') ? undefined : json['gaSynced'],
        'utmSource': !exists(json, 'utmSource') ? undefined : json['utmSource'],
        'utmMedium': !exists(json, 'utmMedium') ? undefined : json['utmMedium'],
        'utmCampaign': !exists(json, 'utmCampaign') ? undefined : json['utmCampaign'],
        'heylinkId': !exists(json, 'heylinkId') ? undefined : json['heylinkId'],
        'referrer': !exists(json, 'referrer') ? undefined : json['referrer'],
        'publisher': !exists(json, 'publisher') ? undefined : json['publisher'],
        'glid': !exists(json, 'glid') ? undefined : json['glid'],
        'martialStatus': !exists(json, 'martialStatus') ? undefined : json['martialStatus'],
        'dwelling': !exists(json, 'dwelling') ? undefined : json['dwelling'],
        'housingExpense': !exists(json, 'housingExpense') ? undefined : json['housingExpense'],
        'houseHoldHeatingExpense': !exists(json, 'houseHoldHeatingExpense') ? undefined : json['houseHoldHeatingExpense'],
        'householdUtilitiesExpense': !exists(json, 'householdUtilitiesExpense') ? undefined : json['householdUtilitiesExpense'],
        'householdDigitalMediaExpense': !exists(json, 'householdDigitalMediaExpense') ? undefined : json['householdDigitalMediaExpense'],
        'ownVacationHouse': !exists(json, 'ownVacationHouse') ? undefined : json['ownVacationHouse'],
        'nrOfChildren': !exists(json, 'nrOfChildren') ? undefined : json['nrOfChildren'],
        'rkiMember': !exists(json, 'rkiMember') ? undefined : json['rkiMember'],
        'discretionaryIncome': !exists(json, 'discretionaryIncome') ? undefined : json['discretionaryIncome'],
        'creditCardStatus': !exists(json, 'creditCardStatus') ? undefined : BinaryBoolFromJSON(json['creditCardStatus']),
        'mastercard': !exists(json, 'mastercard') ? undefined : BinaryBoolFromJSON(json['mastercard']),
        'insurance': !exists(json, 'insurance') ? undefined : json['insurance'],
        'transportationOtherExpense': !exists(json, 'transportationOtherExpense') ? undefined : json['transportationOtherExpense'],
        'otherLoan': !exists(json, 'otherLoan') ? undefined : json['otherLoan'],
        'otherLoanExpense': !exists(json, 'otherLoanExpense') ? undefined : json['otherLoanExpense'],
        'carLoan': !exists(json, 'carLoan') ? undefined : json['carLoan'],
        'carLoanExpense': !exists(json, 'carLoanExpense') ? undefined : json['carLoanExpense'],
        'studyLoan': !exists(json, 'studyLoan') ? undefined : json['studyLoan'],
        'studyLoanExpense': !exists(json, 'studyLoanExpense') ? undefined : json['studyLoanExpense'],
        'consumerLoan': !exists(json, 'consumerLoan') ? undefined : json['consumerLoan'],
        'consumerLoanExpense': !exists(json, 'consumerLoanExpense') ? undefined : json['consumerLoanExpense'],
        'publicDebt': !exists(json, 'publicDebt') ? undefined : json['publicDebt'],
        'publicDebtExpense': !exists(json, 'publicDebtExpense') ? undefined : json['publicDebtExpense'],
        'otherDebt': !exists(json, 'otherDebt') ? undefined : json['otherDebt'],
        'otherDebtExpense': !exists(json, 'otherDebtExpense') ? undefined : json['otherDebtExpense'],
        'bankSinceYear': !exists(json, 'bankSinceYear') ? undefined : json['bankSinceYear'],
        'coApplicantId': !exists(json, 'coApplicantId') ? undefined : json['coApplicantId'],
        'addressSince': !exists(json, 'addressSince') ? undefined : (new Date(json['addressSince'])),
        'jobInsuranceSince': !exists(json, 'jobInsuranceSince') ? undefined : (new Date(json['jobInsuranceSince'])),
        'livedSinceDate': !exists(json, 'livedSinceDate') ? undefined : (json['livedSinceDate'] === null ? null : new Date(json['livedSinceDate'])),
        'banks': !exists(json, 'banks') ? undefined : (json['banks'] === null ? null : (json['banks'] as Array<any>).map(DbBankFromJSON)),
        'step': !exists(json, 'step') ? undefined : json['step'],
        'childSupport': !exists(json, 'childSupport') ? undefined : json['childSupport'],
        'childExpense': !exists(json, 'childExpense') ? undefined : json['childExpense'],
        'houseSupport': !exists(json, 'houseSupport') ? undefined : json['houseSupport'],
        'cvr': !exists(json, 'cvr') ? undefined : json['cvr'],
        'otherReason': !exists(json, 'otherReason') ? undefined : json['otherReason'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'completionTime': !exists(json, 'completionTime') ? undefined : (json['completionTime'] === null ? null : new Date(json['completionTime'])),
        'lastUpdated': !exists(json, 'lastUpdated') ? undefined : (json['lastUpdated'] === null ? null : new Date(json['lastUpdated'])),
        'startLandingPage': !exists(json, 'startLandingPage') ? undefined : json['startLandingPage'],
        'ipAddress': !exists(json, 'ipAddress') ? undefined : json['ipAddress'],
        'marketingConsent': !exists(json, 'marketingConsent') ? undefined : json['marketingConsent'],
        'oldLoanAmount': !exists(json, 'oldLoanAmount') ? undefined : json['oldLoanAmount'],
        'transactionId': !exists(json, 'transactionId') ? undefined : json['transactionId'],
        'transactionIdSynced': !exists(json, 'transactionIdSynced') ? undefined : json['transactionIdSynced'],
        'fbclid': !exists(json, 'fbclid') ? undefined : json['fbclid'],
        'addToCartSynced': !exists(json, 'addToCartSynced') ? undefined : json['addToCartSynced'],
        'householdShare': !exists(json, 'householdShare') ? undefined : json['householdShare'],
        'oetAccept': !exists(json, 'oetAccept') ? undefined : json['oetAccept'],
        'isCarLoan': !exists(json, 'isCarLoan') ? undefined : json['isCarLoan'],
        'ancestralHome': !exists(json, 'ancestralHome') ? undefined : json['ancestralHome'],
    };
}

export function DbApplicationToJSON(value?: DbApplication | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'created': value.created === undefined ? undefined : (value.created.toISOString()),
        'applicant': DbApplicantToJSON(value.applicant),
        'coApplicant': DbApplicantToJSON(value.coApplicant),
        'carInfo': DbApplicationCarInfoToJSON(value.carInfo),
        'loanAmount': value.loanAmount,
        'durationMonths': value.durationMonths,
        'reasonOfLoan': value.reasonOfLoan,
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'name': value.name,
        'coidSynced': value.coidSynced,
        'lastStatusCheck': value.lastStatusCheck === undefined ? undefined : (value.lastStatusCheck === null ? null : value.lastStatusCheck.toISOString()),
        'accepted': value.accepted,
        'sibSynced': value.sibSynced,
        'sibSignedUp': value.sibSignedUp,
        'isFinished': value.isFinished,
        'clientId': value.clientId,
        'cpr': value.cpr,
        'nrOfCars': value.nrOfCars,
        'payOutAmount': value.payOutAmount,
        'gaSynced': value.gaSynced,
        'utmSource': value.utmSource,
        'utmMedium': value.utmMedium,
        'utmCampaign': value.utmCampaign,
        'heylinkId': value.heylinkId,
        'referrer': value.referrer,
        'publisher': value.publisher,
        'glid': value.glid,
        'martialStatus': value.martialStatus,
        'dwelling': value.dwelling,
        'housingExpense': value.housingExpense,
        'houseHoldHeatingExpense': value.houseHoldHeatingExpense,
        'householdUtilitiesExpense': value.householdUtilitiesExpense,
        'householdDigitalMediaExpense': value.householdDigitalMediaExpense,
        'ownVacationHouse': value.ownVacationHouse,
        'nrOfChildren': value.nrOfChildren,
        'rkiMember': value.rkiMember,
        'discretionaryIncome': value.discretionaryIncome,
        'creditCardStatus': BinaryBoolToJSON(value.creditCardStatus),
        'mastercard': BinaryBoolToJSON(value.mastercard),
        'insurance': value.insurance,
        'transportationOtherExpense': value.transportationOtherExpense,
        'otherLoan': value.otherLoan,
        'otherLoanExpense': value.otherLoanExpense,
        'carLoan': value.carLoan,
        'carLoanExpense': value.carLoanExpense,
        'studyLoan': value.studyLoan,
        'studyLoanExpense': value.studyLoanExpense,
        'consumerLoan': value.consumerLoan,
        'consumerLoanExpense': value.consumerLoanExpense,
        'publicDebt': value.publicDebt,
        'publicDebtExpense': value.publicDebtExpense,
        'otherDebt': value.otherDebt,
        'otherDebtExpense': value.otherDebtExpense,
        'bankSinceYear': value.bankSinceYear,
        'coApplicantId': value.coApplicantId,
        'addressSince': value.addressSince === undefined ? undefined : (value.addressSince.toISOString()),
        'jobInsuranceSince': value.jobInsuranceSince === undefined ? undefined : (value.jobInsuranceSince.toISOString()),
        'livedSinceDate': value.livedSinceDate === undefined ? undefined : (value.livedSinceDate === null ? null : value.livedSinceDate.toISOString()),
        'banks': value.banks === undefined ? undefined : (value.banks === null ? null : (value.banks as Array<any>).map(DbBankToJSON)),
        'step': value.step,
        'childSupport': value.childSupport,
        'childExpense': value.childExpense,
        'houseSupport': value.houseSupport,
        'cvr': value.cvr,
        'otherReason': value.otherReason,
        'active': value.active,
        'completionTime': value.completionTime === undefined ? undefined : (value.completionTime === null ? null : value.completionTime.toISOString()),
        'lastUpdated': value.lastUpdated === undefined ? undefined : (value.lastUpdated === null ? null : value.lastUpdated.toISOString()),
        'startLandingPage': value.startLandingPage,
        'ipAddress': value.ipAddress,
        'marketingConsent': value.marketingConsent,
        'oldLoanAmount': value.oldLoanAmount,
        'transactionId': value.transactionId,
        'transactionIdSynced': value.transactionIdSynced,
        'fbclid': value.fbclid,
        'addToCartSynced': value.addToCartSynced,
        'householdShare': value.householdShare,
        'oetAccept': value.oetAccept,
        'isCarLoan': value.isCarLoan,
        'ancestralHome': value.ancestralHome,
    };
}


