import { bool, shape, string } from "prop-types";
import React from "react";
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';



const defaultMaskOptions = {
    prefix: '',
    suffix: " %",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: '.',
    allowDecimal: false,
    integerLimit: 3, // limit length of integer numbers
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    allowNegative: false,
    allowLeadingZeroes: false,
}

const PercentageSuffix = ({ maskOptions, ...inputProps }) => {
    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
        ...maskOptions,
    })

    return <MaskedInput mask={currencyMask} {...inputProps} />
}

PercentageSuffix.defaultProps = {
    inputMode: 'numeric',
    maskOptions: {},
}

PercentageSuffix.propTypes = {
    inputmode: string,
    maskOptions: shape({
        prefix: string,
        suffix: string,
        includeThousandsSeparator: bool,
        thousandsSeparatorSymbol: string,
        allowDecimal: bool,
        decimalSymbol: string,
        decimalLimit: string,
        requireDecimal: bool,
        allowNegative: bool,
        allowLeadingZeroes: bool,
    }),
}

export default PercentageSuffix
